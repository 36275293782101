import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/components/WtLayout/index'
// in development-env not use lazy-loading, because lazy-loading too many pages will cause webpack hot update too slow. so only in production use lazy-loading;
// detail: https://panjiachen.github.io/vue-element-admin-site/#/lazy-loading

Vue.use(Router)

/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
 *                                if not set alwaysShow, only more than one route under the children
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
 **/
const dynamicRouters = [
  { path: '/login', component: () => import('@/modules/login/index'), hidden: true },
  { path: '/register', component: () => import('@/modules/register/index'), hidden: true },
  { path: '/preview', component: () => import('@/modules/viewerPdf/index'), hidden: true },
  { path: '/course/detail/:id', component: () => import('@/modules/cursor/detail'), hidden: true },
  // { path: '/home', component: () => import('@/modules/home/index'), hidden: true },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    name: '首页',
    hidden: true,
    children: [{
      path: 'home',
      component: () => import('@/modules/home/index')
    }, {
      path: 'article',
      component: () => import('@/modules/article/index')
    }, {
      path: 'article/detail/:id',
      component: () => import('@/modules/article/detail')
    }, {
      path: 'dynamics',
      component: () => import('@/modules/dynamics/index')
    }, {
      path: 'dynamics/detail/:id',
      component: () => import('@/modules/report/detail')
    }, {
      path: 'dynamics/purchase/:id',
      component: () => import('@/modules/report/purchase')
    }, {
      path: 'report',
      component: () => import('@/modules/report/index')
    }, {
      path: 'report/detail/:id',
      component: () => import('@/modules/report/detail')
    }, {
      path: 'report/purchase/:id',
      component: () => import('@/modules/report/purchase')
    }, {
      path: 'course',
      component: () => import('@/modules/cursor/index')
    }, {
      path: 'course/purchase/:id',
      component: () => import('@/modules/cursor/purchase')
    }, {
      path: 'user',
      component: () => import('@/modules/user/index')
    }, {
      path: 'user/order/r/:id',
      component: () => import('@/modules/order/index')
    }, {
      path: 'user/order/c/:id',
      component: () => import('@/modules/order/courseOrder')
    }, {
      path: 'user/order/m/:id',
      component: () => import('@/modules/order/memberOrder')
    }]
  }
]

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new Router({
  // mode: 'history', //后端支持可开
  scrollBehavior: () => ({ y: 0 }),
  routes: dynamicRouters
})

export default router
