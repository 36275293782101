import request from "../../utils/request";

const dictionary = {
  state: {
    productCategory: [], // 1 产品类别
    drugType: [], // 3 药品类型
    drugDoseUnit: [], // 4 药品剂量单位
    drugQuantityUnit: [], // 5 药品数量单位
    drugPackagingUnit: [], // 6 药品包装单位
    drugAdministration: [], // 7 药品服用方式
    drugTakingFrequency: [], // 8 药品服用频率
    chineseWesternPatentMedicineDosageForm: [], // 9 中西成药剂型
    traditionalChineseMedicineDosageForm: [] // 10 中药剂型
  },

  mutations: {
    SET_DICTIONARY(state, data) {
      state[data.field] = data.value
    }
  },

  actions: {
    systemDataDictionary({commit, state}, data) {
      request({
        url: 'modules/systemData/dictionary',
        method: 'post',
        data: {
          dataType: data
        }
      }).then(res => {
        if (res.code === '0') {
          console.log(data)
          console.log(res)
          const list = res.data
          let field = ''
          switch (data) {
            case 1:
              field = 'productCategory'
              break
            case 3:
              field = 'drugType'
              break
            case 4:
              field = 'drugDoseUnit'
              break
            case 5:
              field = 'drugQuantityUnit'
              break
            case 6:
              field = 'drugPackagingUnit'
              break
            case 7:
              field = 'drugAdministration'
              break
            case 8:
              field = 'drugTakingFrequency'
              break
            case 9:
              field = 'chineseWesternPatentMedicineDosageForm'
              break
            case 10:
              field = 'traditionalChineseMedicineDosageForm'
              break
          }
          commit('SET_DICTIONARY', {field: field, value: list})
        }
      })
    }
  }
}

export default dictionary
