<template>
  <div class="main-box">
    <div class="router-box">个人中心 > <span>订单详情</span></div>
    <div class="content-page">
      <div class="title-divider">
        订单状态
        <div :class="`pay-status ${payStatusClass[detailForm.payStatus]}`">{{ detailForm.payStatus | FILTER_OrderPayStatus }}</div>
      </div>
      <wt-course-item2 :item="detailForm.courseResult"/>
    </div>
    <div class="content-page wt-m-t-24 wt-p-b-32">
      <div class="title-divider">订单信息</div>
      <div class="information-item">订单编号：<span>{{detailForm.outTradeNo}}</span></div>
      <div class="information-item">下单时间：<span>{{detailForm.createTime}}</span></div>
      <div v-if="detailForm.payStatus === 2" class="information-item">取消时间：<span>{{detailForm.cancelTime || '-'}}</span></div>
      <div v-else class="information-item">支付时间：<span>{{detailForm.payTime || '-'}}</span></div>
      <div class="information-item">优惠抵扣：<span>{{detailForm.couponPriceDollar && detailForm.payStatus != 2 ? `￥${detailForm.couponPriceDollar}` : '-'}}</span></div>
      <div class="information-item wt-m-b-0">实际支付：<span>{{detailForm.payStatus === 1 ? `￥${detailForm.priceDollar}` : '-'}}</span></div>
    </div>
    <div v-if="detailForm.payStatus === 0 || detailForm.payStatus === 1" class="content-page wt-m-t-24 wt-p-24">
      <div v-if="detailForm.payStatus === 0" style="display: flex; justify-content: flex-end;">
        <el-button type="default" size="medium" @click="cancelOrder" style="min-width: 100px;">取消订单</el-button>
        <el-button type="primary" size="medium" @click="payOrder" style="min-width: 100px;">去支付</el-button>
      </div>
      <div v-if="detailForm.payStatus === 1" style="display: flex; justify-content: flex-end;">
        <el-button type="primary" size="medium" @click="navigateCourseDetail" style="min-width: 100px;">去学习</el-button>
      </div>
    </div>
    <el-dialog
      :visible.sync="payDialogVisible"
      width="40%">
      <div class="pay-qr-code">
        <div v-if="waitPay" class="pay-wait">
          <div class="title">微信支付</div>
          <img v-if="payQrCodeImg" :src="payQrCodeImg">
          <div class="tip">请打开手机微信，扫一扫完成支付</div>
        </div>
        <div v-else class="pay-success">
          <img src="@/assets/image/pay/pay-success.png"/>
          <div>支付成功</div>
          <div class="tip">订单支付成功，课程购买成功</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'courseOrder',
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo || {}
    })
  },
  activated() {
    // console.log('得到的参数', this.$route.params.id)
    this.id = this.$route.params.id
    this.requestDetail()
  },
  data() {
    return {
      id: '',
      detailForm: {},
      payStatusClass: {
        0: 'no-pay',
        1: 'success-pay',
        2: 'cancel-pay'
      },
      dialogVisible: false,
      email: '',
      payDialogVisible: false,
      payQrCodeImg: '',
      waitPay: true,
      timer: null
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  deactivated() {
    clearInterval(this.timer)
  },
  methods: {
    refreshMyOrder() {
      this.$bus.$emit('refreshMyOrder')
    },
    requestDetail() {
      this.$wtRequest({
        url: `/api/order/detail`,
        method: 'post',
        data: { id: this.id }
      }).then((res) => {
        res.data.courseResult = { ...res.data.courseResult, priceDollar: res.data.priceDollar || res.data.price }
        this.detailForm = res.data
      }).catch(() => {})
    },
    cancelOrder() {
      this.$confirm('是否确认取消订单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$wtRequest({
          url: `/api/order/cancel`,
          method: 'post',
          data: { id: this.detailForm.id }
        }).then((res) => {
          if (res.code !== '0') return this.$message.error(res.msg)
          this.$message.success('订单已取消')
          this.requestDetail()
          this.refreshMyOrder()
        }).catch(() => {})
      }).catch(() => {})
    },
    payOrder() {
      this.$wtRequest({
        url: `/api/pay/pcBusinessOrderPayQrCode`,
        method: 'post',
        data: { id: this.detailForm.id }
      }).then((res) => {
        this.payQrCodeImg = res.data
        this.payDialogVisible = true
        this.isPay()
      }).catch(() => {})
    },
    isPay() {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.$wtRequest({
          url: `/api/order/isPay`,
          method: 'post',
          data: { id: this.detailForm.id }
        }).then((res) => {
          if (res.data === 1) {
            this.waitPay = false
            this.requestDetail()
            clearInterval(this.timer)
          }
        }).catch(() => {})
      }, 1000)
    },
    navigateCourseDetail() {
      const routeUrl = this.$router.resolve(`/course/detail/${this.detailForm.courseResult.id}`)
      window.open(routeUrl.href, '_blank')
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
.main-box {
  padding: 16/$font 120/$font 60/$font 120/$font;
  width: 1440/$font;
  height: auto;
  background: #F5F7F9;
}
.router-box {
  margin-bottom: 16/$font;
  width: 100%;
  height: auto;
  font-size: 14/$font;
  font-weight: 400;
  color: #666666;
  line-height: 20/$font;
  >span {
    color: $primary-color;
    font-size: 14/$font;
    font-weight: 400;
    line-height: 20/$font;
  }
}
.content-page {
  padding: 24/$font 24/$font 60/$font 24/$font;
  width: 1200/$font;
  height: auto;
  background: #FFFFFF;
  .title-divider {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 0 0 32/$font 0;
    padding: 0 0 20/$font 14/$font;
    width: auto;
    height: 50/$font;
    font-size: 22/$font;
    font-weight: 500;
    color: #333333;
    line-height: 30/$font;
    border-bottom: 1/$font solid rgba(196, 196, 196, 0.5);
    &:before {
      content: '';
      position: absolute;
      top: 4/$font;
      left: 0;
      width: 4/$font;
      height: 22/$font;
      background: #FF6C00;
    }
    .pay-status {
    }
    .no-pay {
      color: #FF0000;
    }
    .success-pay {
      color: #06B453;
    }
    .cancel-pay {
      color: #999999;
    }
    .tip {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 120/$font;
      height: 20/$font;
      font-size: 14/$font;
      font-weight: 400;
      color: #333333;
      line-height: 20/$font;
    }
  }
}
.information-item {
  margin-bottom: 16/$font;
  width: auto;
  height: auto;
  font-size: 16/$font;
  font-weight: 400;
  color: #999999;
  line-height: 22/$font;
  >span {
    color: #333333;
  }
}
.pay-qr-code {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .pay-wait {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      margin-bottom: 60/$font;
      width: 100%;
      height: auto;
      font-size: 18/$font;
      font-weight: 500;
      color: #333333;
      line-height: 24/$font;
      text-align: center;
    }
    >img {
      width: 196/$font;
      height: 196/$font;
    }
    .tip {
      margin-top: 10/$font;
      padding: 8/$font;
      width: auto;
      height: auto;
      font-size: 12/$font;
      font-weight: 400;
      color: #FFFFFF;
      background: #2262B6;
    }
  }
  .pay-success {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    >img {
      width: 80/$font;
      height: 80/$font;
    }
    >div {
      margin-top: 24/$font;
      width: auto;
      height: auto;
      font-size: 24/$font;
      font-weight: 500;
      color: #333333;
      line-height: 32/$font;
    }
    .tip {
      margin-top: 8/$font;
      font-size: 14/$font;
      font-weight: 400;
      color: #999999;
      line-height: 22/$font;
    }
  }
}
</style>
