// import IconTest from '@/assets/images/icon_test.png'
const wtConst = {
  ISPUBLISH_ENUM_CONTENT: {
    0: { text: '禁用', color: '#333333', icon: 'el-icon-warning', iconColor: '#DE504E' },
    1: { text: '启用', color: '#333333', icon: 'el-icon-success', iconColor: '#50D4AB' }
  },
  ISPUBLISH_ENUM_OPTIONS: [
    { value: '', label: '全部' },
    { value: 1, label: '启用' },
    { value: 0, label: '禁用' }
  ],
  MENU_ISHIDDEN_ENUM_CONTENT: {
    0: { text: '显示', color: '#50D4AB', weight: 'bold' },
    1: { text: '隐藏', color: '#999999' }
  },
  MEMBER_TYPE_OPTIONS: [
    { value: 1, label: '初级会员' },
    { value: 2, label: '高级会员' },
    { value: 3, label: 'vip会员' }
  ],
  DICT_TYPE_OPTIONS: [
    { value: 1, label: '研报标签' },
    { value: 2, label: '课程分类' },
    { value: 3, label: '热搜词' },
    { value: 4, label: '文章标签' },
    { value: 5, label: '动态标签' }
  ]
}
export default wtConst
