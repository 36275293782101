<template>
  <div class="box">
    <img v-if="item.imgUrl" :src="item.imgUrl">
    <img v-else>
    <div class="report-info">
      <div class="left">
        <div class="title">{{ item.name }}</div>
        <div class="content">机构：{{item.organizationName}}</div>
        <div class="content">报告时间：{{ dateFormat(item.reportTime || '') }}</div>
        <div class="content">页数：{{item.pageNumber}}</div>
        <div v-if="item.priceDollar !== '0'" class="price"><span>￥</span>{{ item.priceDollar }}</div>
        <div v-else class="price">免费</div>
      </div>
      <div class="right">
        <div class="label">研报</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'wt-browse-item-report',
  props: {
    item: { type: Object, default: () => { return {} } }
  },
  computed: {
    dateFormat() {
      return (date) => {
        if (date === '') return
        return `${date.substr(0, 4)}年${date.substr(5, 2)}月${date.substr(8, 2)}日`
      }
    }
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
.box {
  display: flex;
  padding: 24/$font 0;
  width: 100%;
  height: auto;
  background: #FFFFFF;
  >img {
    margin-right: 16/$font;
    width: 128/$font;
    height: 183/$font;
    border: 1/$font solid #2262B6;
  }
}
.report-info {
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .left {
    .title {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      margin-bottom: 20/$font;
      width: auto;
      height: 37/$font;
      font-size: 20/$font;
      font-weight: 600;
      color: #333333;
      line-height: 26/$font;
    }
    .content {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      margin-bottom: 8/$font;
      width: auto;
      height: 20/$font;
      font-size: 14/$font;
      font-weight: 400;
      color: #666666;
      line-height: 20/$font;
    }
    .price {
      margin-top: 20/$font;
      width: auto;
      height: 36/$font;
      font-size: 28/$font;
      font-weight: 500;
      color: #FF6C00;
      line-height: 36/$font;
      >span {
        font-size: 14/$font;
      }
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    height: 100%;
    .label {
      padding: 6/$font 16/$font;
      width: fit-content;
      height: auto;
      font-size: 12/$font;
      font-weight: 500;
      color: #2262B6;
      line-height: 17/$font;
      border-radius: 100/$font;
      border: 1/$font solid rgba(34, 98, 182, 0.7);
    }
  }
}
</style>
