<template>
  <div class="main-box">
    <div class="router-box">{{ detailForm.isDynamic === 1 ? '动态' : '研报' }} > 购买 > <span>购买详情</span></div>
    <div class="content-page">
      <div class="title-divider">确认订单信息</div>
      <wt-report-item3 :item="detailForm"/>
      <div class="title-divider wt-m-t-40">
        优惠券
        <div class="tip">使用优惠券<el-checkbox v-model="use" class="wt-m-l-10 "></el-checkbox></div>
      </div>
      <el-radio-group v-if="use" v-model="selectedCoupon" style="width: 100%;">
        <div class="coupon-card-box">
          <div v-for="(item, index) in availableCoupon" :key="item.id" :style="`${(index + 1) % 3 === 0 ? 'margin-right: 0;' : ''}`">
            <wt-coupon-card :item="item" class="coupon-card"/>
            <el-radio :label="item.id" :disabled="item.type === 1 && Number(item.fullSubtractPriceDollar) > Number(detailForm.priceDollar)" class="radio">{{''}}</el-radio>
          </div>
        </div>
      </el-radio-group>
    </div>
    <div class="content-page pay-box wt-m-t-24 wt-p-b-32">
      <div class="price">总计：<span>￥<span>{{ totalPrice }}</span></span></div>
      <div class="pay-operation">
        <el-checkbox v-model="agreement" class="wt-m-r-10"></el-checkbox>
        同意<span @click="dialogVisible = true">《车百智库购买协议》</span>
        <el-button type="primary" size="medium" @click="payBtn">微信支付</el-button>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="45%">
<!--      <span slot="title" class="agreement-title">购买协议</span>-->
      <div v-html="agreementContent" class="agreement-content"></div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="confirmAgreement">确定并同意</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="payDialogVisible"
      width="40%"
      @close="closePayDialog">
      <div class="pay-qr-code">
        <div v-if="waitPay" class="pay-wait">
          <div class="title">微信支付</div>
          <img v-if="payQrCodeImg" :src="payQrCodeImg">
          <div class="tip">请打开手机微信，扫一扫完成支付</div>
        </div>
        <div v-else class="pay-success">
          <img src="@/assets/image/pay/pay-success.png"/>
          <div>支付成功</div>
          <div class="tip">订单支付成功，研报购买成功</div>
          <div>
            <el-button size="small" type="default" @click="navigateHome">返回首页</el-button>
            <el-button size="small" type="primary" @click="navigateBack">查看研报</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { subtractAction, multiplyAction } from '../../utils/highPrecisionComputation'

export default {
  name: 'purchase',
  computed: {
    totalPrice() {
      let result = ''
      if (this.selectedCoupon !== '') {
        const coupon = this.availableCoupon.find(item => item.id === this.selectedCoupon)
        // 下单时需要进行优惠价格计算
        if (coupon.type === 1) {
          result = subtractAction(this.detailForm.priceDollar, coupon.favourablePriceDollar)
        } else {
          result = multiplyAction(this.detailForm.priceDollar, Number(coupon.discount) / 10)
        }
        result = result.toString()
        if (result.indexOf('.') >= 0) {
          const index = result.indexOf('.')
          result = `${result.substr(0, index + 1)}${result.substr(index + 1, 2)}`
        }
      } else {
        result = this.detailForm.priceDollar
      }
      return result
    }
  },
  mounted() {
    console.log('得到的参数', this.$route.params.id)
    this.id = this.$route.params.id
    this.requestDetail()
    this.requestCoupon()
    this.getPayAgreementDetail()
  },
  watch: {
    use(newVal) {
      if (!newVal) this.selectedCoupon = ''
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  data() {
    return {
      id: '',
      detailForm: {},
      availableCoupon: [],
      selectedCoupon: '',
      agreementContent: '',
      payQrCodeImg: '',
      use: false,
      agreement: false,
      dialogVisible: false,
      payDialogVisible: false,
      waitPay: true,
      orderId: '',
      timer: null
    }
  },
  methods: {
    async payBtn() {
      if (!this.agreement) return this.$message.warning('请同意车百智库购买协议')
      await this.addOrder()
      this.payDialogVisible = true
    },
    requestDetail() {
      this.$wtRequest({
        url: `/api/report/detail`,
        method: 'post',
        data: { id: this.id }
      }).then((res) => {
        this.detailForm = res.data
      }).catch(() => {})
    },
    requestCoupon() {
      const form = {
        formData: {
          useClass: 2
        },
        pageData: {
          pageSize: -1,
          sortName: 'createTime',
          sortOrder: 'desc'
        }
      }
      this.$wtRequest({
        url: `/api/userCoupon/availableList`,
        method: 'post',
        data: form
      }).then((res) => {
        this.availableCoupon = res.data.list
      }).catch(() => {})
    },
    getPayAgreementDetail() {
      this.$wtRequest({
        url: `/api/content/payAgreementDetail`,
        method: 'post'
      }).then((res) => {
        this.agreementContent = res.data.content
      }).catch(() => {})
    },
    confirmAgreement() {
      this.agreement = true
      this.dialogVisible = false
    },
    closePayDialog() { // 如果未支付关闭则调取消订单
      // clearInterval(this.timer)
    },
    async addOrder() { // 新增订单 调获取支付二维码
      await this.$wtRequest({
        url: `/api/order/addReportOrder`,
        method: 'post',
        data: { id: this.id, userCouponId: this.selectedCoupon }
      }).then(async(res) => {
        this.orderId = res.data.id
        await this.payQrCode(this.orderId)
      }).catch(() => {})
    },
    async payQrCode(orderId) {
      await this.$wtRequest({
        url: `/api/pay/pcBusinessOrderPayQrCode`,
        method: 'post',
        data: { id: orderId }
      }).then((res) => {
        this.payQrCodeImg = res.data
        this.isPay()
      }).catch(() => {})
    },
    isPay() {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.$wtRequest({
          url: `/api/order/isPay`,
          method: 'post',
          data: { id: this.orderId }
        }).then((res) => {
          if (res.data === 1) {
            this.waitPay = false
            this.selectedCoupon = ''
            this.requestDetail()
            this.requestCoupon()
            clearInterval(this.timer)
          }
        }).catch(() => {})
      }, 1000)
    },
    navigateHome() {
      this.payDialogVisible = false
      this.$router.replace('/home')
    },
    navigateBack() {
      this.payDialogVisible = false
      this.$router.replace(`/report/detail/${this.id}`)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
.main-box {
  padding: 16/$font 120/$font 60/$font 120/$font;
  width: 1440/$font;
  height: auto;
  background: #F5F7F9;
}
.router-box {
  margin-bottom: 16/$font;
  width: 100%;
  height: auto;
  font-size: 14/$font;
  font-weight: 400;
  color: #666666;
  line-height: 20/$font;
  >span {
    color: $primary-color;
    font-size: 14/$font;
    font-weight: 400;
    line-height: 20/$font;
  }
}
.content-page {
  padding: 24/$font 24/$font 60/$font 24/$font;
  width: 1200/$font;
  height: auto;
  background: #FFFFFF;
  .title-divider {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 0 0 32/$font 0;
    padding: 0 0 20/$font 14/$font;
    width: auto;
    height: 50/$font;
    font-size: 22/$font;
    font-weight: 500;
    color: #333333;
    line-height: 30/$font;
    border-bottom: 1/$font solid rgba(196, 196, 196, 0.5);
    &:before {
      content: '';
      position: absolute;
      top: 4/$font;
      left: 0;
      width: 4/$font;
      height: 22/$font;
      background: #FF6C00;
    }
    .tip {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 120/$font;
      height: 20/$font;
      font-size: 14/$font;
      font-weight: 400;
      color: #333333;
      line-height: 20/$font;
    }
  }
}
.coupon-card-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  >div {
    position: relative;
    margin-right: 30/$font;
    margin-bottom: 24/$font;
  }
}
.coupon-card {
  width: 362/$font;
  height: 111/$font;
}
.radio {
  position: absolute;
  bottom: 10/$font;
  right: 0;
}
.pay-box {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .price {
    margin-bottom: 16/$font;
    width: auto;
    height: auto;
    font-size: 14/$font;
    font-weight: 400;
    color: #999999;
    line-height: 20/$font;
    >span {
      margin-left: 38/$font;
      width: auto;
      height: auto;
      font-size: 16/$font;
      font-weight: 400;
      color: #FF6C00;
      line-height: 22/$font;
      >span {
        font-size: 26/$font;
      }
    }
  }
  .pay-operation {
    display: flex;
    align-items: center;
    width: auto;
    height: auto;
    font-size: 14/$font;
    font-weight: 400;
    color: #333333;
    line-height: 20/$font;
    >span {
      margin-right: 24/$font;
      width: 140/$font;
      min-width: 120px;
      height: 20/$font;
      font-size: 14/$font;
      font-weight: 400;
      color: #FF6C00;
      line-height: 20/$font;
      cursor: pointer;
    }
  }
}
.agreement-title {
  font-size: 18/$font;
  font-weight: 500;
  color: #333333;
  line-height: 24/$font;
}
.agreement-content {
  font-size: 14/$font;
  font-weight: 400;
  color: #333333;
  line-height: 22/$font;
}
.pay-qr-code {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .pay-wait {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      margin-bottom: 60/$font;
      width: 100%;
      height: auto;
      font-size: 18/$font;
      font-weight: 500;
      color: #333333;
      line-height: 24/$font;
      text-align: center;
    }
    >img {
      width: 196/$font;
      height: 196/$font;
    }
    .tip {
      margin-top: 10/$font;
      padding: 8/$font;
      width: auto;
      height: auto;
      font-size: 12/$font;
      font-weight: 400;
      color: #FFFFFF;
      background: #2262B6;
    }
  }
  .pay-success {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    >img {
      width: 80/$font;
      height: 80/$font;
    }
    >div {
      margin-top: 24/$font;
      width: auto;
      height: auto;
      font-size: 24/$font;
      font-weight: 500;
      color: #333333;
      line-height: 32/$font;
    }
    .tip {
      margin-top: 8/$font;
      font-size: 14/$font;
      font-weight: 400;
      color: #999999;
      line-height: 22/$font;
    }
  }
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: rgba(206, 37, 37, 1);
  background: rgba(206, 37, 37, 1);
}
</style>
