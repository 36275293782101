<template>
  <div class="box">
    <div v-if="item.advancedLabel" class="report-label" :style="`background-image: url(${labelBgObj[item.advancedLabel]});`">
      <span :style="item.advancedLabel !== 1 ? 'left: 0;' : ''">{{labelObj[item.advancedLabel]}}</span>
    </div>
    <img v-if="item.imgUrl" :src="item.imgUrl">
    <img v-else>
    <div>
      <div class="title">{{ item.name }}</div>
      <div class="content">{{item.themeName}}</div>
      <div class="introduction">{{ item.summary }}</div>
      <div v-if="item.priceDollar !== '0'" class="price"><span>￥</span>{{ item.priceDollar }}</div>
      <div v-else class="price">免费</div>
      <div class="page-info">页数：{{ item.pageNumber }}&emsp;|&emsp;{{ dateFormat(item.reportTime || '') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'wt-report-item2',
  props: {
    item: { type: Object, default: () => { return {} } }
  },
  computed: {
    dateFormat() {
      return (date) => {
        if (date === '') return
        return `${date.substr(0, 4)}年${date.substr(5, 2)}月${date.substr(8, 2)}日`
      }
    }
  },
  data() {
    return {
      labelObj: {
        1: '热门',
        2: '上新',
        3: '爆款'
      },
      labelBgObj: {
        1: require('@/assets/image/report/report-label1.svg'),
        2: require('@/assets/image/report/report-label2.svg'),
        3: require('@/assets/image/report/report-label3.svg')
      }
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
.box {
  position: relative;
  display: flex;
  padding: 24/$font;
  width: 588/$font;
  height: 277/$font;
  background: #FFFFFF;
  border-radius: 2/$font;
  border: 1/$font solid #F0F1F2;
  transition: box-shadow 0.25s;
  >img {
    margin-right: 16/$font;
    width: 160/$font;
    height: 229/$font;
    border: 1/$font solid #2262B6;
  }
  &:hover {
    box-shadow: 0/$font 5/$font 9/$font 2/$font rgba(34,98,182,0.1);
  }
}
.report-label {
  position: absolute;
  top: 32/$font;
  left: 20/$font;
  padding: 8/$font 12/$font;
  width: auto;
  height: auto;
  background-size: 100% 100%;
  >span {
    position: relative;
    top: -3/$font;
    left: 8/$font;
    padding-right: 5/$font;
    font-size: 13/$font;
    font-weight: 500;
    color: #FFFFFF;
  }
}
.title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  width: auto;
  height: 52/$font;
  font-size: 20/$font;
  font-weight: 600;
  color: #333333;
  line-height: 26/$font;
}
.content {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  margin-top: 16/$font;
  width: auto;
  height: 24/$font;
  font-size: 14/$font;
  font-weight: 400;
  color: #666666;
  line-height: 24/$font;
}
.introduction {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin-top: 12/$font;
  width: auto;
  height: 40/$font;
  font-size: 14/$font;
  font-weight: 400;
  color: #999999;
  line-height: 20/$font;
}
.price {
  margin-top: 22/$font;
  width: auto;
  height: 36/$font;
  font-size: 20/$font;
  font-weight: 500;
  color: #FF6C00;
  line-height: 36/$font;
  >span {
    font-size: 14/$font;
  }
}
.page-info {
  margin-top: 10/$font;
  width: auto;
  height: 20/$font;
  font-size: 14/$font;
  font-weight: 400;
  color: #999999;
  line-height: 20/$font;
}
</style>
