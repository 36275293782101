<template>
  <div class="box">
    <img v-if="item.imgUrl" :src="item.imgUrl">
    <img v-else>
    <div>
      <div class="title">{{ item.name }}</div>
      <div v-if="item.priceDollar !== '0'" class="price"><span>￥</span>{{ item.priceDollar }}</div>
      <div v-else class="price">免费</div>
      <div class="page-info">页数：{{ item.pageNumber }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'wt-report-item',
  props: {
    item: { type: Object, default: () => { return {} } }
  },
  data() {
    return {}
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
.box {
  position: relative;
  display: flex;
  margin: 81/$font 0 0 0;
  padding: 19/$font 15/$font 16/$font 15/$font;
  width: 384/$font;
  height: 153/$font;
  background: #FFFFFF;
  border-radius: 2/$font;
  border: 1/$font solid #F0F1F2;
  transition: box-shadow 0.2s, transform 0.2s;
  >img {
    position: relative;
    bottom: 68/$font;
    margin-right: 16/$font;
    width: 130/$font;
    height: 186/$font;
    border: 1/$font solid rgba(34,98,182,0.2);
  }
  &:hover {
    box-shadow: 0px 5px 9px 2px rgba(34,98,182,0.1);
    transform: scale(1.01);
  }
}
.title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  width: 208/$font;
  height: 48/$font;
  font-size: 18/$font;
  font-weight: 600;
  color: #333333;
  line-height: 24/$font;
}
.price {
  margin-top: 13/$font;
  width: auto;
  height: 29/$font;
  font-size: 18/$font;
  font-weight: 500;
  color: #FF6C00;
  line-height: 22/$font;
  >span {
    font-size: 14/$font;
  }
}
.page-info {
  margin-top: 8/$font;
  width: auto;
  height: 20/$font;
  font-size: 14/$font;
  font-weight: 400;
  color: #999999;
  line-height: 20/$font;
}
</style>
