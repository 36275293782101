<template>
  <div>
    <div class="search-box">
      <img src="@/assets/image/report/report-search-bg.png"/>
      <div>
        <input v-model="form.formData.name" placeholder="请输入关键字进行搜索" @change="searchEvent"/>
        <div @click="searchEvent">
          <img src="@/assets/icon_png/search-icon.png"/>
        </div>
      </div>
      <div class="labels-box">
        <div class="left">热门：</div>
        <div class="right">
          <div v-for="item in labelList" :key="item.id" class="labels" @click="clickHotLabel(item)">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div class="filter-box">
      <div class="box">
        <div class="left">主题：</div>
        <div class="right" style="width: 100%;">
          <div v-for="item in ztList" :key="item.id" :class="{ active: item.id === activeList[0] }" @click="clickLabel(item, 0)">{{ item.name }}</div>
        </div>
      </div>
      <div v-if="ztTwoLevelList.length > 0" class="box">
        <div class="left"></div>
        <div class="right" style="width: 100%; background-color: rgba(245, 247, 249, 1);">
          <div v-for="item in ztTwoLevelList" :key="item.id" :class="{ active: item.id === ztActiveList[0] }" @click="clickThemeLabel(item, 0)" style="background-color: rgba(245, 247, 249, 1);">{{ item.name }}</div>
        </div>
      </div>
      <div v-if="ztThereLevelList.length > 0" class="box">
        <div class="left"></div>
        <div class="right" style="width: 100%; background-color: rgba(245, 247, 249, 1);">
          <div v-for="item in ztThereLevelList" :key="item.id" :class="{ active: item.id === ztActiveList[1] }" @click="clickThemeLabel(item, 1)" style="background-color: rgba(245, 247, 249, 1);">{{ item.name }}</div>
        </div>
      </div>
      <div class="box">
        <div class="left">机构：</div>
        <div class="right" style="width: 100%;">
          <div v-for="item in jgList" :key="item.id" :class="{ active: item.id === activeList[1] }" @click="clickLabel(item, 1)">{{ item.name }}</div>
        </div>
      </div>
      <div v-if="jgTwoLevelList.length > 0" class="box">
        <div class="left"></div>
        <div class="right" style="width: 100%; background-color: rgba(245, 247, 249, 1);">
          <div v-for="item in jgTwoLevelList" :key="item.id" :class="{ active: item.id === jgActiveList[0] }" @click="clickOrganizationLabel(item, 0)" style="background-color: rgba(245, 247, 249, 1);">{{ item.name }}</div>
        </div>
      </div>
      <div v-if="jgThereLevelList.length > 0" class="box">
        <div class="left"></div>
        <div class="right" style="width: 100%; background-color: rgba(245, 247, 249, 1);">
          <div v-for="item in jgThereLevelList" :key="item.id" :class="{ active: item.id === jgActiveList[1] }" @click="clickOrganizationLabel(item, 1)" style="background-color: rgba(245, 247, 249, 1);">{{ item.name }}</div>
        </div>
      </div>
      <div class="box">
        <div class="left">研报页数：</div>
        <div class="right">
          <div v-for="item in ysList" :key="item.id" :class="{ active: item.id === activeList[2] }" @click="clickLabel(item, 2)">{{ item.title }}</div>
        </div>
        <div style="display: flex; align-items: center;">
          <el-input v-model="form.formData.startPageNumber" type="number" size="small" placeholder="输入页数" style="width: 90px;"></el-input >
          <div style="width: 12px; height: 1px; background: #C4C4C4; margin: 0 8px;"></div>
          <el-input v-model="form.formData.endPageNumber" type="number" size="small" placeholder="输入页数" style="width: 90px;"></el-input>
        </div>
      </div>
      <div class="box" style="margin-bottom: 0;">
        <div class="left">研报时间：</div>
        <div class="right">
          <div v-for="item in sjList" :key="item.id" :class="{ active: item.id === activeList[3] }" @click="clickLabel(item, 3)">{{ item.title }}</div>
        </div>
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          size="small"
          prefix-icon="1"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期" style="width: 360px;">
        </el-date-picker>
      </div>
      <div class="button-box">
        <el-button size="mini" @click="resetFilter">重置</el-button>
        <el-button size="mini" type="primary" @click="searchEvent">筛选</el-button>
      </div>
    </div>
    <div class="content-box">
      <div class="card-list">
        <div v-for="item in list" :key="item.id" class="wt-m-b-32" @click="navigateDetail(item.id)">
          <wt-report-item2 :item="item"/>
        </div>
      </div>
      <div class="pagination">
        <wt-pagination :total="listTotal" :page-size="6" :current-page="form.pageData.currentPage" @currentPageChange="currentPageChange"/>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'index',
  data() {
    return {
      labelList: [],
      activeList: ['', '', '', ''],
      ztActiveList: ['', ''],
      jgActiveList: ['', ''],
      ztList: [
        { name: '全部', id: '' }
      ],
      ztTwoLevelList: [],
      ztThereLevelList: [],
      jgList: [
        { name: '全部', id: '' }
      ],
      jgTwoLevelList: [],
      jgThereLevelList: [],
      ysList: [
        { title: '全部', id: '', extData: { endPageNumber: '', startPageNumber: '' }},
        { title: '1-10', id: 1, extData: { endPageNumber: 10, startPageNumber: 1 }},
        { title: '1-20', id: 2, extData: { endPageNumber: 20, startPageNumber: 1 }}
      ],
      sjList: [
        { title: '全部', id: '' },
        { title: '近一个月', id: 1, extData: { dayNumber: 30 }},
        { title: '近三个月', id: 2, extData: { dayNumber: 90 }},
        { title: '最近半年', id: 3, extData: { dayNumber: 180 }},
        { title: '最近一年', id: 4, extData: { dayNumber: 365 }}
      ],
      form: {
        formData: {
          createTimeRange: [],
          endPageNumber: '',
          isDynamic: 0,
          isFirstPageShow: '',
          labelIdList: [],
          name: '',
          organizationIdList: [],
          reportTimeEnd: '',
          reportTimeStart: '',
          startPageNumber: '',
          themeIdList: []
        },
        pageData: {
          currentPage: 1,
          pageSize: 6,
          sortName: 'createTime',
          sortOrder: 'desc'
        }
      },
      dateRange: [],
      listTotal: 0,
      list: []
    }
  },
  mounted() {
    this.initDictionary()
    this.getThemeList(1, 0)
    this.getOrganizationList(1, 0)
  },
  activated() {
    this.requestList()
  },
  methods: {
    clickLabel(label, index) {
      const _activeList = JSON.parse(JSON.stringify(this.activeList))
      _activeList[index] = label.id
      this.activeList = _activeList
      switch (index) {
        case 0:
          if (label.id === '') {
            this.getThemeList(1, 0)
          } else {
            this.getThemeList(2, label.id)
          }
          break
        case 1:
          if (label.id === '') {
            this.getOrganizationList(1, 0)
          } else {
            this.getOrganizationList(2, label.id)
          }
          break
        case 2:
          this.form.formData.startPageNumber = label.extData.startPageNumber
          this.form.formData.endPageNumber = label.extData.endPageNumber
          break
        case 3:
          switch (label.id) {
            case '':
              this.dateRange = []
              break
            case 1:
            case 2:
            case 3:
            case 4:
              this.dateRange = this.recentlyDate(label.extData.dayNumber)
              break
          }
          break
      }
    },
    clickThemeLabel(label, index) {
      const _activeList = JSON.parse(JSON.stringify(this.ztActiveList))
      _activeList[index] = label.id
      this.ztActiveList = _activeList
      if (label.level === 2) this.getThemeList(3, label.id)
    },
    clickOrganizationLabel(label, index) {
      const _activeList = JSON.parse(JSON.stringify(this.jgActiveList))
      _activeList[index] = label.id
      this.jgActiveList = _activeList
      if (label.level === 2) this.getOrganizationList(3, label.id)
    },
    recentlyDate(dayNumber) {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * dayNumber)
      return [dayjs(start).format('YYYY-MM-DD'), dayjs(end).format('YYYY-MM-DD')]
    },
    resetFilter() {
      this.dateRange = []
      this.form.formData = {
        createTimeRange: [],
        endPageNumber: '',
        isDynamic: 0,
        isFirstPageShow: '',
        labelIdList: [],
        name: '',
        organizationIdList: [],
        reportTimeEnd: '',
        reportTimeStart: '',
        startPageNumber: '',
        themeIdList: []
      }
      this.activeList = ['', '', '', '']
      this.ztActiveList = ['', '']
      this.jgActiveList = ['', '']
      this.ztTwoLevelList = []
      this.ztThereLevelList = []
      this.jgTwoLevelList = []
      this.jgThereLevelList = []
      this.searchEvent()
    },
    clickHotLabel(e) {
      this.form.formData.name = e.title
      this.searchEvent()
    },
    searchEvent() {
      if (this.dateRange.length !== 0) {
        this.form.formData.reportTimeStart = this.dateRange[0]
        this.form.formData.reportTimeEnd = this.dateRange[1]
      } else {
        this.form.formData.reportTimeStart = ''
        this.form.formData.reportTimeEnd = ''
      }
      this.form.pageData.currentPage = 1
      this.requestList()
    },
    currentPageChange(e) {
      this.form.pageData.currentPage = e
      this.requestList()
    },
    requestList() {
      const _themeIdList = [this.activeList[0], ...this.ztActiveList].filter(item => item !== '')
      this.form.formData.themeIdList = _themeIdList.splice(-1)
      const _organizationIdList = [this.activeList[1], ...this.jgActiveList].filter(item => item !== '')
      this.form.formData.organizationIdList = _organizationIdList.splice(-1)
      this.$wtRequest({
        url: `/api/report/list`,
        method: 'post',
        data: this.form
      }).then((res) => {
        this.list = res.data.list
        this.listTotal = res.data.total
      }).catch(() => {})
    },
    navigateDetail(id) {
      const routeUrl = this.$router.resolve(`/report/detail/${id}`)
      window.open(routeUrl.href, '_blank')
    },
    initDictionary() {
      // 标签字典 1:研报  2:课程  3:热搜词  4:文章  5:动态
      this.$wtRequest({
        url: `/api/dictionary/listByType`,
        method: 'post',
        data: { id: 3 }
      }).then((res) => {
        const _labelList = []
        res.data.forEach(label => _labelList.push({ title: label.name, id: label.id }))
        this.labelList = _labelList
      }).catch(() => {})
    },
    getThemeList(level, pid) {
      this.$wtRequest({
        url: `/api/theme/listByParentId`,
        method: 'post',
        data: { id: pid }
      }).then((res) => {
        switch (level) {
          case 1:
            this.ztList = [{ name: '全部', id: '' }, ...res.data]
            this.ztTwoLevelList = []
            this.ztThereLevelList = []
            this.ztActiveList = ['', '']
            break
          case 2:
            this.ztTwoLevelList = res.data
            this.ztThereLevelList = []
            this.ztActiveList = ['', '']
            break
          case 3:
            this.ztThereLevelList = res.data
            this.ztActiveList[1] = ''
            break
        }
      }).catch(() => {})
    },
    getOrganizationList(level, pid) {
      this.$wtRequest({
        url: `/api/organization/listByParentId`,
        method: 'post',
        data: { id: pid }
      }).then((res) => {
        switch (level) {
          case 1:
            this.jgList = [{ name: '全部', id: '' }, ...res.data]
            this.jgTwoLevelList = []
            this.jgThereLevelList = []
            this.jgActiveList = ['', '']
            break
          case 2:
            this.jgTwoLevelList = res.data
            this.jgThereLevelList = []
            this.jgActiveList = ['', '']
            break
          case 3:
            this.jgThereLevelList = res.data
            this.jgActiveList[1] = ''
            break
        }
      }).catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
img {
  -webkit-user-drag: none;
}
.search-box {
  position: relative;
  width: 1440/$font;
  height: 300/$font;
  >img {
    width: 1440/$font;
    height: 300/$font;
  }
  >div {
    position: absolute;
    top: 100/$font;
    left: 335/$font;
    display: flex;
    align-items: center;
    >input {
      padding: 0 16/$font;
      width: 690/$font;
      height: 50/$font;
      outline: none;
    }
    >div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80/$font;
      height: 50/$font;
      background: $primary-color;
      border-radius: 0 2/$font 2/$font 0;
      >img {
        width: 30/$font;
        height: 30/$font;
      }
    }
  }
  .labels-box {
    position: absolute;
    top: 174/$font;
    left: 335/$font;
    display: flex;
    align-items: flex-start;
    width: 770/$font;
    height: 100/$font;
    >div {
      width: auto;
      height: auto;
      font-size: 12/$font;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 17/$font;
      background: rgba(0, 0, 0, 0);
    }
    .left {
      margin-top: 6/$font;
    }
    .right {
      display: flex;
      flex: 1;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      height: 85/$font;
      overflow: hidden;
      .labels {
        margin: 0 16/$font 16/$font 0;
        padding: 6/$font 16/$font;
        font-size: 12/$font;
        font-weight: 300;
        color: #FFFFFF;
        line-height: 17/$font;
        width: auto;
        height: auto;
        border-radius: 150/$font;
        opacity: 0.7;
        border: 1/$font solid #FFFFFF;
        cursor: pointer;
        transition: opacity 0.2s;
        &:hover {
          //color: $primary-color;
          //border: 1/$font solid $primary-color;
          opacity: 1;
        }
      }
    }
  }
}
.filter-box {
  position: relative;
  padding: 32/$font 120/$font 36/$font 120/$font;
  width: 1440/$font;
  height: auto;
  .box {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 24/$font;
    .left {
      margin-top: 4/$font;
      margin-right: 24/$font;
      width: 70/$font;
      min-width: 60px;
      height: 20/$font;
      font-size: 14/$font;
      font-weight: 500;
      color: #333333;
      line-height: 20/$font;
    }
    .right {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-shrink: 0;
      flex-wrap: wrap;
      >div {
        margin-right: 32/$font;
        padding: 4/$font 8/$font;
        width: auto;
        height: auto;
        font-size: 14/$font;
        font-weight: 400;
        color: #666666;
        border-radius: 2/$font;
        cursor: default;
        transition: color 0.2s, background 0.2s;
      }
      .active {
        color: #2262B6;
        background: #F3F7FA;
      }
    }
  }
  .button-box {
    position: absolute;
    right: 120/$font;
    bottom: 32/$font;
  }
}
.content-box {
  padding: 40/$font 120/$font 60/$font 120/$font;
  width: 1440/$font;
  height: auto;
  background: #F5F7F9;
  .card-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
}
.pagination {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>
