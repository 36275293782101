<template>
  <div class="box">
    <div
      v-for="(item, index) in list"
      :key="index"
      :class="{ active: index === active && showActive, 'indicator-bar': indicatorBar && index === active && showActive }"
      :style="{ textAlign }"
      @click="clickMenu(item, index)">
      <div v-if="indicatorBar" :class="{ 'indicator-bar': true,  'indicator-bar-active': index === active && showActive }"></div>
      {{ item.title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'wt-side-menu-single',
  props: {
    list: { type: Array, default: () => { return [] } },
    indicatorBar: { type: Boolean, default: false }, // 左侧指示条
    textAlign: { type: String, default: 'left' },
    showActive: { type: Boolean, default: true }
  },
  data() {
    return {
      active: 0
    }
  },
  methods: {
    clickMenu(item, index) {
      this.active = index
      this.$emit('click', item)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
.box {
  padding: 16/$font 0;
  width: 100%;
  height: auto;
  background: #FFFFFF;
  >div {
    position: relative;
    padding: 16/$font 24/$font;
    width: 100%;
    height: 54/$font;
    font-size: 16/$font;
    font-weight: 400;
    color: #333333;
    background: #FFFFFF;
    transition: all 0.2s;
    cursor: pointer;
    .indicator-bar {
      position: absolute;
      top: 0;
      left: 0;
      width: 4/$font;
      height: 100%;
      background: rgba(0, 0, 0, 0);
      transition: all 0.2s;
    }
    .indicator-bar-active {
      background: $primary-color;
    }
  }
  .active {
    font-weight: 600;
    color: $primary-color;
    background: $primary-background-color;
  }
}
</style>
