<template>
  <div class="box">
    <div class="header">
      <img src="@/assets/image/logo.png"/>
<!--      <div class="cursor-title">{{ detailForm.name }}</div>-->
      <el-popover
        placement="bottom-end"
        trigger="hover">
        <div class="popover-menu">
          <div @click="navigateTo('user')">个人中心</div>
          <div @click="logoutBtn">退出登录</div>
        </div>
        <img slot="reference" v-if="token" :src="userInfo.wechatPhotoUrl || defaultHeader" class="header-img"/>
      </el-popover>
    </div>
    <div class="block1"></div>
    <div class="content-page main-content-page">
      <div class="video-box">
        <div>
          <div>{{ selectCursorVideo.fileName }}</div>
          <div id="videoPlayer" style="width: 800/$font; height: 540px;"></div>
          <video v-if="trySeeUrl" controls controlsList="nodownload" style="width: 100%; height: 540px; position: absolute; left: 0; bottom: 0;">
            <source :src="trySeeUrl" type="video/mp4">
          </video>
        </div>
        <div>
          <div class="tab-select">
            <div @click="tabActive = 1" :class="{ 'active': tabActive === 1 }">课程目录</div>
            <div @click="tabActive = 2" :class="{ 'active': tabActive === 2 }">课程评论</div>
          </div>
          <div v-if="tabActive === 1" class="directory">
            <div v-for="(chapter, index) in chapterList" :key="chapter.id" class="chapter-box">
              <div class="title">
                {{zeroFill(index + 1)}}.
                <span class="text-over-line1">{{chapter.name}}</span>
                <img @click="openEvent(index)" src="@/assets/image/cursor/video-down.svg" :style="isOpenList[index] ? '' : 'transform: rotate(180deg)'"/>
              </div>
              <div v-for="fileItem in chapter.fileList" class="file-box" :style="isOpenList[index] ? '' : 'margin-top: 0;height: 0;overflow: hidden;'" @click="selectVideoEvent(fileItem)">
                <img v-if="fileItem.id === selectCursorVideo.id" src="@/assets/image/cursor/video-pause.svg"/>
                <img v-else src="@/assets/image/cursor/video-play.svg"/>
                <div>
                  <div :class="{'text-over-line1': true, 'active': fileItem.id === selectCursorVideo.id}">{{fileItem.fileName}}</div>
                  <div>{{fileItem.fileDurationFormat}}</div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="tabActive === 2" class="comment">
            <div v-for="item in evaluationList" :key="item.id" class="message-box">
              <div>{{ item.userName }}<span>{{ item.createTime }}</span></div>
              <div>{{ item.content }}</div>
            </div>
            <div class="send-input">
              <input v-model="content" placeholder="请发表你的评论，200字以内" maxlength="200"/>
              <div @click="sendEvent">发送</div>
            </div>
          </div>
        </div>
      </div>
      <div class="video-base">
        <div style="width: 67%;">{{ detailForm.name }}</div>
        <div>
          <span v-if="userAuth.isReadAuth === 0"><span>￥</span>{{ detailForm.priceDollar }}</span>
          课程时长：{{ detailForm.totalDurationFormat }}
          课程讲师：{{ detailForm.teacher }}
        </div>
        <el-button v-if="userAuth.isReadAuth === 0" @click="navigateToPurchase" type="primary" size="medium" class="button">购买课程</el-button>
      </div>
    </div>
    <div v-if="detailForm.introduction" class="content-page">
      <div class="title-divider">课程介绍</div>
      <div class="content">{{ detailForm.introduction }}</div>
    </div>
    <div v-if="detailForm.teacherIntroduction" class="content-page">
      <div class="title-divider">师资简介</div>
      <div class="content">{{ detailForm.teacherIntroduction }}</div>
    </div>
    <div v-if="detailForm.characteristic" class="content-page">
      <div class="title-divider">课程特色</div>
      <div class="content">{{ detailForm.characteristic }}</div>
    </div>
    <div v-if="detailForm.worth" class="content-page wt-p-t-16 wt-p-r-24 wt-p-b-40 wt-p-l-24">
      <div class="title-divider">课程价值</div>
      <div class="content">{{ detailForm.worth }}</div>
    </div>
    <div class="block2"></div>
    <div class="footer">
      <div class="footer-nav">
        <div @click="navigateTo('home')">首页</div>
        <div class="separate"></div>
        <div @click="navigateTo('article')">读文章</div>
        <div class="separate"></div>
        <div @click="navigateTo('dynamics')">看动态</div>
        <div class="separate"></div>
        <div @click="navigateTo('report')">找研报</div>
        <div class="separate"></div>
<!--        <div @click="navigateTo('course')">学课程</div>-->
<!--        <div class="separate"></div>-->
        <div @click="meeting">活动会议</div>
      </div>
      <div class="divider"></div>
      <div class="information">
        <img src="@/assets/image/logo.png" class="logo" />
        <div class="address">
          <span>地址：中国.北京.海淀区建材城中路27号金隅智造工场N5号楼</span>
          <span>联系电话：+86 10 82911506</span>
          <span>邮箱：ev100plus@chinaev100.org</span>
        </div>
        <div class="qr-code">
          <div>
            <img src="@/assets/image/wx_gzh.jpg" />官方公众号
          </div>
          <div class="">
            <img src="@/assets/image/wx_mp.jpg" />官方小程序
          </div>
        </div>
      </div>
      <div class="tip">Copyright ©2023中国电动汽车百人会 版权所有 <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备19027029号-2</a></div>
    </div>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import { mapState } from 'vuex'
export default {
  name: 'detail',
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo || {}
    }),
    zeroFill() {
      return (number) => {
        if (number.toString().length === 1) return `0${number}`
        return number
      }
    }
  },
  data() {
    return {
      token: getToken(),
      defaultHeader: require('@/assets/image/login/default-header.png'),
      id: '',
      detailForm: {},
      userAuth: {
        isReadAuth: 0
      },
      tabActive: 1,
      chapterList: [],
      isOpenList: [],
      selectCursorVideo: {},
      player: null,
      content: '',
      evaluationList: [],
      trySeeUrl: ''
    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.getAuthority()
    this.requestDetail()
    this.requestCourseEvaluationList()
  },
  destroyed() {
  },
  methods: {
    sendEvent() {
      if (!this.content.trim()) return this.$message.warning('请输入评论内容')
      const that = this
      that.$wtRequest({
        url: '/api/courseEvaluation/add',
        method: 'post',
        data: { courseId: this.id, content: this.content }
      }).then((resp) => {
        if (resp.code !== '0') return this.$message.error(resp.msg)
        this.content = ''
        this.$message.success('审核后即可查看')
      }).catch(() => {})
    },
    openEvent(index) {
      const _isOpenList = JSON.parse(JSON.stringify(this.isOpenList))
      _isOpenList[index] = !_isOpenList[index]
      this.isOpenList = _isOpenList
    },
    async selectVideoEvent(fileItem) {
      await this.getAuthority()
      if (this.selectCursorVideo.fileId === fileItem.fileId) return
      if (this.userAuth.isReadAuth === 0 && fileItem.trySeeDuration === 0) {
        return this.$message.warning('该节内容不可试看')
      }
      if (this.player) this.player.dispose()
      this.selectCursorVideo = fileItem
      console.log(fileItem)
      this.playVideo(fileItem.fileId, fileItem.id)
    },
    playVideo(vid, id) {
      const that = this
      const videoId = vid
      const requestUrl = this.userAuth.isReadAuth === 0 ? '/api/course/trySee' : '/api/course/videoPlayAuth'
      const data = this.userAuth.isReadAuth === 0 ? { id } : { courseId: this.detailForm.id, videoId }
      that.$wtRequest({
        url: requestUrl,
        method: 'post',
        data: data
      }).then((resp) => {
        console.log('播放凭证', resp)
        if (this.userAuth.isReadAuth === 0) {
          that.trySeeUrl = ''
          setTimeout(() => {
            that.trySeeUrl = resp.data.playInfoList[0].playURL
          }, 500)
          return
        }
        that.trySeeUrl = ''
        // eslint-disable-next-line no-undef
        that.player = new Aliplayer({
          id: 'videoPlayer',
          width: '100%',
          autoplay: false,
          vid: videoId, // 必选参数。音视频ID。示例：1e067a2831b641db90d570b6480f****。
          playauth: resp.data.playAuth // 必选参数。音视频播放凭证。
        }, function(player) {
          // if (that.userAuth.isReadAuth === 0) {
          //   that.player.setPreviewTime(that.selectCursorVideo.trySeeDuration) // 试看秒数
          // }
          console.log('The player is created.', player)
        })
        console.log('播放器实例', this.player)
        that.getAuthority()
      }).catch(() => {})
    },
    async getAuthority() {
      // 用户权限
      await this.$wtRequest({
        url: `/api/auth/authority`,
        method: 'post',
        data: { businessId: this.id, businessType: 2 }
      }).then((res) => {
        this.userAuth = res.data
      }).catch(() => {})
    },
    requestDetail() {
      this.$wtRequest({
        url: `/api/course/detail`,
        method: 'post',
        data: { id: this.id }
      }).then((res) => {
        this.detailForm = res.data
        this.chapterList = res.data.chapterList
        this.isOpenList = res.data.chapterList.map((item, index) => {
          return index === 0
        })
        this.selectVideoEvent(this.chapterList[0].fileList[0])
      }).catch(() => {})
    },
    requestCourseEvaluationList() {
      this.$wtRequest({
        url: `/api/courseEvaluation/auditPassListByCourseLimit`,
        method: 'post',
        data: { id: this.id }
      }).then((res) => {
        this.evaluationList = res.data
      }).catch(() => {})
    },
    navigateTo(router) {
      this.$router.push(`/${router}`)
    },
    logoutBtn() {
      this.$confirm('是否退出?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
      }).then(() => {
        this.$store.dispatch('requestLogout').then(() => {
          this.$router.replace('/home')
          setTimeout(() => {
            window.location.reload()
          }, 200)
        })
      }).catch(() => {
      })
    },
    navigateToPurchase() {
      this.$wtRequest({
        url: `/api/order/waitPayOrder`,
        method: 'post',
        data: { businessId: this.id, businessType: 2 }
      }).then((res) => {
        console.log(res)
        if (!res.data) {
          this.$router.push(`/course/purchase/${this.id}`)
        } else {
          this.$confirm(`您有该课程待支付的订单`, '提示', {
            confirmButtonText: '去支付',
            cancelButtonText: '取消订单',
            type: 'info'
          }).then(() => {
            this.$router.push(`/user/order/c/${res.data.id}`)
          }).catch(() => {
            this.$router.push(`/user/order/c/${res.data.id}`)
          })
        }
      }).catch(() => {})
    },
    meeting() {
      window.open('https://www.chinaev100.com/meetting/list/1', 'block')
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
.box {
  min-height: 100vh;
  background: rgba(245, 247, 249, 1);
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1099;
  display: flex;
  align-items: center;
  padding: 11/$font 120/$font;
  width: 1440/$font;
  height: 60/$font;
  background: $primary-color;
  >img {
    width: 135/$font;
    height: 38/$font;
  }
  .cursor-title {
    margin-left: 80/$font;
    font-size: 16/$font;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 22/$font;
  }
  .header-img {
    position: absolute;
    top: 10/$font;
    right: 120/$font;
    width: 40/$font;
    height: 40/$font;
    border-radius: 50%;
    cursor: pointer;
  }
}
.block1 {
  height: 60/$font;
}
.content-page {
  position: relative;
  margin: 24/$font auto 0 auto;
  padding: 16/$font 24/$font 40/$font 24/$font;
  width: 1200/$font;
  height: auto;
  background: #FFFFFF;
  .title-divider {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0 0 16/$font 14/$font;
    width: auto;
    height: 50/$font;
    font-size: 20/$font;
    font-weight: 500;
    color: #333333;
    line-height: 28/$font;
    border-bottom: 1/$font solid rgba(196, 196, 196, 0.5);
    &:before {
      content: '';
      position: absolute;
      top: 8/$font;
      left: 0;
      width: 4/$font;
      height: 22/$font;
      background: #FF6C00;
    }
  }
  .content {
    margin-top: 32/$font;
    font-size: 16/$font;
    font-weight: 400;
    color: #333333;
    line-height: 26/$font;
  }
}
.main-content-page {
  margin: 0;
  padding: 24/$font 120/$font 32/$font 120/$font;
  width: 1440/$font;
}
.video-box {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  >div:nth-child(1) {
    position: relative;
    >div:nth-child(1) {
      padding: 13/$font 24/$font;
      width: 800/$font;
      height: auto;
      font-size: 16/$font;
      font-weight: 500;
      color: #2262B6;
      background: #F3F9FF;
    }
    >div:nth-child(2) {
      width: 800/$font;
      height: 540/$font;
      font-size: 16/$font;
      background: #000000;
    }
  }
  >div:nth-child(2) {
    position: relative;
    flex-shrink: 0;
    width: 384/$font;
    height: 588/$font;
    background: #F3F9FF;
    border: 1/$font solid #EAF4FF;
  }
}
.tab-select {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 14/$font 24/$font 14/$font 24/$font;
  background: #F3F9FF;
  :nth-child(1) {
    margin-right: 60/$font;
  }
  >div {
    position: relative;
    font-size: 14/$font;
    font-weight: 400;
    color: #999999;
    line-height: 20/$font;
    cursor: pointer;
  }
  .active {
    font-weight: 500;
    color: #333333;
    &:after {
      content: '';
      position: absolute;
      bottom: -12/$font;
      left: 0;
      width: 56/$font;
      height: 2/$font;
      background: #FF6C00;
    }
  }
}
.directory {
  margin-top: 74/$font;
  height: calc(100% - 74/$font);
  overflow: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }
  &::-webkit-scrollbar-thumb {
    background-color: #2262b6;
  }
  .chapter-box {
    margin-bottom: 33/$font;
    padding-left: 24/$font;
    .title {
      display: flex;
      align-items: center;
      width: 100%;
      height: 22/$font;
      font-size: 18/$font;
      font-weight: 500;
      color: #333333;
      line-height: 22/$font;
      >span {
        width: 288/$font;
        font-size: 16/$font;
        font-weight: 400;
        color: #333333;
        line-height: 22/$font;
      }
      >img {
        margin-left: 16/$font;
        width: 12/$font;
        height: 12/$font;
      }
    }
    .file-box {
      display: flex;
      align-items: flex-start;
      margin-top: 24/$font;
      cursor: pointer;
      >img {
        position: relative;
        top: 4/$font;
        width: 12/$font;
        height: 12/$font;
        margin-right: 13/$font;
      }
      >div {
        :nth-child(1) {
          width: 252/$font;
          height: 20/$font;
          font-size: 14/$font;
          font-weight: 400;
          color: #333333;
          line-height: 20/$font;
        }
        .active {
          font-weight: 500;
          color: #2262B6;
        }
        :nth-child(2) {
          margin-top: 4/$font;
          font-size: 12/$font;
          font-weight: 400;
          color: #999999;
        }
      }
    }
  }
}
.comment {
  overflow: auto;
  margin-top: 74/$font;
  padding: 0 16/$font 0 24/$font;
  height: 436/$font;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }
  &::-webkit-scrollbar-thumb {
    background-color: #2262b6;
  }
  .message-box {
    margin-bottom: 24/$font;
    padding-bottom: 24/$font;
    border-bottom: 1/$font solid #C8D6E8;
    >div:nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16/$font;
      font-weight: 500;
      color: #333333;
      line-height: 22/$font;
      > span {
        font-size: 12/$font;
        font-weight: 400;
        color: #999999;
        line-height: 17/$font;
      }
    }
    >div:nth-child(2) {
      margin-top: 12/$font;
      font-size: 14/$font;
      font-weight: 400;
      color: #666666;
      line-height: 22/$font;
    }
  }
  .send-input {
    position: absolute;
    bottom: 16/$font;
    left: 24/$font;
    overflow: hidden;
    display: flex;
    align-items: center;
    border-radius: 2/$font;
    > input {
      padding: 10/$font 12/$font;
      width: 274/$font;
      height: 40/$font;
      font-size: 14px;
      outline: none;
      border: 1/$font solid #F0F1F2;
    }
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70/$font;
      height: 38/$font;
      font-size: 16/$font;
      color: #FFFFFF;
      background: #2262B6;
      cursor: pointer;
    }
  }
}
.video-base {
  position: relative;
  margin-top: 24/$font;
  width: 100%;
  >div:nth-child(1) {
    font-size: 24/$font;
    font-weight: 500;
    color: #333333;
    line-height: 33/$font;
  }
  >div:nth-child(2) {
    display: flex;
    align-items: center;
    margin-top: 8/$font;
    font-size: 14/$font;
    font-weight: 400;
    color: #999999;
    >span {
      margin-right: 24/$font;
      font-size: 26/$font;
      font-weight: 400;
      color: #FF6C00;
      >span {
        font-size: 14/$font;
      }
    }
  }
  .button {
    position: absolute;
    top: 14/$font;
    right: 24/$font;
  }
}
.block2 {
  height: 60/$font;
}
.popover-menu {
  >div {
    padding: 8/$font 0;
    width: 100%;
    height: auto;
    font-size: 16/$font;
    font-weight: 400;
    color: #333333;
    text-align: center;
    transition: color 0.2s;
    cursor: pointer;
    &:hover {
      color: $primary-color;
    }
  }
  :nth-child(2) {
    color: #F56C6C;
    &:hover {
      color: red;
    }
  }
}
.footer {
  position: relative;
  z-index: 999;
  width: 1440/$font;
  height: 287/$font;
  background: $primary-color;
  .footer-nav {
    display: flex;
    align-items: center;
    padding-left: 120/$font;
    width: 1440/$font;
    height: 52/$font;
    >div {
      width: auto;
      height: 20/$font;
      font-size: 14/$font;
      font-weight: 300;
      color: #FFFFFF;
      line-height: 20/$font;
      cursor: pointer;
    }
    .separate {
      margin: 0 24/$font 0 24/$font;
      width: 1/$font;
      height: 11/$font;
      opacity: 0.6;
      border: 1/$font solid #FFFFFF;
      cursor: default;
    }
  }
  .divider {
    width: 1440/$font;
    height: 1/$font;
    opacity: 0.3;
    border: 1/$font solid #FFFFFF;
  }
  .information {
    display: flex;
    align-items: flex-start;
    padding: 40/$font 120/$font 0 120/$font;
    width: 100%;
    height: auto;
    .address {
      width: 757/$font;
      >span {
        display: block;
        margin-bottom: 16/$font;
        font-size: 14/$font;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20/$font;
        user-select: text;
      }
    }
    .logo {
      margin-right: 60/$font;
      width: 137/$font;
      height: 38/$font;
    }
    .qr-code {
      display: flex;
      align-items: center;
      >div {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 40/$font;
        font-size: 12/$font;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 17/$font;
        >img {
          margin-bottom: 8/$font;
          width: 83/$font;
          height: 83/$font;
        }
      }
    }
  }
  .tip {
    position: absolute;
    bottom: 24/$font;
    width: 100%;
    height: 17/$font;
    font-size: 12/$font;
    font-weight: 300;
    color: #FFFFFF;
    line-height: 17/$font;
    text-align: center;
    opacity: 0.6;
    user-select: text;
    > a {
      font-size: 12/$font;
      font-weight: 300;
      color: #FFFFFF;
      text-decoration: none;
    }
  }
}
::v-deep .prism-cc-btn {
  display: none;
}
//::v-deep .prism-controlbar {
//  display: block !important;
//}
//::v-deep .prism-setting-list {
//  display: block !important;
//}
::v-deep .prism-setting-cc, ::v-deep .prism-setting-audio, ::v-deep .prism-setting-quality {
  display: none;
}
</style>
