<template>
  <div class="box">
    <div @click="prevEvent">
      <img src="./arrow-left.png" class="arrow"/>
    </div>
    <div
      v-for="(item, index) in pager"
      :key="index"
      :class="{ active: item === currentPageData, 'border-none': item === 'leftMore' || item === 'rightMore' }"
      @click="clickPage(item)"
    >
      <img v-if="item === 'leftMore'" src="./more.png" class="more"/>
      <img v-if="item === 'rightMore'" src="./more.png" class="more"/>
      <span v-if="item !== 'leftMore' && item !== 'rightMore'">{{ item }}</span>
    </div>
    <div @click="nextEvent">
      <img src="./arrow-right.png" class="arrow"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'wt-pagination',
  props: {
    total: { type: Number, default: 0 },
    pageSize: { type: Number, default: 6 },
    currentPage: { type: Number, default: 1 }
  },
  data() {
    return {
      // pagesNumber: 1,
      // pagesNumber: 2,
      // pagesNumber: 5,
      // pagesNumber: 7,
      // pagesNumber: 8,
      // pagesNumber: 11,
      // pagesNumber: 22,
      pagesNumber: 0,
      currentPageData: 1
    }
  },
  computed: {
    pager() {
      let pages = []
      if (this.pagesNumber <= 7) {
        for (let i = 1; i <= this.pagesNumber; i++) {
          pages.push(i)
        }
      } else {
        for (let i = 1; i <= this.pagesNumber; i++) {
          pages.push(i)
        }
        if (this.currentPageData > 4 && this.currentPageData < this.pagesNumber - 3) {
          console.log(...pages.slice(this.currentPageData - 2, 3))
          pages = [...pages.slice(0, 2), ...pages.slice(this.currentPageData - 3, this.currentPageData - 2 + 3), ...pages.slice(-2)]
        }
        if (this.currentPageData > 4) {
          pages.splice(1, 1)
          pages[1] = 'leftMore'
        }
        if (this.currentPageData < this.pagesNumber - 3) {
          pages.length > 7 && pages.splice(pages.length - 2, 1)
          pages[pages.length - 2] = 'rightMore'
        }
        if (pages.length > 7) {
          if (this.currentPageData > 4) pages.splice(2, pages.length - 7)
          if (this.currentPageData < this.pagesNumber - 3) pages.splice(5, pages.length - 7)
        }
      }
      return pages
    }
  },
  mounted() {
    this.setPagesNumber()
    this.setCurrentPageData()
  },
  watch: {
    total() {
      this.setPagesNumber()
    },
    currentPage() {
      this.setCurrentPageData()
    }
  },
  methods: {
    setPagesNumber() {
      this.pagesNumber = Math.ceil(this.total / this.pageSize)
    },
    setCurrentPageData() {
      this.currentPageData = this.currentPage
    },
    clickPage(item) {
      if (item === 'leftMore') {
        this.currentPageData = this.currentPageData - 3
        this.emitCurrentPageEvent()
        return
      } else if (item === 'rightMore') {
        this.currentPageData = this.currentPageData + 3
        this.emitCurrentPageEvent()
        return
      }
      this.currentPageData = item
      this.emitCurrentPageEvent()
    },
    prevEvent() {
      if (this.currentPageData === 1) return
      this.currentPageData = this.currentPageData - 1
      this.emitCurrentPageEvent()
    },
    nextEvent() {
      if (this.currentPageData === this.pagesNumber) return
      this.currentPageData = this.currentPageData + 1
      this.emitCurrentPageEvent()
    },
    emitCurrentPageEvent() {
      this.$emit('currentPageChange', this.currentPageData)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
.box {
  display: flex;
  align-items: center;
  >div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8/$font;
    width: 32/$font;
    height: 32/$font;
    font-size: 14/$font;
    font-weight: 400;
    color: rgba(0,0,0,0.88);
    background: #FFFFFF;
    border-radius: 6/$font;
    border: 1/$font solid rgba(0,0,0,0.15);
    cursor: pointer;
    user-select: none;
    transition: border 0.2s, color 0.2s;
    .arrow {
      width: 28/$font;
      height: 28/$font;
      opacity: 0.88;
    }
    .more {
      width: 28/$font;
      height: 28/$font;
    }
  }
  .active {
    color: $primary-color;
    border: 1/$font solid $primary-color;
  }
  .border-none {
    border: 1/$font solid rgba(0,0,0,0);
    background-color: rgba(0,0,0,0);
  }
}
</style>
