import Vue from 'vue'
import store from '@/store/index'

// console.log('wtAuthUtil.js init')

Vue.directive('has-any-premission', {
  bind: (el, binding, vnode) => {
    el.style.display = hasAnyPermission(binding ? binding.value : []) ? el.style.display : 'none'
  }
})

Vue.directive('has-any-role', {
  bind: (el, binding, vnode) => {
    el.style.display = hasAnyRole(binding ? binding.value : []) ? el.style.display : 'none'
  }
})

function hasAnyPermission(values) {
  if (!Array.isArray(values)) {
    values = values.substr(2)
    values = values.substr(0, values.length - 2)
    values = values.split(',')
  }
  let isValidate = false
  const codes = store.state.auth.permissionCodeList || []
  const isSkipCheck = process.env.VUE_APP_SKIP_PREMISSION_CHECK === 'TRUE'
  if (isSkipCheck) {
    isValidate = true
  } else if (values && values.length > 0) {
    values.forEach(item => {
      if (codes.indexOf(item) >= 0) {
        isValidate = true
        return true
      }
    })
  }
  return isValidate
}

function hasAnyRole(values) {
  let isValidate = false
  const codes = store.state.auth.roleCodeList || []
  const isSkipCheck = process.env.VUE_APP_SKIP_ROLE_CHECK === 'TRUE'
  if (isSkipCheck) {
    isValidate = true
  } else if (values && values.length > 0) {
    values.forEach(item => {
      if (codes.indexOf(item) >= 0) {
        isValidate = true
        return true
      }
    })
  }
  return isValidate
}

export default {
  hasAnyPermission: hasAnyPermission,
  hasAnyRole: hasAnyRole
}
