<template>
  <div class="box">
    <div class="header">
      <img src="@/assets/image/logo.png"/>
    </div>
    <div class="block1"></div>
    <div class="content-page">
      <div class="form-title">欢迎注册</div>
      <div class="right-top-tip">已有账号？<span @click="navigateTo('login')">快速登录>></span></div>
      <el-form :model="detailForm" :rules="rules" ref="verifyForm" label-width="100px" class="form-box">
        <el-form-item label="姓名:" prop="name">
          <el-popover
            placement="right"
            width="350"
            trigger="click">
            <el-input slot="reference" v-model="detailForm.name" size="small" placeholder="请输入姓名"></el-input>
            <div style="font-size: 14px;">● 2-25个字符，推荐使用中文</div>
            <div style="font-size: 14px;">● 请勿包含身份证/银行卡等隐私信息，一旦设置成功无法修改</div>
          </el-popover>
        </el-form-item>
        <el-form-item label="公司名称:" prop="company">
          <el-input v-model="detailForm.companyName" size="small" placeholder="请输入公司名称"></el-input>
        </el-form-item>
        <el-form-item label="职务:" prop="duties">
          <el-input v-model="detailForm.job" size="small" placeholder="请输入职务"></el-input>
<!--          <el-popover-->
<!--            placement="right"-->
<!--            width="350"-->
<!--            trigger="click">-->
<!--            <el-input slot="reference" v-model="detailForm.job" size="small" placeholder="请输入职务"></el-input>-->
<!--            <div style="font-size: 14px;">● 5-25个字符，推荐使用中文</div>-->
<!--            <div style="font-size: 14px;">● 请勿包含身份证/银行卡等隐私信息，一旦设置成功无法修改</div>-->
<!--          </el-popover>-->
        </el-form-item>
        <el-form-item label="邮箱:" prop="email">
          <el-popover
            placement="right"
            width="350"
            trigger="click">
            <el-input slot="reference" v-model="detailForm.email" size="small" placeholder="请输入邮箱"></el-input>
            <div style="font-size: 14px;">● 5-25个字符，推荐使用中文</div>
            <div style="font-size: 14px;">● 请勿包含身份证/银行卡等隐私信息，一旦设置成功无法修改</div>
          </el-popover>
        </el-form-item>
        <el-form-item label="密码:" prop="password">
          <el-popover
            placement="right"
            width="350"
            trigger="click">
            <el-input slot="reference" v-model="detailForm.password" type="password" size="small" placeholder="请输入密码"></el-input>
            <div style="font-size: 14px;">● 6-20个字符，密码不能和会员名相同</div>
            <div style="font-size: 14px;">● 只能包含数字、字母及标点符号（除空格）</div>
            <div style="font-size: 14px;">● 数字、字母和标点符号至少包含2种</div>
          </el-popover>
        </el-form-item>
        <el-form-item label="确认密码:" prop="confirmPassword">
          <el-popover
            placement="right"
            width="350"
            trigger="click">
            <el-input slot="reference" v-model="detailForm.confirmPassword" type="password" size="small" placeholder="请输入确认密码"></el-input>
            <div style="font-size: 14px;">● 请再次输入您的密码</div>
          </el-popover>
        </el-form-item>
        <el-form-item label="手机号:" prop="phone">
          <el-input v-model="detailForm.phone" size="small" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="medium" @click="save('verifyForm')" style="width: 100%;">同意条款并注册</el-button>
        </el-form-item>
        <el-form-item>
          <div class="agreement-checkbox">
            <el-checkbox v-model="agreement" class="wt-m-r-10"></el-checkbox>
            同意<span @click="dialogVisible = true">《车百智库购买协议》</span>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="block2"></div>
    <div class="footer">
      Copyright ©2023中国电动汽车百人会 版权所有 <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备19027029号-2</a>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="45%">
      <div v-html="agreementContent" class="agreement-content"></div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'index',
  data() {
    return {
      detailForm: {
        companyName: '', // 公司名称
        confirmPassword: '', // 确认密码
        email: '', // 邮箱
        job: '', // 职务
        name: '', // 姓名
        password: '', // 密码
        phone: '' // 手机号
      },
      rules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        email: [{ required: true, message: '请输入邮箱', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        confirmPassword: [{ required: true, message: '请输入确认密码', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }]
      },
      agreement: false,
      dialogVisible: false,
      agreementContent: ''
    }
  },
  mounted() {
    this.getPayAgreementDetail()
  },
  destroyed() {
  },
  methods: {
    getPayAgreementDetail() {
      this.$wtRequest({
        url: `/api/content/payAgreementDetail`,
        method: 'post'
      }).then((res) => {
        this.agreementContent = res.data.content
      }).catch(() => {})
    },
    byteCount(str) {
      // eslint-disable-next-line no-control-regex
      return str && str.replace(/[^\x00-\xff]/g, 'xx').length || 0
    },
    save(formName) {
      // 数字、字母和特殊字符组合  不能全是数字、字母或特殊字符
      const passwordValidator = new RegExp(/(?![\d]+$)(?![a-zA-Z]+$)(?![?|!|"|#|$|%|&|\'|(|)|*|+|,|-|.|/|:|;|<|=|>|@|\[|\\|\]|^|_|`|{|\||}|~|）|']+$)(?=[\da-zA-Z|?|!|"|#|$|%|&|\'|(|)|*|+|,|-|.|/|:|;|<|=|>|@|\[|\\|\]|^|_|`|{|\||}|~|）|']+$).{6,20}$/)
      console.log(passwordValidator.test(this.detailForm.password.trim()))
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const nameLength = this.detailForm.name.length
          const emailLength = this.byteCount(this.detailForm.email)
          if (nameLength < 2 || nameLength > 25) return this.$message.warning('姓名应在2-25个字符之间')
          if (emailLength < 5 || emailLength > 25) return this.$message.warning('邮箱应在5-25个字符之间')
          if (this.detailForm.password.trim() === this.detailForm.name.trim() || !passwordValidator.test(this.detailForm.password.trim()) || this.detailForm.password.trim().length > 20) return this.$message.warning('不符合密码规则')
          if (this.detailForm.password !== this.detailForm.confirmPassword) return this.$message.warning('两次密码输入不一致')
          if (!this.$wtValidator.checkEmail(this.detailForm.email)) return this.$message.warning('邮箱格式输入有误')
          if (!this.$wtValidator.checkMobile(this.detailForm.phone)) return this.$message.warning('手机号输入有误')
          if (!this.agreement) return this.$message.warning('请同意《车百智库购买协议》')
          this.$confirm('是否确认同意条款并注册？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.userRegister()
          }).catch(() => {})
        } else {
          return false
        }
      })
    },
    userRegister() {
      this.$wtRequest({
        url: `/api/user/pcRegister`,
        method: 'post',
        data: this.detailForm
      }).then((res) => {
        if (res.code === '0') {
          this.$store.dispatch('setStoreToken', res.data.token)
          this.$store.dispatch('setStoreUserInfo', res.data.id)
          this.$router.replace('/home')
        } else {
          this.$message.error(res.msg)
        }
      }).catch(() => {})
    },
    navigateTo(router) {
      this.$router.replace(`/${router}`)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
.box {
  min-height: 100vh;
  background: rgba(245, 247, 249, 1);
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  padding: 11/$font 120/$font;
  width: 1440/$font;
  height: 60/$font;
  background: $primary-color;
  >img {
    width: 135/$font;
    height: 38/$font;
  }
}
.block1 {
  height: 60/$font;
}
.content-page {
  position: relative;
  margin: 24/$font auto 60/$font auto;
  padding-bottom: 50/$font;
  width: 1200/$font;
  height: auto;
  background: #FFFFFF;
  .form-title {
    position: relative;
    left: 48/$font;
    padding: 40/$font 0 32/$font 0;
    margin: 0 auto;
    width: auto;
    height: auto;
    font-size: 26/$font;
    font-weight: 500;
    color: #2262B6;
    text-align: center;
    line-height: 37/$font;
  }
  .right-top-tip {
    position: absolute;
    top: 24/$font;
    right: 24/$font;
    width: auto;
    height: auto;
    font-size: 14/$font;
    font-weight: 400;
    color: #333333;
    line-height: 20/$font;
    >span {
      font-size: 14/$font;
      font-weight: 400;
      color: rgba(255, 108, 0, 1);
      line-height: 20/$font;
      cursor: pointer;
    }
  }
  .form-box {
    margin: 0 auto;
    width: 420/$font;
  }
}
.agreement-checkbox {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  >span {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 108, 0, 1);
    line-height: 20px;
    cursor: pointer;
  }
}
.block2 {
  height: 65/$font;
}
.footer {
  position: fixed;
  z-index: 999;
  bottom: 0;
  left: 0;
  width: 1440/$font;
  height: 65/$font;
  font-size: 12/$font;
  font-weight: 300;
  color: #FFFFFF;
  text-align: center;
  line-height: 65/$font;
  background: $primary-color;
  user-select: text;
  > a {
    font-size: 12/$font;
    font-weight: 300;
    color: #FFFFFF;
    text-decoration: none;
  }
}
</style>
