import request from '@/utils/request'
const ops = {
  state: {
    groupList: []
  },
  mutations: {
    updateGroupList: (state, data) => {
      state.groupList = data
    }
  },
  actions: {
    requestGroupList({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        if (data && data.cacheEnable && state.groupList && state.groupList.length > 0) {
          return resolve(state.groupList)
        }
        request({
          url: 'modules/auth/groupTreeAll',
          method: 'post',
          data: data || {}
        }).then(resp => {
          const data = resp.data
          const resultList = []
          const handleGroup = (item) => {
            const key = item.id
            const result = { label: item.name, id: item.id, value: item.id }
            if (data[key] && data[key].length > 0) {
              const children = []
              data[key].forEach((item) => {
                children.push(handleGroup(item))
              })
              result.children = children
            }
            return result
          }
          if (data['0'] && data['0'].length > 0) {
            data['0'].forEach(item => {
              resultList.push(handleGroup(item))
            })
          }
          commit('updateGroupList', resultList)
          resolve(resultList)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default ops
