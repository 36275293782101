<template>
  <table style="width: 100%;">
    <tr class="title-row">
      <th class="bgColor1">特权名称</th>
      <th class="bgColor2">初级会员</th>
      <th class="bgColor3">高级会员</th>
      <th class="bgColor4">尊享会员</th>
    </tr>
    <tr>
      <td class="bgColor5">报告权益</td>
      <td class="bgColor6">
        {{numberData(1, 1, 1)}}<br/><br/>
        {{numberData(1, 1, 2)}}
      </td>
      <td class="bgColor7">
        {{numberData(2, 1, 1)}}<br/><br/>
        {{numberData(2, 1, 2)}}
      </td>
      <td class="bgColor8">
        {{numberData(3, 1, 1)}}<br/><br/>
        {{numberData(3, 1, 2)}}
      </td>
    </tr>
    <tr>
      <td class="bgColor5">课程权益</td>
      <td class="bgColor6">{{numberData(1, 2, 1)}}</td>
      <td class="bgColor7">{{numberData(2, 2, 1)}}</td>
      <td class="bgColor8">{{numberData(3, 2, 1)}}</td>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'memberEquityTable',
  props: {
    tableData: { type: Array, default: () => { return [] } }
  },
  computed: {
    numberData() {
      return (level, type, method) => {
        // method 1:查看 2:下载
        const data = this.tableData.find(item => item.level === level && item.type === type) || { downloadDayLimitNumber: 0, isFreeDownload: 0, isFreeRead: 0, readDayLimitNumber: 0 }
        if (method === 1) {
          if (data.isFreeRead !== 0) {
            return `免费查看`
          } else {
            return `免费查看${data.readDayLimitNumber}次/天`
          }
        } else {
          if (data.isFreeDownload !== 0) {
            return `免费下载`
          } else {
            return `免费下载${data.downloadDayLimitNumber}次/天`
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
table {
  border-collapse: collapse;
  background: rgba(240, 240, 240, 0.20);
  .title-row {
    height: 70/$font;
  }
  >tr {
    height: 143/$font;
    >th, >td {
      text-align: center;
      border: 1/$font solid rgba(240, 240, 240, 0.6);
    }
  }
}
.bgColor1 {
  font-size: 18/$font;
  font-weight: 600;
  color: #333333;
  line-height: 25/$font;
  background: rgba(246, 246, 246, 1);
}
.bgColor2 {
  font-size: 18/$font;
  font-weight: 600;
  color: #333333;
  line-height: 25/$font;
  background: rgba(222, 242, 254, 1);
}
.bgColor3 {
  font-size: 18/$font;
  font-weight: 600;
  color: rgba(159, 83, 0, 1);
  line-height: 25/$font;
  background: linear-gradient(148deg, #FEE4B6 0%, #FDCD79 59%, #F8D48C 100%);
}
.bgColor4 {
  font-size: 18/$font;
  font-weight: 600;
  color: rgba(255, 206, 158, 1);
  line-height: 25/$font;
  background: linear-gradient(131deg, #3D3D3D 0%, #131313 100%);
}
.bgColor5 {
  font-size: 16/$font;
  font-weight: 400;
  color: #333333;
  line-height: 22/$font;
  background: #FFFFFF;
}
.bgColor6 {
  font-size: 16/$font;
  font-weight: 400;
  color: rgba(97, 157, 219, 1);
  line-height: 22/$font;
  background: rgba(222, 242, 254, 0.37);
}
.bgColor7 {
  font-size: 16/$font;
  font-weight: 400;
  color: rgba(159, 83, 0, 1);
  line-height: 22/$font;
  background: rgba(255, 245, 231, 1);
}
.bgColor8 {
  font-size: 16/$font;
  font-weight: 400;
  color: rgba(255, 206, 158, 1);
  line-height: 22/$font;
  background: rgba(42, 46, 56, 0.80);
}
</style>
