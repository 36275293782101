<template>
  <div class="box">
    <img v-if="item.imgUrl" :src="item.imgUrl">
    <img v-else>
    <div>
      <div class="title">{{ item.name }}</div>
      <div class="content">课程时长：{{item.totalDurationFormat}}</div>
      <div class="content">课程讲师：{{item.teacher}}</div>
      <div class="label">{{ item.labelName }}</div>
      <div v-if="item.priceDollar !== '0'" class="price"><span>￥</span>{{ item.priceDollar }}</div>
      <div v-else class="price">免费</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'wt-course-item2',
  props: {
    item: { type: Object, default: () => { return {} } }
  },
  computed: {},
  data() {
    return {}
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
.box {
  display: flex;
  padding: 24/$font;
  width: 100%;
  height: 258/$font;
  background: #F5F7F9;
  >img {
    margin-right: 16/$font;
    width: 280/$font;
    height: 210/$font;
    border: 1/$font solid #2262B6;
  }
}
.title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  margin-bottom: 24/$font;
  width: auto;
  height: 37/$font;
  font-size: 26/$font;
  font-weight: 600;
  color: #333333;
  line-height: 37/$font;
}
.content {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  margin-bottom: 4/$font;
  width: auto;
  height: 20/$font;
  font-size: 14/$font;
  font-weight: 400;
  color: #666666;
  line-height: 20/$font;
}
.label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24/$font 0 18/$font 0;
  padding: 6/$font 16/$font;
  width: fit-content;
  height: auto;
  font-size: 12/$font;
  font-weight: 500;
  color: #2262B6;
  border-radius: 100/$font;
  border: 1/$font solid rgba(34, 98, 182, 0.7);
}
.price {
  width: auto;
  height: 36/$font;
  font-size: 28/$font;
  font-weight: 500;
  color: #FF6C00;
  line-height: 36/$font;
  >span {
    font-size: 14/$font;
  }
}
</style>
