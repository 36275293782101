<template>
  <div class="main-box">
    <div class="card-box">
      <div v-for="item in list" :key="item.id">
        <wt-coupon-card :item="item"/>
      </div>
    </div>
    <div class="pagination">
      <wt-pagination :total="listTotal" :page-size="10" :current-page="form.pageData.currentPage" @currentPageChange="currentPageChange"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'myCoupon',
  data() {
    return {
      form: {
        formData: {},
        pageData: {
          currentPage: 1,
          pageSize: 10,
          sortName: 'createTime',
          sortOrder: 'desc'
        }
      },
      list: [],
      listTotal: 0
    }
  },
  mounted() {
    this.requestList()
    this.$bus.$on('refreshMyOrder', this.refreshMyOrder)
  },
  methods: {
    currentPageChange(e) {
      this.form.pageData.currentPage = e
      this.requestList()
    },
    requestList() {
      this.$wtRequest({
        url: `/api/userCoupon/list`,
        method: 'post',
        data: this.form
      }).then((res) => {
        this.list = res.data.list
        this.listTotal = res.data.total
      }).catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
.main-box {
  padding: 32/$font 24/$font 60/$font 24/$font;
}
.card-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  >div {
    margin-bottom: 32/$font;
  }
}
.pagination {
  display: flex;
  justify-content: flex-end;
}
</style>
