window.wtConst = window.wtConst || {}
window.wtConst.env = process.env.NODE_ENV

if (window.wtConst.env === 'jenkins') {
  window.wtConst.BASE_API = 'https://evplus.dev2.wangtiansoft.com/'
  window.wtConst.uploadUrl = '' // 文件上传地址
} else if (window.wtConst.env === 'test') {
  window.wtConst.BASE_API = ''
  window.wtConst.uploadUrl = '' // 文件上传地址
} else if (window.wtConst.env === 'production') {
  window.wtConst.BASE_API = 'https://evplus.ev100plus.com/'
  window.wtConst.uploadUrl = '' // 文件上传地址
} else if (window.wtConst.env === 'development') {
  // window.wtConst.BASE_API = 'https://evplus.dev2.wangtiansoft.com/'
  window.wtConst.BASE_API = 'https://evplus.ev100plus.com/'
  window.wtConst.uploadUrl = '' // 文件上传地址
}
export default window.wtConst.uploadUrl
