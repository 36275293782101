<template>
  <div @click="$emit('click')" :class="{'member-card-type-box': true, 'active': active}">
    <div>{{cardTitle}}</div>
    <div><span>￥</span>{{price}}</div>
  </div>
</template>

<script>
export default {
  name: 'memberCardType',
  props: {
    cardTitle: { type: String, default: '' },
    price: { type: [String, Number], default: '' },
    active: { type: Boolean, default: false }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
.member-card-type-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 116/$font;
  height: 125/$font;
  background: #F7F9FC;
  border-radius: 8/$font;
  transition: all 0.2s;
  cursor: pointer;
  :nth-child(1) {
    width: auto;
    height: auto;
    font-size: 18/$font;
    font-weight: 500;
    color: #333333;
    line-height: 25/$font;
    transition: all 0.2s;
  }
  :nth-child(2) {
    width: auto;
    height: auto;
    font-size: 36/$font;
    font-weight: bold;
    color: #FF6C00;
    line-height: 44/$font;
    transition: all 0.2s;
    >span {
      font-size: 16/$font;
      font-weight: 500;
      color: #FF6C00;
      line-height: 22/$font;
      transition: all 0.2s;
    }
  }
}
.active {
  background: linear-gradient(90deg, #FFE6C1 0%, #FFE6C1 100%);
  :nth-child(1) {
    color: rgba(87, 32, 0, 1);
  }
  :nth-child(2) {
    color: rgba(87, 32, 0, 1);
    >span {
      color: rgba(87, 32, 0, 1);
    }
  }
}
</style>
