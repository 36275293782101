import SparkMD5 from 'spark-md5'
import request from '@/utils/request'
import dayjs from 'dayjs'
import downloadRequest from '@/utils/wtDownloadRequest'

function S4() {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
}
const wtUtil = {
  isBlank: (str) => {
    return str == null || /^\s*$/.test(str)
  },
  isNotBlank: (str) => {
    return !wtUtil.isBlank(str)
  },
  debounce: (func, delay) => {
    let timer
    return function(...args) {
      if (timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(() => {
        func.apply(this, args)
      }, delay)
    }
  },
  uuid: () => {
    return (S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4())
  },
  covertByteToString: (limit) => {
    let size = ''
    if (limit < 1 * 1024) {
      size = limit.toFixed(2) + 'B'
    } else if (limit < 1 * 1024 * 1024) {
      //  如果小于0.1MB转化成KB
      size = (limit / 1024).toFixed(2) + 'KB'
    } else if (limit < 1 * 1024 * 1024 * 1024) {
      // 如果小于0.1GB转化成MB
      size = (limit / (1024 * 1024)).toFixed(2) + 'MB'
    } else {
      // 其他转化成GB
      size = (limit / (1024 * 1024 * 1024)).toFixed(2) + 'GB'
    }
    var sizestr = size + ''
    var len = sizestr.indexOf('.')
    var dec = sizestr.substr(len + 1, 2)
    if (dec === '00') {
      //  当小数点后为00时 去掉小数部分
      return sizestr.substring(0, len) + sizestr.substr(len + 3, 2)
    }
    return sizestr
  },
  // 手机掩码处理
  maskPhoneNo(val) {
    if (!val) return ''
    const reg = /^(\d{3})\d*(\d{4})$/
    return val.replace(reg, '$1****$2')
  },
  // 身份证掩码处理
  maskCardNo(val) {
    if (!val) return ''
    const reg = /^(.{2})(?:\w+)(.{1})$/
    return val.replace(reg, '$1****************$2')
  },
  getFileMd5(file, callback) {
    const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
    const chunkSize = 1024 * 1024 * 512
    const chunks = Math.ceil(file.size / chunkSize)
    let currentChunk = 0
    const spark = new SparkMD5.ArrayBuffer()
    const fileReader = new FileReader()

    fileReader.onload = function(e) {
      spark.append(e.target.result)
      currentChunk++
      if (currentChunk < chunks) {
        loadNext()
      } else {
        const fileMd5 = spark.end()
        file.md5Code = 'SUCCESS'
        file.md5 = fileMd5
        callback(file)
      }
    }

    fileReader.onerror = function(err) {
      file.md5Code = 'FAIL'
      file.md5Error = err
      callback(file)
    }

    function loadNext() {
      const start = currentChunk * chunkSize
      const end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize
      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
    }
    loadNext()
  },
  getFileListMd5(files) {
    const _this = this
    const md5PromiseArray = []
    for (let i = 0; i < files.length; i++) {
      md5PromiseArray.push(new Promise((resolve, reject) => {
        _this.getFileMd5(files[i], (resp) => {
          resolve(resp)
        })
      }))
    }
    return Promise.all(md5PromiseArray)
  },
  toFormData(form) {
    form.formData = {}
    form.formInputsData.forEach((item) => {
      form.formData[item.key] = item.value
    })
    return {
      formData: form.formData,
      pageData: form.pageData
    }
  },
  validateDetailForms(detailForm, ...names) {
    const detailRefs = detailForm.$refs
    if (detailRefs) {
      const allValidator = []
      Object.keys(detailRefs).forEach((key, index) => {
        if (detailRefs[key] && detailRefs[key].$refs) {
          const validateForm = detailRefs[key]
          if (validateForm && validateForm.validate && (validateForm.$attrs.validate === null || validateForm.$attrs.validate === undefined || validateForm.$attrs.validate === '' || validateForm.$attrs.validate)) {
            const promise = new Promise((resolve, reject) => {
              validateForm.validate((valid) => {
                resolve(valid)
              })
            }).then(result => result)
            allValidator.push(promise)
          }
        }
      })
      return new Promise((resolve, reject) => {
        let result = true
        Promise.all(allValidator).then((data) => {
          if (data && data.length > 0) {
            data.forEach((item) => {
              if (!item) {
                result = false
                return 0
              }
            })
          }
          resolve(result)
        }).catch(() => {
          result = false
          resolve(result)
        })
      })
    }
  },
  validateDetailFormsAndConfirm(detailForm, text, ...names) {
    const that = this
    return new Promise((resolve, reject) => {
      that.validateDetailForms(detailForm, names).then((valid) => {
        if (valid) {
          detailForm.$confirm(text, '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'info' }).then(() => {
            resolve()
          }).catch((e) => {
            reject(e)
          })
        }
      })
    })
  },
  postFormData({ url, formData, isLoading }) {
    console.log({ url, formData, isLoading }, '-------------------')
    return new Promise((resolve, reject) => {
      isLoading = true
      request({
        url: url,
        method: 'post',
        data: formData
      }).then(response => {
        isLoading = false
        resolve(response)
      }).catch(error => {
        isLoading = false
        reject(error)
      })
    })
  },
  /**
   * 从数组中find key相同的item，不存在时返回null
   * @param array
   * @param key
   * @returns {*}
   */
  findByKey(array, key) {
    let result = null
    if (array) {
      array.forEach((item) => {
        if (item.key === key) {
          result = item
        }
      })
    }
    return result
  },
  getDate(value, unit) {
    value = value || 0
    unit = unit || 'day'
    const date = dayjs(new Date(new Date())).add(value, unit)
    return date.toDate()
  },
  downloadFile({ url, fileName, data, method = 'post' }) {
    return new Promise((resolve, reject) => {
      downloadRequest({
        url: url,
        method: method,
        data: data,
        responseType: 'blob',
        contentType: 'application/json'
      }).then(response => {
        if (response.data.type === 'application/json') {
          reject(new Error('下载失败'))
          return
        }
        const blob = new Blob([response.data])
        if (!fileName) {
          fileName = Date.parse(new Date()) + ''
        }
        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob, fileName)
        } else {
          var link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = fileName
          link.click()
          window.URL.revokeObjectURL(link.href)
        }
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  buildImageUrl(url) {
    if (this.isBlank(url)) {
      return ''
    }
    if (url.indexOf('http') === 0) {
      return url
    }
    return window.wtConst.FILE_SERVER_URL + url
  },
  calculationFileMd5(file) {
    const that = this
    return new Promise((resolve, reject) => {
      try {
        that.getFileMd5(file, (md5File) => {
          if (md5File.md5Code === 'SUCCESS') {
            resolve(file)
          } else {
            reject(new Error('文件检查失败'))
          }
        })
      } catch (e) {
        reject(new Error(e))
      }
    })
  },
  dragControllerDiv() {
    var resize = document.getElementsByClassName('resize')
    var left = document.getElementsByClassName('orga')
    var mid = document.getElementsByClassName('dataform')
    var box = document.getElementsByClassName('page-container')
    for (let i = 0; i < resize.length; i++) {
      // 鼠标按下事件
      // debugger
      resize[i].onmousedown = function(e) {
        // 颜色改变提醒
        resize[i].style.background = '#818181'
        var startX = e.clientX
        resize[i].left = resize[i].offsetLeft
        // 鼠标拖动事件
        document.onmousemove = function(e) {
          var endX = e.clientX
          var moveLen = resize[i].left + (endX - startX) // （endx-startx）=移动的距离。resize[i].left+移动的距离=左边区域最后的宽度
          var maxT = box[i].clientWidth - resize[i].offsetWidth // 容器宽度 - 左边区域的宽度 = 右边区域的宽度

          if (moveLen < 180) moveLen = 180 // 左边区域的最小宽度为180px
          if (moveLen > maxT - 700) moveLen = maxT - 700 // 右边区域最小宽度为500px

          resize[i].style.left = moveLen // 设置左侧区域的宽度

          for (let j = 0; j < left.length; j++) {
            left[j].style.width = moveLen + 'px'
            mid[j].style.width = (box[i].clientWidth - moveLen - 10) + 'px'
          }
        }
        // 鼠标松开事件
        document.onmouseup = function(evt) {
          // 颜色恢复
          resize[i].style.background = '#d6d6d6'
          document.onmousemove = null
          document.onmouseup = null
          resize[i].releaseCapture && resize[i].releaseCapture() // 当你不在需要继续获得鼠标消息就要应该调用ReleaseCapture()释放掉
        }
        resize[i].setCapture && resize[i].setCapture() // 该函数在属于当前线程的指定窗口里设置鼠标捕获
        return false
      }
    }
  }
}

export default wtUtil

