<template>
  <div class="main-box">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="全部订单" name="0" style="font-size: 20px;"></el-tab-pane>
      <el-tab-pane label="未支付" name="1"></el-tab-pane>
      <el-tab-pane label="已支付" name="2"></el-tab-pane>
      <el-tab-pane label="已取消" name="3"></el-tab-pane>
    </el-tabs>
    <div v-for="item in list" :key="item.id" class="order-item" @click="navigateOrderDetail(item)">
      <wt-order-item-report v-if="item.businessType === 1" :item="item.reportResult"/>
      <wt-order-item-course v-if="item.businessType === 2" :item="item.courseResult"/>
    </div>
    <div class="pagination">
      <wt-pagination :total="listTotal" :page-size="10" :current-page="form.pageData.currentPage" @currentPageChange="currentPageChange"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'myOrder',
  data() {
    return {
      activeName: '0',
      form: {
        formData: {
          payStatus: ''
        },
        pageData: {
          currentPage: 1,
          pageSize: 10,
          sortName: 'createTime',
          sortOrder: 'desc'
        }
      },
      list: [],
      listTotal: 0
    }
  },
  mounted() {
    this.requestList()
    this.$bus.$on('refreshMyOrder', this.refreshMyOrder)
  },
  methods: {
    refreshMyOrder() {
      this.requestList()
    },
    handleClick() {
      console.log(this.activeName)
      switch (this.activeName) {
        case '0':
          this.form.formData.payStatus = ''
          break
        case '1':
          this.form.formData.payStatus = 0
          break
        case '2':
          this.form.formData.payStatus = 1
          break
        case '3':
          this.form.formData.payStatus = 2
          break
      }
      this.form.pageData.currentPage = 1
      this.requestList()
    },
    currentPageChange(e) {
      this.form.pageData.currentPage = e
      this.requestList()
    },
    requestList() {
      this.$wtRequest({
        url: `/api/order/list`,
        method: 'post',
        data: this.form
      }).then((res) => {
        res.data.list = res.data.list.map(item => {
          return {
            ...item,
            reportResult: { ...item.reportResult, payTime: item.payTime, payStatus: item.payStatus },
            courseResult: { ...item.courseResult, payTime: item.payTime, payStatus: item.payStatus }
          }
        })
        this.list = res.data.list
        this.listTotal = res.data.total
      }).catch(() => {})
    },
    navigateOrderDetail(item) {
      if (item.businessType === 1) {
        this.$router.push(`/user/order/r/${item.id}`)
      } else if (item.businessType === 2) {
        this.$router.push(`/user/order/c/${item.id}`)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
.main-box {
  padding: 24/$font 24/$font 60/$font 24/$font;
}
.order-item {
  border-bottom: 1/$font solid rgba(196, 196, 196, 0.5);
}
.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 32/$font;
}
::v-deep .el-tabs__item {
  font-size: 18px !important;
}
</style>
