<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  watch: {
    $route(val) {
      // this.$store.commit('SET_CURRENT_ROUTE', val)
    }
  }
}
</script>

<style>
  html body::-webkit-scrollbar {
    display: none;
  }
  body .el-table th.gutter{
    display: table-cell!important;
  }
   /* 调整填写数字时的表单样式 */
  .fill-info-container .el-input input{width: 220px;}
  .el-dialog .page-detail-container {overflow-y: auto;}
  /* 不显示数字输入框的右侧步长按钮 */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
</style>
