import WtLayout from './WtLayout'
import WtHomeBanner from './WtHomeBanner'
import WtTitleBar from './WtTitleBar'
import WtReportItem from './WtReportItem'
import WtReportItem2 from './WtReportItem2'
import WtReportItem3 from './WtReportItem3'
import WtCourseItem from './WtCourseItem'
import WtCourseItem2 from './WtCourseItem2'
import WtArticleItem from './WtArticleItem'
import WtLabelItem from './WtLabelItem'
import WtTrendsItem from './WtTrendsItem'
import WtSideMenuSingle from './WtSideMenuSingle'
import WtPagination from './WtPagination'
import WtOssUpload from './WtOssUpload'
import WtOrderItemReport from './WtOrderItem/report'
import WtOrderItemCourse from './WtOrderItem/course'
import WtCouponCard from './WtCouponCard/index'
import WtBrowseItemReport from './WtBrowseItem/report'
export default {
  WtLayout,
  WtHomeBanner,
  WtTitleBar,
  WtReportItem,
  WtReportItem2,
  WtReportItem3,
  WtCourseItem,
  WtCourseItem2,
  WtArticleItem,
  WtLabelItem,
  WtTrendsItem,
  WtSideMenuSingle,
  WtPagination,
  WtOssUpload,
  WtOrderItemReport,
  WtOrderItemCourse,
  WtCouponCard,
  WtBrowseItemReport
}
