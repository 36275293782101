<template>
  <div class="box">
    <el-form :model="form" :rules="rules" ref="verifyForm" label-width="90px" class="form-box">
      <el-form-item v-if="detailForm.isSetPassword === 1" label="原密码:" prop="sourcePassword">
        <el-input v-model="form.sourcePassword" type="password" size="small" placeholder="请输入原密码"></el-input>
      </el-form-item>
      <el-form-item label="新密码:" prop="password">
        <el-input v-model="form.password" type="password" size="small" placeholder="请输入新密码"></el-input>
      </el-form-item>
      <el-form-item label="确认密码:" prop="confirmPassword">
        <el-input v-model="form.confirmPassword" type="password" size="small" placeholder="请再次输入新密码"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="medium" @click="save('verifyForm')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'accountSecurity',
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo || {}
    })
  },
  data() {
    return {
      detailForm: {},
      form: {
        confirmPassword: '', // 确认密码
        password: '', // 密码
        sourcePassword: '' // 原密码
      },
      rules: {
        sourcePassword: [{ required: true, message: '请输入原密码', trigger: 'blur' }],
        password: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
        confirmPassword: [{ required: true, message: '请再次输入新密码', trigger: 'blur' }]
      }
    }
  },
  mounted() {
    console.log('账号安全装载')
    this.requestSelfInfo()
  },
  activated() {
    console.log('账号安全活动')
  },
  deactivated() {
    console.log('账号安全非活动')
  },
  destroyed() {
    console.log('账号安全卸载')
  },
  methods: {
    requestSelfInfo() {
      this.$wtRequest({
        url: `/api/user/selfInfo`,
        method: 'post'
      }).then((res) => {
        this.detailForm = res.data
      }).catch(() => {})
    },
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.password !== this.form.confirmPassword) return this.$message.warning('两次密码输入不一致')
          this.$wtRequest({
            url: `/api/user/updatePassword`,
            method: 'post',
            data: this.form
          }).then((res) => {
            if (res.code !== '0') {
              this.$message.error(res.msg)
            } else {
              this.requestSelfInfo()
              this.form = {
                confirmPassword: '', // 确认密码
                password: '', // 密码
                sourcePassword: '' // 原密码
              }
              this.$message.success('修改成功')
            }
          }).catch(() => {})
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
.box {
  padding: 40/$font 24/$font 340/$font 24/$font;
}
.form-box {
  width: 400/$font;
}
</style>
