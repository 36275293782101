/*
  全局过期器定义
  注意：1. 使用字符类型表示key值；2. 使用'default'表示默认值；3.当没有定义'default'值时，将返回空字符串
 */

const filters = {
  FILTER_YesOrNo: {
    '0': '否',
    '1': '是'
  },
  FILTER_Gender: {
    '1': '男',
    '2': '女'
  },
  FILTER_AdvancedType: {
    1: '热门',
    2: '最新',
    3: '爆款',
    default: ''
  },
  FILTER_MemberType: {
    1: '初级会员',
    2: '高级会员',
    3: '优享会员',
    default: ''
  },
  FILTER_CardType: {
    1: '月卡',
    2: '季卡',
    3: '年卡',
    default: ''
  },
  FILTER_OrderPayStatus: {
    0: '未支付',
    1: '已支付',
    2: '已取消',
    default: ''
  },
  FILTER_RightType: {
    1: '研报',
    2: '课程',
    default: ''
  },
  FILTER_DictType: {
    1: '研报标签',
    2: '课程分类',
    3: '热搜词',
    4: '文章标签',
    5: '动态标签',
    default: ''
  }
}

export default filters
