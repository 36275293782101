<template>
  <div class="box">
    <div v-if="item.isRecommend === 1" class="recommend-label" :style="`background-image: url(${labelBg});`">
      <span>推荐课</span>
    </div>
    <img v-if="item.imgUrl" :src="item.imgUrl" />
    <img v-else />
    <div v-if="item.priceDollar && item.priceDollar !== '0'" class="price" :style="`background-image: url(${priceBg});`"><span>￥</span>{{ item.priceDollar }}</div>
    <div v-else class="price" :style="`background-image: url(${priceBg});`">免费</div>
    <div class="title">{{item.name}}</div>
    <div class="duration">
      <wt-label-item :text="item.labelName" class="wt-m-r-12"/>
      时长：{{item.totalDurationFormat}}
    </div>
    <div class="content">{{item.introduction}}</div>
  </div>
</template>

<script>
export default {
  name: 'wt-course-item',
  props: {
    item: { type: Object, default: () => { return {} } }
  },
  data() {
    return {
      labelBg: require('@/assets/image/cursor/cursor-label-bg.png'),
      priceBg: require('@/assets/image/cursor/cursor-price-bg.png')
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
.box {
  position: relative;
  width: 282/$font;
  height: 364/$font;
  background: #FFFFFF;
  border-radius: 2/$font;
  border: 1/$font solid #F0F1F2;
  transition: box-shadow 0.2s, transform 0.2s;
  >img {
    width: 282/$font;
    height: 205/$font;
  }
  &:hover {
    box-shadow: 0px 5px 9px 2px rgba(34,98,182,0.1);
    transform: translateY(-10/$font);
  }
}
.recommend-label {
  position: absolute;
  top: 20/$font;
  left: -4/$font;
  padding: 6/$font 10/$font;
  width: auto;
  height: auto;
  background-size: 100% 100%;
  >span {
    position: relative;
    top: -3/$font;
    font-size: 13/$font;
    font-weight: 500;
    color: #FFFFFF;
  }
}
.price {
  position: relative;
  bottom: 48/$font;
  padding-left: 15/$font;
  width: 44%;
  height: 48/$font;
  background: rgba(0, 0, 0, 0);
  font-size: 22/$font;
  font-weight: 500;
  color: #FF6C00;
  line-height: 48/$font;
  background-size: 100% 100%;
  >span {
    font-size: 14/$font;
  }
}
.title {
  position: relative;
  bottom: 48/$font;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin: 16/$font 0 20/$font 0;
  padding: 0 14/$font 0 16/$font;
  width: 100%;
  height: 50/$font;
  font-size: 18/$font;
  font-weight: 600;
  color: #333333;
  line-height: 24/$font;
}
.duration {
  position: relative;
  bottom: 48/$font;
  display: flex;
  align-items: center;
  margin-bottom: 8/$font;
  padding: 0 16/$font;
  font-size: 14/$font;
  font-weight: 400;
  color: #333333;
}
.content {
  position: relative;
  bottom: 48/$font;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  padding: 0 16/$font;
  width: 100%;
  height: 20/$font;
  font-size: 14/$font;
  font-weight: 400;
  color: #999999;
  line-height: 20/$font;
}
</style>
