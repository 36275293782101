<template>
  <div>
    <div class="box1">
      <div class="card" :style="`background-image: url(${bgImg})`">
        <img src="@/assets/image/user/member-equity-icon1.png"/>
        <div>
          <div>报告权益</div>
          <div>{{ numberData(userInfo.memberLevel, 1, 1) }}、{{ numberData(userInfo.memberLevel, 1, 2) }}</div>
        </div>
      </div>
      <div class="card" :style="`background-image: url(${bgImg})`">
        <img src="@/assets/image/user/member-equity-icon2.png"/>
        <div>
          <div>课程权益</div>
          <div>{{ numberData(userInfo.memberLevel, 2, 1) }}</div>
        </div>
      </div>
    </div>
    <div class="divide"></div>
    <div class="title">充值记录</div>
    <div class="divider"></div>
    <div class="box2">
      <div v-for="item in list" :key="item.id" class="list-item">
        <div class="left">
          <div class="line1">{{ item.memberLevel | FILTER_MemberType }}</div>
          <div class="line2"><span>{{ item.memberScope | FILTER_CardType }}</span>有效期：{{ formatDate(item.startDate) }}～{{ formatDate(item.endDate) }}</div>
        </div>
        <div class="right"><span>￥</span>{{ item.priceDollar }}</div>
      </div>
      <div class="pagination">
        <wt-pagination :total="listTotal" :page-size="10" :current-page="form.pageData.currentPage" @currentPageChange="currentPageChange"/>
      </div>
    </div>
  </div>
</template>

<script>
import * as dayjs from 'dayjs'
import { mapState } from 'vuex'
export default {
  name: 'memberEquity',
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo || {}
    }),
    formatDate() {
      return (date) => {
        return date ? dayjs(date).format('YYYY年M月D日') : '--'
      }
    },
    numberData() {
      return (level, type, method) => {
        // method 1:查看 2:下载
        const data = this.tableData.find(item => item.level === level && item.type === type) || { downloadDayLimitNumber: 0, isFreeDownload: 0, isFreeRead: 0, readDayLimitNumber: 0 }
        if (method === 1) {
          if (data.isFreeRead !== 0) {
            return `免费查看`
          } else {
            return `免费查看${data.readDayLimitNumber}${type === 1 ? '篇' : '个'}/天`
          }
        } else {
          if (data.isFreeDownload !== 0) {
            return `免费下载`
          } else {
            return `免费下载${data.downloadDayLimitNumber}${type === 1 ? '篇' : '个'}/天`
          }
        }
      }
    }
  },
  data() {
    return {
      bgImg: require('@/assets/image/user/member-equity-bg.png'),
      form: {
        formData: {},
        pageData: {
          currentPage: 1,
          pageSize: 10,
          sortName: 'createTime',
          sortOrder: 'desc'
        }
      },
      list: [],
      listTotal: 0,
      tableData: []
    }
  },
  mounted() {
    this.requestList()
    this.requestMemberFunction()
  },
  methods: {
    currentPageChange(e) {
      this.form.pageData.currentPage = e
      this.requestList()
    },
    requestList() {
      this.$wtRequest({
        url: `/api/userMemberOrder/list`,
        method: 'post',
        data: this.form
      }).then((res) => {
        this.list = res.data.list
        this.listTotal = res.data.total
      }).catch(() => {})
    },
    requestMemberFunction() {
      const form = {
        formData: {},
        pageData: {
          currentPage: 1,
          pageSize: 20
        }
      }
      this.$wtRequest({
        url: `/api/memberFunction/list`,
        method: 'post',
        data: form
      }).then((res) => {
        this.tableData = res.data.list
      }).catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
.box1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40/$font 24/$font 40/$font 24/$font;
  .card {
    display: flex;
    align-items: center;
    padding: 0 20/$font;
    width: 428/$font;
    height: 85/$font;
    background-size: 100% 100%;
    border-radius: 10/$font;
    >img {
      margin-right: 10/$font;
      width: 63/$font;
      height: 63/$font;
    }
    >div {
      :nth-child(1) {
        width: auto;
        height: auto;
        font-size: 18/$font;
        font-weight: 500;
        color: #F5C791;
        line-height: 25/$font;
      }
      :nth-child(2) {
        margin-top: 8/$font;
        width: auto;
        height: auto;
        font-size: 14/$font;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20/$font;
        opacity: 0.6;
      }
    }
  }
}
.divide {
  width: 100%;
  height: 24/$font;
  background: #F5F7F9;
}
.title {
  padding: 17/$font 24/$font;
  width: auto;
  height: auto;
  font-size: 20/$font;
  font-weight: 500;
  color: #333333;
  line-height: 28/$font;
}
.divider {
  width: 100%;
  height: 1/$font;
  opacity: 0.5;
  border: 1/$font solid #C4C4C4;
}
.box2 {
  width: 100%;
  padding: 32/$font 24/$font 60/$font 24/$font;
  .list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24/$font;
    padding-bottom: 20/$font;
    width: 100%;
    border-bottom: 1/$font solid rgba(196, 196, 196, 0.5);
    .left {
      .line1 {
        margin-bottom: 12/$font;
        width: auto;
        height: auto;
        font-size: 18/$font;
        font-weight: 500;
        color: #333333;
        line-height: 25/$font;
      }
      .line2 {
        display: flex;
        align-items: center;
        font-size: 14/$font;
        font-weight: 400;
        color: #999999;
        >span {
          display: block;
          margin-right: 10/$font;
          padding: 4/$font 8/$font;
          width: auto;
          height: auto;
          font-size: 12/$font;
          font-weight: 500;
          color: #2262B6;
          background: #FFFFFF;
          border-radius: 2/$font;
          border: 1/$font solid #2262B6;
        }
      }
    }
    .right {
      width: auto;
      height: auto;
      font-size: 16/$font;
      font-weight: 400;
      color: #FF6C00;
      line-height: 20/$font;
      >span {
        font-size: 14/$font;
      }
    }
  }
}
.pagination {
  display: flex;
  justify-content: flex-end;
}
</style>
