<template>
  <div class="title-box">
    <div class="title">{{ title }}</div>
    <div class="split-bar"></div>
    <div class="subtitle">{{ subtitle }}</div>
    <div class="operation">
      <span @click="$emit('clickOperation')">{{ operationText }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'wt-title-bar',
  props: {
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    operationText: { type: String, default: '查看更多>' }
  },
  data() {
    return {}
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
.title-box {
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
  .title {
    width: auto;
    height: auto;
    font-size: 24/$font;
    font-weight: 500;
    color: #333333;
    line-height: 33/$font;
  }
  .split-bar {
    margin: 0 10/$font;
    width: 2/$font;
    height: 20/$font;
    background: $primary-color;
  }
  .subtitle {
    margin-right: 8/$font;
    width: auto;
    height: auto;
    font-size: 24/$font;
    font-weight: 500;
    color: #C4C4C4;
    line-height: 33/$font;
  }
  .operation {
    flex: 1;
    padding-bottom: 4/$font;
    width: auto;
    height: auto;
    font-size: 12/$font;
    font-weight: 400;
    color: #999999;
    text-align: right;
    border-bottom: 1/$font solid #C4C4C4;
    >span {
      font-size: 12/$font;
      cursor: pointer;
    }
  }
}
</style>
