const actionOperation = (val1, val2) => {
  const len1 = val1.toString().length - val1.toString().indexOf('.') - 1
  const len2 = val2.toString().length - val2.toString().indexOf('.') - 1
  const p = Math.max(len1, len2)
  return Math.pow(10, p)
}

export const addAction = (val1, val2) => {
  console.log('相加', val1, val2)
  const p = actionOperation(val1, val2)
  return ((val1 * p).toFixed(0) + (val2 * p).toFixed(0)) / p
}
export const subtractAction = (val1, val2) => {
  console.log('相减', val1, val2)
  const p = actionOperation(val1, val2)
  return ((val1 * p).toFixed(0) - (val2 * p).toFixed(0)) / p
}
export const multiplyAction = (val1, val2) => {
  console.log('相乘', val1, val2)
  const p = actionOperation(val1, val2)
  return ((val1 * p).toFixed(0) * (val2 * p).toFixed(0)) / (p * p)
}
export const divideAction = (val1, val2) => {
  console.log('相除', val1, val2)
  const p = actionOperation(val1, val2)
  return (val1 * p).toFixed(0) / (val2 * p).toFixed(0)
}
