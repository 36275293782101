<template>
  <div class="main-box">
    <div class="router-box">个人中心 > <span>会员购买</span></div>
    <div class="content-page">
      <div class="title-divider">确认订单信息</div>
      <div class="member-info">
        <img src="@/assets/image/user/member-icon.png"/>
        <div class="info">
          <div>{{detailForm.name}}</div>
          <div>购买后，有效期至{{detailForm.dayNumberDate}}</div>
          <ul>
            <li>{{numberData(detailForm.level, 1, 1)}}</li>
            <li>{{numberData(detailForm.level, 1, 2)}}</li>
            <li>{{numberData(detailForm.level, 2, 1)}}</li>
          </ul>
        </div>
        <div class="price"><span>￥</span>{{detailForm.priceDollar}}</div>
      </div>
      <div class="title-divider wt-m-t-40">
        优惠券
        <div class="tip">使用优惠券<el-checkbox v-model="use" class="wt-m-l-10"></el-checkbox></div>
      </div>
      <el-radio-group v-if="use" v-model="selectedCoupon" style="width: 100%;">
        <div class="coupon-card-box">
          <div v-for="(item, index) in availableCoupon" :key="item.id" :style="`${(index + 1) % 3 === 0 ? 'margin-right: 0;' : ''}`">
            <wt-coupon-card :item="item" class="coupon-card"/>
            <el-radio :label="item.id" :disabled="item.type === 1 && Number(item.fullSubtractPriceDollar) > Number(detailForm.priceDollar)" class="radio">{{''}}</el-radio>
          </div>
        </div>
      </el-radio-group>
    </div>
    <div class="content-page pay-box wt-m-t-24 wt-p-b-32">
      <div class="price">总计：<span>￥<span>{{totalPrice}}</span></span></div>
      <div class="pay-operation">
        <el-checkbox v-model="agreement" class="wt-m-r-10"></el-checkbox>
        同意<span @click="dialogVisible = true">《车百智库购买协议》</span>
        <el-button type="primary" size="medium" @click="payBtn">微信支付</el-button>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="45%">
      <!--      <span slot="title" class="agreement-title">购买协议</span>-->
      <div v-html="agreementContent" class="agreement-content"></div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="confirmAgreement">确定并同意</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="payDialogVisible"
      width="40%"
      @close="closePayDialog">
      <div class="pay-qr-code">
        <div v-if="waitPay" class="pay-wait">
          <div class="title">微信支付</div>
          <img v-if="payQrCodeImg" :src="payQrCodeImg">
          <div class="tip">请打开手机微信，扫一扫完成支付</div>
        </div>
        <div v-else class="pay-success">
          <img src="@/assets/image/pay/pay-success.png"/>
          <div>支付成功</div>
          <div class="tip">恭喜您成为{{detailForm.name}}，有效期至{{dayNumberDate}}</div>
          <div>
            <el-button size="small" type="default" @click="navigateHome">返回首页</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as dayjs from 'dayjs'
import { mapState } from 'vuex'
import { multiplyAction, subtractAction } from '../../utils/highPrecisionComputation'
export default {
  name: 'memberOrder',
  mounted() {
    console.log('得到的参数', this.$route.params.id)
    this.id = this.$route.params.id
    this.requestDetail()
    this.requestCoupon()
    this.getPayAgreementDetail()
    this.requestMemberFunction()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo || {}
    }),
    totalPrice() {
      let result = ''
      if (this.selectedCoupon !== '') {
        const coupon = this.availableCoupon.find(item => item.id === this.selectedCoupon)
        // 下单时需要进行优惠价格计算
        if (coupon.type === 1) {
          result = subtractAction(this.detailForm.priceDollar, coupon.favourablePriceDollar)
        } else {
          result = multiplyAction(this.detailForm.priceDollar, Number(coupon.discount) / 10)
        }
        result = result.toString()
        if (result.indexOf('.') >= 0) {
          const index = result.indexOf('.')
          result = `${result.substr(0, index + 1)}${result.substr(index + 1, 2)}`
        }
      } else {
        result = this.detailForm.priceDollar
      }
      return result
    },
    dateFormat() {
      return (date) => {
        return dayjs(date).format('YYYY年M月D日')
      }
    },
    numberData() {
      return (level, type, method) => {
        // method 1:查看 2:下载
        const data = this.tableData.find(item => item.level === level && item.type === type) || { downloadDayLimitNumber: 0, isFreeDownload: 0, isFreeRead: 0, readDayLimitNumber: 0 }
        if (method === 1) {
          if (data.isFreeRead !== 0) {
            return `${type === 1 ? '报告' : '课程'}免费查看`
          } else {
            return `每天免费查看${data.readDayLimitNumber}${type === 1 ? '篇报告' : '个课程'}`
          }
        } else {
          if (data.isFreeDownload !== 0) {
            return `${type === 1 ? '报告' : '课程'}免费下载`
          } else {
            return `每天免费下载${data.downloadDayLimitNumber}${type === 1 ? '篇报告' : '个课程'}`
          }
        }
      }
    }
  },
  watch: {
    use(newVal) {
      if (!newVal) this.selectedCoupon = ''
    }
  },
  data() {
    return {
      id: '',
      detailForm: {},
      dayNumberDate: '',
      availableCoupon: [],
      selectedCoupon: '',
      agreementContent: '',
      payQrCodeImg: '',
      use: false,
      agreement: false,
      dialogVisible: false,
      payDialogVisible: false,
      waitPay: true,
      memberOrderId: '',
      tableData: [],
      timer: null
    }
  },
  methods: {
    async payBtn() {
      if (!this.agreement) return this.$message.warning('请同意车百智库购买协议')
      await this.addOrder()
      this.payDialogVisible = true
    },
    requestDetail() {
      this.dayNumberDate = JSON.parse(JSON.stringify(this.detailForm.dayNumberDate || ''))
      this.$wtRequest({
        url: `/api/memberLevel/detail`,
        method: 'post',
        data: { id: this.id }
      }).then((res) => {
        const data = res.data
        this.$wtRequest({
          url: `/api/userMemberOrder/detailByLevelAndUser`,
          method: 'post',
          data: { id: data.level }
        }).then((res) => {
          const levelData = res.data
          let dayNumber = data.dayNumber
          let date = levelData.realEndDate
          if (!date || new Date(date) < new Date()) {
            date = new Date()
            dayNumber = dayNumber - 1
          }
          const theDate = new Date(date)
          theDate.setDate(theDate.getDate() + dayNumber)
          data.dayNumberDate = dayjs(theDate).format('YYYY年M月D日')
          this.detailForm = data
        }).catch(() => {})
      }).catch(() => {})
    },
    requestCoupon() {
      const form = {
        formData: {
          useClass: 3
        },
        pageData: {
          pageSize: -1,
          sortName: 'createTime',
          sortOrder: 'desc'
        }
      }
      this.$wtRequest({
        url: `/api/userCoupon/availableList`,
        method: 'post',
        data: form
      }).then((res) => {
        this.availableCoupon = res.data.list
      }).catch(() => {})
    },
    getPayAgreementDetail() {
      this.$wtRequest({
        url: `/api/content/payAgreementDetail`,
        method: 'post'
      }).then((res) => {
        this.agreementContent = res.data.content
      }).catch(() => {})
    },
    requestMemberFunction() {
      const form = {
        formData: {},
        pageData: {
          currentPage: 1,
          pageSize: 20
        }
      }
      this.$wtRequest({
        url: `/api/memberFunction/list`,
        method: 'post',
        data: form
      }).then((res) => {
        this.tableData = res.data.list
      }).catch(() => {})
    },
    confirmAgreement() {
      this.agreement = true
      this.dialogVisible = false
    },
    closePayDialog() { // 如果未支付关闭则调取消订单
      if (this.memberOrderId && this.waitPay) {
        clearInterval(this.timer)
        this.cancelOrder()
      }
    },
    cancelOrder() {
      this.$wtRequest({
        url: `/api/userMemberOrder/notPayCancel`,
        method: 'post',
        data: { id: this.memberOrderId }
      }).then(() => {
      }).catch(() => {})
    },
    async addOrder() { // 新增订单 调获取支付二维码
      await this.$wtRequest({
        url: `/api/userMemberOrder/add`,
        method: 'post',
        data: { id: this.id, userCouponId: this.selectedCoupon }
      }).then(async(res) => {
        this.memberOrderId = res.data.id
        await this.payQrCode(this.memberOrderId)
      }).catch(() => {})
    },
    async payQrCode(orderId) {
      await this.$wtRequest({
        url: `/api/pay/pcMemberOrderPayQrCode`,
        method: 'post',
        data: { id: orderId }
      }).then((res) => {
        this.payQrCodeImg = res.data
        this.isPay()
      }).catch(() => {})
    },
    isPay() {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.$wtRequest({
          url: `/api/userMemberOrder/isPay`,
          method: 'post',
          data: { id: this.memberOrderId }
        }).then((res) => {
          if (res.data === 1) {
            this.waitPay = false
            this.$store.dispatch('setStoreUserInfo', this.userInfo.id)
            this.selectedCoupon = ''
            this.requestDetail()
            this.requestCoupon()
            clearInterval(this.timer)
          }
        }).catch(() => {})
      }, 1000)
    },
    navigateHome() {
      this.payDialogVisible = false
      this.$router.replace('/home')
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
.main-box {
  padding: 16/$font 120/$font 60/$font 120/$font;
  width: 1440/$font;
  height: auto;
  background: #F5F7F9;
}
.router-box {
  margin-bottom: 16/$font;
  width: 100%;
  height: auto;
  font-size: 14/$font;
  font-weight: 400;
  color: #666666;
  line-height: 20/$font;
  >span {
    color: $primary-color;
    font-size: 14/$font;
    font-weight: 400;
    line-height: 20/$font;
  }
}
.content-page {
  padding: 24/$font 24/$font 60/$font 24/$font;
  width: 1200/$font;
  height: auto;
  background: #FFFFFF;
  .title-divider {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 0 0 32/$font 0;
    padding: 0 0 20/$font 14/$font;
    width: auto;
    height: 50/$font;
    font-size: 22/$font;
    font-weight: 500;
    color: #333333;
    line-height: 30/$font;
    border-bottom: 1/$font solid rgba(196, 196, 196, 0.5);
    &:before {
      content: '';
      position: absolute;
      top: 4/$font;
      left: 0;
      width: 4/$font;
      height: 22/$font;
      background: #FF6C00;
    }
    .tip {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 120/$font;
      height: 20/$font;
      font-size: 14/$font;
      font-weight: 400;
      color: #333333;
      line-height: 20/$font;
    }
  }
  .member-info {
    position: relative;
    width: 1152/$font;
    height: 219/$font;
    background: #F5F7F9;
    >img {
      position: absolute;
      top: 24/$font;
      left: 24/$font;
      width: 48/$font;
      height: 48/$font;
    }
    .info {
      position: absolute;
      top: 29/$font;
      left: 92/$font;
      width: auto;
      height: auto;
      :nth-child(1) {
        font-size: 26/$font;
        font-weight: 600;
        color: #333333;
        line-height: 37/$font;
      }
      :nth-child(2) {
        margin-top: 9/$font;
        font-size: 14/$font;
        font-weight: 400;
        color: #666666;
        line-height: 20/$font;
      }
      >ul {
        margin-top: 24/$font;
        padding-inline-start: 20/$font;
        li {
          margin-top: 8/$font !important;
          font-size: 14/$font !important;
          font-weight: 400 !important;
          color: #E2B16C !important;
          line-height: 20/$font !important;
        }
      }
    }
    .price {
      position: absolute;
      top: 26/$font;
      right: 24/$font;
      font-size: 25/$font;
      font-weight: 500;
      color: #FF6C00;
      line-height: 22/$font;
      >span {
        font-size: 16/$font;
        font-weight: 400;
      }
    }
  }
}
.coupon-card-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  >div {
    position: relative;
    margin-right: 30/$font;
    margin-bottom: 24/$font;
  }
}
.coupon-card {
  width: 362/$font;
  height: 111/$font;
}
.radio {
  position: absolute;
  bottom: 10/$font;
  right: 0;
}
.pay-box {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .price {
    margin-bottom: 16/$font;
    width: auto;
    height: auto;
    font-size: 14/$font;
    font-weight: 400;
    color: #999999;
    line-height: 20/$font;
    >span {
      margin-left: 38/$font;
      width: auto;
      height: auto;
      font-size: 16/$font;
      font-weight: 400;
      color: #FF6C00;
      line-height: 22/$font;
      >span {
        font-size: 26/$font;
      }
    }
  }
  .pay-operation {
    display: flex;
    align-items: center;
    width: auto;
    height: auto;
    font-size: 14/$font;
    font-weight: 400;
    color: #333333;
    line-height: 20/$font;
    >span {
      margin-right: 24/$font;
      width: 140/$font;
      min-width: 120px;
      height: 20/$font;
      font-size: 14/$font;
      font-weight: 400;
      color: #FF6C00;
      line-height: 20/$font;
      cursor: pointer;
    }
  }
}
.agreement-title {
  font-size: 18/$font;
  font-weight: 500;
  color: #333333;
  line-height: 24/$font;
}
.agreement-content {
  font-size: 14/$font;
  font-weight: 400;
  color: #333333;
  line-height: 22/$font;
}
.pay-qr-code {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .pay-wait {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      margin-bottom: 60/$font;
      width: 100%;
      height: auto;
      font-size: 18/$font;
      font-weight: 500;
      color: #333333;
      line-height: 24/$font;
      text-align: center;
    }
    >img {
      width: 196/$font;
      height: 196/$font;
    }
    .tip {
      margin-top: 10/$font;
      padding: 8/$font;
      width: auto;
      height: auto;
      font-size: 12/$font;
      font-weight: 400;
      color: #FFFFFF;
      background: #2262B6;
    }
  }
  .pay-success {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    >img {
      width: 80/$font;
      height: 80/$font;
    }
    >div {
      margin-top: 24/$font;
      width: auto;
      height: auto;
      font-size: 24/$font;
      font-weight: 500;
      color: #333333;
      line-height: 32/$font;
    }
    .tip {
      margin-top: 8/$font;
      font-size: 14/$font;
      font-weight: 400;
      color: #999999;
      line-height: 22/$font;
    }
  }
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: rgba(206, 37, 37, 1);
  background: rgba(206, 37, 37, 1);
}
</style>
