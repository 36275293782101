<template>
  <div class="main-box">
    <div v-for="item in list" :key="item.id" class="order-item" @click="navigateOrderDetail(item)">
      <wt-browse-item-report v-if="item.businessType === 1" :item="item.reportResult"/>
      <wt-article-item v-if="item.businessType === 3" :item="item.articleResult" class="wt-m-t-24 wt-m-b-24"/>
    </div>
    <div class="pagination">
      <wt-pagination :total="listTotal" :page-size="10" :current-page="form.pageData.currentPage" @currentPageChange="currentPageChange"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'myBrowse',
  data() {
    return {
      form: {
        formData: {},
        pageData: {
          currentPage: 1,
          pageSize: 10,
          sortName: 'createTime',
          sortOrder: 'desc'
        }
      },
      list: [],
      listTotal: 0
    }
  },
  mounted() {
    this.requestList()
  },
  methods: {
    currentPageChange(e) {
      this.form.pageData.currentPage = e
      this.requestList()
    },
    requestList() {
      this.$wtRequest({
        url: `/api/userReadRecord/list`,
        method: 'post',
        data: this.form
      }).then((res) => {
        this.list = res.data.list
        this.listTotal = res.data.total
      }).catch(() => {})
    },
    navigateOrderDetail(item) {
      let routeUrl = ''
      switch (item.businessType) {
        case 1: // 研报
          routeUrl = this.$router.resolve(`/report/detail/${item.reportResult.id}`)
          break
        case 2: // 课程
          break
        case 3: // 文章
          routeUrl = this.$router.resolve(`/article/detail/${item.articleResult.id}`)
          break
      }
      window.open(routeUrl.href, '_blank')
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
.main-box {
  padding: 24/$font 24/$font 60/$font 24/$font;
}
.order-item {
  border-bottom: 1/$font solid rgba(196, 196, 196, 0.5);
}
.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 32/$font;
}
::v-deep .el-tabs__item {
  font-size: 18px !important;
}
</style>
