<template>
  <div>
    <div class="content-box">
      <div class="left">
        <div class="header-box">
          <img :src="userInfo.wechatPhotoUrl || defaultHeader" class="header">
          <div v-if="userInfo.memberLevel !== 1" class="member-label">
            <img src="@/assets/image/user/label-card.png"/>
            <img src="@/assets/image/user/large-v-logo.png"/>
            <div>{{ userInfo.memberLevel === 2 ? '高级会员' : '尊享会员' }}</div>
          </div>
          <div class="nick-name">{{ userInfo.name }}</div>
          <div class="tip">会员有效期至：{{ formatDate }}</div>
          <div class="up-level-card" @click="clickMemberUp">
            <img src="@/assets/image/user/bg-card.png"/>
            <div class="content">
              <div class="title">
                <img src="@/assets/image/user/large-v.png"/>
                升级会员
              </div>
              <div class="more">
                尊享更多权益
                <img src="@/assets/image/user/arrow.png"/>
              </div>
            </div>
          </div>
        </div>
        <wt-side-menu-single :list="labelList" :indicator-bar="true" :show-active="active.id !== 0" text-align="center" @click="clickMenu" class="wt-p-t-0 wt-p-b-0"/>
      </div>
      <div class="right">
        <div v-if="isShowTitle" class="title">{{ active.title }}</div>
        <div v-if="isShowTitle" class="divider"></div>
        <keep-alive>
          <component v-if="isKeepAlive" :is="active.componentName"></component>
        </keep-alive>
        <component v-if="!isKeepAlive" :is="active.componentName"></component>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import memberUp from '@/modules/user/components/memberUp'
import personalData from '@/modules/user/components/personalData'
import accountSecurity from '@/modules/user/components/accountSecurity'
import memberEquity from '@/modules/user/components/memberEquity'
import myOrder from '@/modules/user/components/myOrder'
import myBrowse from '@/modules/user/components/myBrowse'
import myCoupon from '@/modules/user/components/myCoupon'
import * as dayjs from 'dayjs'
export default {
  name: 'index',
  components: { memberUp, personalData, accountSecurity, memberEquity, myOrder, myBrowse, myCoupon },
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo || {}
    }),
    isKeepAlive() {
      return this.keepAliveList.indexOf(this.active.componentName) !== -1
    },
    isShowTitle() {
      return this.active.id !== 4
    },
    formatDate() {
      return this.userInfo.memberEndTime ? dayjs(this.userInfo.memberEndTime).format('YYYY年M月D日') : '--'
    }
  },
  data() {
    return {
      keepAliveList: ['personal-data'],
      labelList: [
        { title: '个人资料', id: 1, componentName: 'personal-data' },
        { title: '账号安全', id: 2, componentName: 'account-security' },
        { title: '会员权益', id: 3, componentName: 'member-equity' },
        { title: '我的订单', id: 4, componentName: 'my-order' },
        { title: '我的浏览', id: 5, componentName: 'my-browse' },
        { title: '我的优惠券', id: 6, componentName: 'my-coupon' }
      ],
      active: { title: '个人资料', id: 1, componentName: 'personal-data' },
      defaultHeader: require('@/assets/image/login/default-header.png')
    }
  },
  methods: {
    clickMenu(e) {
      console.log(e)
      this.active = e
    },
    clickMemberUp() {
      this.active = { title: '升级会员', id: 0, componentName: 'member-up' }
    },
    currentPageChange(e) {
      console.log('当前页面', e)
    },
    navigateDetail() {
      this.$router.push(`/article/detail/10086`)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
img {
  -webkit-user-drag: none;
}
.content-box {
  display: flex;
  align-items: self-start;
  justify-content: space-between;
  padding: 24/$font 120/$font 60/$font 120/$font;
  width: 1440/$font;
  height: auto;
  background: #F5F7F9;
  .left {
    width: 240/$font;
    height: auto;
    .header-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 24/$font;
      padding: 24/$font 0 23/$font 0;
      width: 100%;
      height: 291/$font;
      background: #FFFFFF;
      .header {
        width: 100/$font;
        height: 100/$font;
        border-radius: 50%;
      }
      .member-label {
        position: relative;
        top: -20/$font;
        width: 85/$font;
        height: 30/$font;
        :nth-child(1) {
          position: absolute;
          top: 4/$font;
          right: 0;
          width: 70/$font;
          height: 22/$font;
        }
        :nth-child(2) {
          position: absolute;
          top: 0;
          left: 0;
          width: 30/$font;
          height: 30/$font;
        }
        :nth-child(3) {
          position: absolute;
          top: 4/$font;
          right: 0;
          padding-right: 4/$font;
          width: 70/$font;
          height: 22/$font;
          font-size: 11/$font;
          font-weight: 500;
          color: #9F5300;
          line-height: 22/$font;
          text-align: right;
        }
      }
      .nick-name {
        margin: 14/$font 0 4/$font 0;
        width: auto;
        height: auto;
        font-size: 18/$font;
        font-weight: 500;
        color: #333333;
        line-height: 25/$font;
      }
      .tip {
        width: auto;
        height: auto;
        font-size: 12/$font;
        font-weight: 400;
        color: #999999;
        text-align: center;
        line-height: 17/$font;
      }
      .up-level-card {
        position: relative;
        margin-top: 26/$font;
        width: 208/$font;
        height: 48/$font;
        cursor: pointer;
        >img {
          position: absolute;
          top: 0;
          left: 0;
          width: 208/$font;
          height: 48/$font;
        }
        .content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 208/$font;
          height: 48/$font;
          .title {
            position: relative;
            z-index: 10;
            display: flex;
            flex: 1;
            align-items: center;
            font-size: 14/$font;
            font-weight: 500;
            color: #9F5300;
            line-height: 20/$font;
            >img {
              margin-left: 12/$font;
              width: 16/$font;
              height: 16/$font;
            }
          }
          .more {
            position: relative;
            z-index: 10;
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: flex-end;
            font-size: 12/$font;;
            font-weight: 400;
            color: #9F5300;
            line-height: 17/$font;;
            >img {
              margin-right: 8/$font;
              width: 16/$font;
              height: 16/$font;
            }
          }
        }
      }
    }
  }
  .right {
    width: 936/$font;
    height: auto;
    background: #FFFFFF;
    .title {
      padding: 17/$font 24/$font;
      width: auto;
      height: auto;
      font-size: 20/$font;
      font-weight: 500;
      color: #333333;
      line-height: 28/$font;
    }
    .divider {
      width: 100%;
      height: 1/$font;
      opacity: 0.5;
      border: 1/$font solid #C4C4C4;
    }
  }
}
@media screen and ( max-width: 1440px ) {
  //.content-box {
  //  .left {
  //    width: 240px;
  //  }
  //  .right {
  //    width: 936/$font;
  //  }
  //}
}
</style>
