// 下面两行是为了兼容IE11
// import 'core-js/stable'
// import 'regenerator-runtime/runtime'
import './config'
import Vue from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
import 'normalize.css'
import '@/styles/index.scss'

import '@/utils/adapter'

import '@/assets/icons' // icon
import '@/utils/permission' // permission control
import wtUtil from '@/utils/wtUtil'
import wtValidator from '@/utils/wtValidator'
import request from '@/utils/request'
import wtUploadRequest from '@/utils/wtUploadRequest'
import components from '@/components'
import moduleComponents from '@/modules'
import wtConst from '@/utils/wtConst'
import Bus from '@/utils/bus'
import '@/filters'
import moment from 'moment'
import 'moment/locale/zh-cn'
import commonJs from '@/utils/common'
import wtAuthUtil from '@/utils/wtAuthUtil'
import { WtCrudInstaller } from '@wangtiansoft/wt-crud'

// console.log = () => void (0)

Vue.prototype.commonJs = commonJs
Vue.prototype.$wtUtil = wtUtil
Vue.prototype.$wtValidator = wtValidator
Vue.prototype.$wtRequest = request
Vue.prototype.$wtUploadRequest = wtUploadRequest
Vue.prototype.$moment = moment
Vue.prototype.$wtAuthUtil = wtAuthUtil
Vue.prototype.$wtConst = wtConst
Vue.prototype.$Bus = Bus
Object.keys(components).forEach(key => {
  const component = components[key]
  Vue.component(component.name, component)
})
Object.keys(moduleComponents).forEach(key => {
  const component = moduleComponents[key]
  Vue.component(component.name, component)
})

Vue.use(ElementUI)

Vue.config.productionTip = false

window.store = store

Vue.prototype.$bus = new Vue() // event Bus 用于无关系组件间的通信。
Vue.prototype.$store = store
Vue.prototype.$confirm = ElementUI.MessageBox.confirm
Vue.component('avatar', ElementUI.Avatar)
Vue.use(WtCrudInstaller, { axios: request, router })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
