import request from '@/utils/request'
import {
  getToken,
  getPassword,
  setPassword,
  setToken,
  setStorgeItem,
  getStorgeItem,
  clearStorge,
  removeStorge,
  getStorge,
  setStorge
} from '@/utils/auth'

const auth = {
  state: {
    token: getToken(),
    userInfo: getStorgeItem('userInfo'),
    needPassword: getPassword('needPassword'),
    isDynamicRoutesInit: false,
    roleList: [],
    moduleItem: {},
    moduleList: [],
    roleCodeList: [],
    moduleCodeList: [],
    permissionCodeList: []
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
      setToken(token)
    },
    SET_USER_ID: (state, userId) => {
      state.userId = userId
      setStorgeItem('userId', userId)
    },
    SET_PASSWORD: (state, password) => {
      state.password = password
      setPassword('needPassword', password)
    },
    SET_USERINFO: (state, data) => {
      state.userInfo = data
      setStorgeItem('userInfo', data)
    },
    SET_AUTHINFO: (state, data) => {
      state.roleCodeList = data.roleList
      state.moduleCodeList = data.moduleList
      state.permissionCodeList = data.permissionList
    },
    SET_DYNAMIC_ROUTES: (state, dynamicRoutes) => {
      state.dynamicRoutes = dynamicRoutes
      state.isDynamicRoutesInit = true
    },
    SET_ROLE_LIST: (state, roles) => {
      state.roleList = roles
    },
    SET_MODULE_ITEM: (state, moduleItem) => {
      state.moduleItem = moduleItem
    },
    SET_MODULE_LIST: (state, moduleList) => {
      state.moduleList = moduleList
      if (moduleList && moduleList.length > 0) {
        for (let i = 0; i < moduleList.length; i++) {
          if (!moduleList[i].hidden) {
            state.moduleItem = moduleList[i]
            break
          }
        }
      }
    },
  },

  actions: {
    // 设置token
    setStoreToken({ commit }, token) {
      setStorge('x-is-remember', 'true')
      commit('SET_TOKEN', token)
    },
    // 设置用户信息
    setStoreUserInfo({ commit }, userId) {
      commit('SET_USER_ID', userId)
      request({
        url: '/api/user/selfInfo',
        method: 'post'
      }).then(response => {
        commit('SET_USERINFO', response.data)
      }).catch(error => {})
    },
    // 登录
    requestLogin({ commit }, formData) {
      const username = formData.username.trim()
      const password = formData.password
      return new Promise((resolve, reject) => {
        request({
          url: '/modules/auth/login',
          method: 'post',
          data: {
            'username': username,
            'password': password
          }
        }).then(response => {
          setStorge('x-is-remember', formData.isRemember)
          if (formData.isRemember) {
            setStorge('username', formData.username)
            setStorge('password', formData.password)
          } else {
            removeStorge('username')
            removeStorge('password')
          }
          commit('SET_TOKEN', response.data.token)
          commit('SET_PASSWORD', response.data.isNeedSetPassword)
          commit('SET_USERINFO', response.data.accountResult)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 登出
    requestLogout({ commit, state }) {
      return new Promise((resolve, reject) => {
        request({
          url: 'api/user/logout',
          method: 'post'
        }).then(response => {
          commit('SET_TOKEN', '')
          commit('SET_USERINFO', {})
          commit('SET_ROLE_LIST', [])
          clearStorge()
          const isRemember = getStorge('x-is-remember')
          if (isRemember !== 'true') {
            removeStorge('username')
            removeStorge('password')
          }
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        resolve()
      })
    }
  }
}

export default auth
