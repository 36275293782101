<template>
  <div
    class="label"
    :style="{ background: bgColor, color: color }">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'wt-label-item',
  props: {
    text: { type: String, default: '' },
    bgColor: { type: String, default: 'rgba(34, 98, 182, 0.15)' },
    color: { type: String, default: '#2262B6' }
  },
  data() {
    return {}
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
.label {
  padding: 4/$font 8/$font;
  width: fit-content;
  height: auto;
  font-size: 12/$font;
  font-weight: 500;
  color: #2262B6;
  background: rgba(34, 98, 182, 0.15);
  border-radius: 2/$font;
}
</style>
