<template>
  <div>
    <wt-home-banner :list="bannerList" @click="bannerNavigation"/>
    <div class="content-box wt-p-t-40 wt-p-b-40">
      <wt-title-bar title="精品报告" subtitle="REPORT" @clickOperation="navigateTo('report')"/>
      <div class="report-list-box">
        <div v-for="item in reportList" :key="item.id" @click="navigateReportDetail(item.id)">
          <wt-report-item :item="item"/>
        </div>
      </div>
    </div>
<!--    <div class="content-box2 wt-p-t-32 wt-p-b-38">-->
<!--      <wt-title-bar title="精品课程" subtitle="LESSION" @clickOperation="navigateTo('course')"/>-->
<!--      <div class="course-list-box wt-m-t-36">-->
<!--        <div v-for="item in cursorList" :key="item.id" @click="navigateCourseDetail(item.id)">-->
<!--          <wt-course-item :item="item"/>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="content-box3 wt-p-t-36 wt-p-b-60">
      <div class="left">
        <wt-title-bar title="文章资讯" subtitle="INFORMATION" @clickOperation="navigateTo('article')"/>
        <div class="wt-p-t-32">
          <div v-for="item in articleList" :key="item.id" class="wt-m-b-24" @click="navigateArticleDetail(item.id)">
            <wt-article-item :item="item"/>
          </div>
        </div>
      </div>
      <div class="right">
        <wt-title-bar title="行业动态" subtitle="TRENDS" @clickOperation="navigateTo('dynamics')"/>
        <div class="wt-p-t-32">
          <div v-for="item in dynamicsList" :key="item.id" class="wt-m-b-24" @click="navigateDynamicsDetail(item.id)">
            <wt-trends-item :item="item"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index',
  data() {
    return {
      bannerList: [],
      reportList: [],
      cursorList: [],
      articleList: [],
      dynamicsList: []
    }
  },
  mounted() {
    console.log('每次都重新吗  首页')
  },
  activated() {
    console.log('页面活动  首页')
    this.requestBannerList()
    this.requestReportList()
    this.requestCursorList()
    this.requestArticleList()
    this.requestDynamicsList()
  },
  methods: {
    navigateTo(url) {
      this.$router.push(`/${url}`)
    },
    bannerNavigation(e) {
      if (e.skipUrl) window.open(e.skipUrl, '_blank')
    },
    requestBannerList() {
      const params = {
        formData: { type: 2 },
        pageData: { pageSize: -1 }
      }
      this.$wtRequest({
        url: `/api/banner/list`,
        method: 'post',
        data: params
      }).then((res) => {
        this.bannerList = res.data.list
      }).catch(() => {})
    },
    requestReportList() {
      const params = {
        formData: { isFirstPageShow: 1, isDynamic: 0 },
        pageData: { pageSize: -1 }
      }
      this.$wtRequest({
        url: `/api/report/list`,
        method: 'post',
        data: params
      }).then((res) => {
        this.reportList = res.data.list
      }).catch(() => {})
    },
    requestCursorList() {
      const params = {
        formData: { isFirstPageShow: 1 },
        pageData: { currentPage: 1, pageSize: 4 }
      }
      this.$wtRequest({
        url: `/api/course/list`,
        method: 'post',
        data: params
      }).then((res) => {
        this.cursorList = res.data.list
      }).catch(() => {})
    },
    requestArticleList() {
      const params = {
        formData: {},
        pageData: { pageSize: 4, currentPage: 1, sortName: 'createTime', sortOrder: 'desc' }
      }
      this.$wtRequest({
        url: `/api/article/list`,
        method: 'post',
        data: params
      }).then((res) => {
        this.articleList = res.data.list
      }).catch(() => {})
    },
    requestDynamicsList() {
      const params = {
        formData: { isDynamic: 1 },
        pageData: { pageSize: 3, currentPage: 1, sortName: 'createTime', sortOrder: 'desc' }
      }
      this.$wtRequest({
        url: `/api/report/list`,
        method: 'post',
        data: params
      }).then((res) => {
        this.dynamicsList = res.data.list
      }).catch(() => {})
    },
    navigateArticleDetail(id) {
      const routeUrl = this.$router.resolve(`/article/detail/${id}`)
      window.open(routeUrl.href, '_blank')
      // this.$router.push(`/article/detail/${id}`)
    },
    navigateCourseDetail(id) {
      const routeUrl = this.$router.resolve(`/course/detail/${id}`)
      window.open(routeUrl.href, '_blank')
    },
    navigateDynamicsDetail(id) {
      const routeUrl = this.$router.resolve(`/dynamics/detail/${id}`)
      window.open(routeUrl.href, '_blank')
    },
    navigateReportDetail(id) {
      const routeUrl = this.$router.resolve(`/report/detail/${id}`)
      window.open(routeUrl.href, '_blank')
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
.content-box {
  padding: 0 120/$font;
  width: 1440/$font;
  height: auto;
  background: #FFFFFF;
}
.content-box2 {
  padding: 0 120/$font;
  width: 1440/$font;
  height: 498/$font;
  background: #F5F7F9;
}
.content-box3 {
  display: flex;
  justify-content: space-between;
  padding: 0 120/$font;
  width: 1440/$font;
  height: 808/$font;
  background: #FFFFFF;
  .left {
    width: 678/$font;
  }
  .right {
    width: 463/$font;
  }
}
.report-list-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  >div {
    margin-right: 24/$font;
  }
  >div:nth-child(3n+3) {
    margin-right: 0;
  }
}
.course-list-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  >div {
    margin-right: 24/$font;
  }
  >div:nth-child(4n+4) {
    margin-right: 0;
  }
}
</style>
