<template>
  <div class="main-box">
    <div class="router-box">文章 > <span>文章详情</span></div>
    <div class="content-page">
      <div class="title">{{ detailForm.title }}</div>
      <div class="date-time">{{ (detailForm.createTime || '').substr(0, 16) }}</div>
      <div v-html="detailForm.content" class="content"></div>
      <div class="divider"></div>
      <div class="wt-flex">
        <wt-label-item :text="detailForm.labelName" bg-color="rgba(196, 196, 196, 0.2)" color="#999999" class="wt-m-r-16"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'detail',
  data() {
    return {
      id: '',
      detailForm: {}
    }
  },
  activated() {
    console.log('得到的参数', this.$route.params.id)
    this.id = this.$route.params.id
    this.requestDetail()
  },
  methods: {
    requestDetail() {
      this.$wtRequest({
        url: `/api/article/detail`,
        method: 'post',
        data: { id: this.id }
      }).then((res) => {
        this.detailForm = res.data
      }).catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
.main-box {
  padding: 16/$font 120/$font 60/$font 120/$font;
  width: 1440/$font;
  height: auto;
  background: #F5F7F9;
}
.router-box {
  margin-bottom: 16/$font;
  width: 100%;
  height: auto;
  font-size: 14/$font;
  font-weight: 400;
  color: #666666;
  line-height: 20/$font;
  >span {
    color: $primary-color;
    font-size: 14/$font;
    font-weight: 400;
    line-height: 20/$font;
  }
}
.content-page {
  padding: 24/$font 24/$font 60/$font 24/$font;
  width: 1200/$font;
  height: auto;
  background: #FFFFFF;
  .title {
    margin-bottom: 12/$font;
    width: 100%;
    height: auto;
    font-size: 28/$font;
    font-weight: 500;
    color: #333333;
    line-height: 40/$font;
  }
  .date-time {
    margin-bottom: 32/$font;
    width: 100%;
    height: auto;
    font-size: 12/$font;
    font-weight: 400;
    color: #999999;
    line-height: 17/$font;
  }
  .content {
    width: 100%;
    height: auto;
    font-size: 16/$font;
    font-weight: 400;
    color: #333333;
    line-height: 26/$font;
  }
  .divider {
    margin: 60/$font 0 24/$font 0;
    width: 100%;
    height: 1/$font;
    background: #C4C4C4;
    opacity: 0.5;
  }
}
</style>
