<template>
  <div style="user-select: none;">
    <div class="seat"></div>
    <div class="header-nav">
      <img slot="reference" src="@/assets/image/logo.png" class="logo" />
      <div :class="{ 'nav-text': true }" @click="navigateTo('home')">首页</div>
      <div :class="{ 'nav-text': true, 'nav-indicate': true, 'nav-text-active': activeNav('article') }" @click="navigateTo('article')">读文章</div>
      <div :class="{ 'nav-text': true, 'nav-indicate': true, 'nav-text-active': activeNav('dynamics') }" @click="navigateTo('dynamics')">看动态</div>
      <div :class="{ 'nav-text': true, 'nav-indicate': true, 'nav-text-active': activeNav('report') }" @click="navigateTo('report')">找研报</div>
<!--      <div :class="{ 'nav-text': true, 'nav-indicate': true, 'nav-text-active': activeNav('course') }" @click="navigateTo('course')">学课程</div>-->
      <div :class="{ 'nav-text': true, 'nav-indicate': true, 'nav-text-active': activeNav('active') }" @click="meeting">活动会议</div>
<!--      <div class="nav-text">{{ $router }}</div>-->
      <div v-if="!token" class="wt-flex button-box">
        <div @click="navigateTo('login')">登录</div>
        <div @click="registerBtn">注册</div>
      </div>
      <el-popover
        placement="bottom-end"
        trigger="hover">
        <div class="popover-menu">
          <div @click="navigateTo('user')">个人中心</div>
          <div @click="logoutBtn">退出登录</div>
        </div>
        <img slot="reference" v-if="token" :src="userInfo.wechatPhotoUrl || defaultHeader" class="header"/>
      </el-popover>
    </div>
    <keep-alive>
      <router-view v-if="!notKeep"/>
    </keep-alive>
    <router-view v-if="notKeep"/>
    <div class="footer">
      <div class="footer-nav">
        <div @click="navigateTo('home')">首页</div>
        <div class="separate"></div>
        <div @click="navigateTo('article')">读文章</div>
        <div class="separate"></div>
        <div @click="navigateTo('dynamics')">看动态</div>
        <div class="separate"></div>
        <div @click="navigateTo('report')">找研报</div>
        <div class="separate"></div>
<!--        <div @click="navigateTo('course')">学课程</div>-->
<!--        <div class="separate"></div>-->
        <div @click="meeting">活动会议</div>
      </div>
      <div class="divider"></div>
      <div class="information">
        <img src="@/assets/image/logo.png" class="logo" />
        <div class="address">
          <span>地址：中国.北京.海淀区建材城中路27号金隅智造工场N5号楼</span>
          <span>联系电话：+86 10 82911506</span>
          <span>邮箱：ev100plus@chinaev100.org</span>
        </div>
        <div class="qr-code">
          <div>
            <img src="@/assets/image/wx_gzh.jpg" />官方公众号
          </div>
          <div class="">
            <img src="@/assets/image/wx_mp.jpg" />官方小程序
          </div>
        </div>
      </div>
      <div class="tip">Copyright ©2023中国电动汽车百人会 版权所有 <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备19027029号-2</a></div>
    </div>
  </div>
</template>

<script>
import { getToken, getStorgeItem } from '@/utils/auth'
import { mapState } from 'vuex'
export default {
  name: 'index',
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo || {}
    }),
    activeNav() {
      return (routeName) => {
        return this.routePath.indexOf(routeName) >= 0
      }
    },
    notKeep() {
      let result = false
      const list = [
        '/report/purchase',
        '/user/order/m'
      ]
      list.forEach(url => {
        if (this.routePath.indexOf(url) >= 0) result = true
      })
      return result
    }
  },
  data() {
    return {
      token: getToken(),
      routePath: '',
      defaultHeader: require('@/assets/image/login/default-header.png')
    }
  },
  watch: {
    $route(to) {
      this.routePath = to.path
    }
  },
  methods: {
    navigateTo(router) {
      this.$router.push(`/${router}`)
    },
    meeting() {
      window.open('https://www.chinaev100.com/meetting/list/1', 'block')
    },
    registerBtn() {
      this.$router.push('/register')
    },
    logoutBtn() {
      this.$confirm('是否退出?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
      }).then(() => {
        this.$store.dispatch('requestLogout').then(() => {
          this.$router.replace('/home')
          setTimeout(() => {
            window.location.reload()
          }, 200)
        })
      }).catch(() => {
      })
    }
  },
  mounted() {
    this.routePath = this.$route.path
    if (getToken()) this.$store.dispatch('setStoreUserInfo', getStorgeItem('userId'))
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
.seat {
  width: 1440/$font;
  height: 60/$font;
}
.header-nav {
  position: fixed;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  padding: 0 120/$font;
  width: 1440/$font;
  height: 60/$font;
  background-color: $primary-color;
  .logo {
    margin-right: 80/$font;
    width: 137/$font;
    height: 38/$font;
  }
  .nav-text {
    padding: 19/$font 24/$font;
    width: auto;
    height: auto;
    font-size: 16/$font;
    font-weight: 400;
    color: #FFFFFF;
    cursor: pointer;
  }
  .nav-indicate {
    position: relative;
    display: flex;
    justify-content: center;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 0;
      height: 3/$font;
      background: rgba(255, 173, 47, 0);
      transition: width 0.2s;
    }
  }
  .nav-text-active {
    &:after {
      content: '';
      width: 80%;
      background: rgba(255, 173, 47, 1);
    }
  }
  .button-box {
    margin-left: auto;
    :nth-child(1) {
      border: 0;
    }
    >div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80/$font;
      height: 34/$font;
      font-size: 16/$font;
      font-weight: 400;
      color: #FFFFFF;
      border-radius: 17/$font;
      border: 1/$font solid #FFFFFF;
      cursor: pointer;
    }
  }
  .header {
    position: absolute;
    top: 10/$font;
    right: 120/$font;
    width: 40/$font;
    height: 40/$font;
    border-radius: 50%;
    cursor: pointer;
  }
}
.popover-menu {
  >div {
    padding: 8/$font 0;
    width: 100%;
    height: auto;
    font-size: 16/$font;
    font-weight: 400;
    color: #333333;
    text-align: center;
    transition: color 0.2s;
    cursor: pointer;
    &:hover {
      color: $primary-color;
    }
  }
  :nth-child(2) {
    color: #F56C6C;
    &:hover {
      color: red;
    }
  }
}
.footer {
  position: relative;
  z-index: 999;
  width: 1440/$font;
  height: 287/$font;
  background: $primary-color;
  .footer-nav {
    display: flex;
    align-items: center;
    padding-left: 120/$font;
    width: 1440/$font;
    height: 52/$font;
    >div {
      width: auto;
      height: 20/$font;
      font-size: 14/$font;
      font-weight: 300;
      color: #FFFFFF;
      line-height: 20/$font;
      cursor: pointer;
    }
    .separate {
      margin: 0 24/$font 0 24/$font;
      width: 1/$font;
      height: 11/$font;
      opacity: 0.6;
      border: 1/$font solid #FFFFFF;
      cursor: default;
    }
  }
  .divider {
    width: 1440/$font;
    height: 1/$font;
    opacity: 0.3;
    border: 1/$font solid #FFFFFF;
  }
  .information {
    display: flex;
    align-items: flex-start;
    padding: 40/$font 120/$font 0 120/$font;
    width: 100%;
    height: auto;
    .address {
      width: 757/$font;
      >span {
        display: block;
        margin-bottom: 16/$font;
        font-size: 14/$font;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20/$font;
        user-select: text;
      }
    }
    .logo {
      margin-right: 60/$font;
      width: 137/$font;
      height: 38/$font;
    }
    .qr-code {
      display: flex;
      align-items: center;
      >div {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 40/$font;
        font-size: 12/$font;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 17/$font;
        >img {
          margin-bottom: 8/$font;
          width: 83/$font;
          height: 83/$font;
        }
      }
    }
  }
  .tip {
    position: absolute;
    bottom: 24/$font;
    width: 100%;
    height: 17/$font;
    font-size: 12/$font;
    font-weight: 300;
    color: #FFFFFF;
    line-height: 17/$font;
    text-align: center;
    opacity: 0.6;
    user-select: text;
    > a {
      font-size: 12/$font;
      font-weight: 300;
      color: #FFFFFF;
      text-decoration: none;
    }
  }
}
</style>
