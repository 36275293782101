<template>
  <div class="login-main-box">
    <div class="header">
      <img src="@/assets/image/logo.png"/>
    </div>
    <div class="bg-img">
      <img src="@/assets/image/login/login-bg.png"/>
    </div>
    <div class="login-form">
      <img src="@/assets/image/login/login-form-bg.png"/>
      <div class="tab">
        <div :class="{active: tabIndex === 0}" @click="tabIndex = 0">账号登录</div>
        <div :class="{active: tabIndex === 1}" @click="tabIndex = 1">微信登录</div>
      </div>
      <div v-if="tabIndex === 0" class="login-input">
        <div>
          <img src="@/assets/image/login/account-icon.png"/>
          <input v-model="loginForm.phone" placeholder="请输入您的账号"/>
        </div>
        <div>
          <img src="@/assets/image/login/password-icon.png"/>
          <input v-model="loginForm.password" type="password" placeholder="请输入密码"/>
        </div>
      </div>
      <el-button v-if="tabIndex === 0" type="primary" size="medium" class="login-button" @click="loginBtn">登录</el-button>
      <div v-if="tabIndex === 1" class="wx-qr-code">
        <div>使用微信扫一扫登录【车百智库】</div>
        <img v-if="qrCodeUrl" :src="qrCodeUrl"/>
      </div>
      <div class="text-btn" @click="registerBtn">免费注册 ></div>
    </div>
    <div class="bottom-tip">
      Copyright ©2023中国电动汽车百人会 版权所有 <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备19027029号-2</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index',
  data() {
    return {
      tabIndex: 0,
      loginForm: {
        phone: '',
        password: ''
      },
      qrCodeUrl: '',
      sceneId: '',
      timer: null,
      timerRefreshCode: null
    }
  },
  mounted() {
    this.initWxCode()
    this.refreshCode()
  },
  destroyed() {
    clearInterval(this.timer)
    clearInterval(this.timerRefreshCode)
  },
  methods: {
    refreshCode() { // 6分钟刷新一次二维码
      const that = this
      that.timerRefreshCode = setInterval(() => {
        that.initWxCode()
      }, 1000 * 60 * 6)
    },
    initWxCode() {
      this.$wtRequest({
        url: '/api/wechat/createQrCode',
        method: 'post',
        data: {}
      }).then((res) => {
        console.log('创建微信登录二维码', res)
        this.qrCodeUrl = res.data.qrCodeUrl
        this.sceneId = res.data.sceneId
        this.initTimer()
      }).catch((e) => {
      })
    },
    initTimer() {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.$wtRequest({
          url: `/api/wechat/checkLogin/${this.sceneId}`,
          method: 'post',
          data: {}
        }).then((res) => {
          console.log('检查授权登陆状态', res)
          // res = {
          //   'code': '0',
          //   'data': {
          //     'token': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiIyIzIxNTRhZjRkYTYzMDQ1YTFhMDMyZGI3YWIwODkwYjIxI3VzZXIjbnVsbCJ9.vnwtNWIXHc69B4w4LfiFo4CXUQuQdSIjlTKrfee_Tvo',
          //     'userId': 2,
          //     'userUuid': '2154af4da63045a1a032db7ab0890b21'
          //   },
          //   'msg': '',
          //   'success': true
          // }
          this.$store.dispatch('setStoreToken', res.data.token)
          this.$store.dispatch('setStoreUserInfo', res.data.userId)
          if (res.data) {
            clearInterval(this.timer)
            this.$router.replace('/home')
          }
        }).catch(() => {})
      }, 2000)
    },
    loginBtn() {
      if (!this.loginForm.phone) return this.$message.warning('请输入账号')
      if (!this.loginForm.password) return this.$message.warning('请输入密码')
      this.$wtRequest({
        url: `/api/user/pcLogin`,
        method: 'post',
        data: this.loginForm
      }).then((res) => {
        if (res.code === '0') {
          this.$store.dispatch('setStoreToken', res.data.token)
          this.$store.dispatch('setStoreUserInfo', res.data.id)
          this.$router.replace('/home')
        } else {
          this.$message.error(res.msg)
        }
      }).catch(() => {})
    },
    registerBtn() {
      this.$router.push('/register')
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
.login-main-box {
  position: relative;
  overflow: hidden;
  width: 1440/$font;
  height: 100vh;
  background: #0E0E0E;
}
.header {
  padding: 11/$font 120/$font;
  width: 1440/$font;
  height: 60/$font;
  >img {
    width: 135/$font;
    height: 38/$font;
  }
}
.bg-img {
  width: 1440/$font;
  height: 782/$font;
  >img {
    width: 100%;
    height: 100%;
  }
}
.login-form {
  position: absolute;
  top: 223/$font;
  right: 120/$font;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 456/$font;
  height: 454/$font;
  max-width: 456px;
  max-height: 454px;
  >img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
  }
  .tab {
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 44/$font 0 66/$font 0;
    width: 100%;
    font-size: 20/$font;
    font-weight: 400;
    color: #333333;
    line-height: 28/$font;
    >div {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: auto;
      cursor: pointer;
      &:after {
        position: relative;
        bottom: -12/$font;
        content: '';
        width: 0;
        height: 4/$font;
        background: rgba(255, 108, 0, 1);
        transition: width 0.1s;
      }
    }
    .active {
      font-weight: 500;
      color: $primary-color;
      &:after {
        content: '';
        width: 100%;
        transition: width 0.2s;
      }
    }
  }
  .login-input {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 360/$font;
    height: 128/$font;
    max-width: 360px;
    max-height: 128px;
    >div {
      display: flex;
      align-items: center;
      padding: 0 22/$font;
      width: 360/$font;
      height: 48/$font;
      max-width: 360px;
      max-height: 48px;
      background: #FFFFFF;
      border-radius: 2/$font;
      border: 1/$font solid #B5B5B5;
      >img {
        width: 20/$font;
        height: 20/$font;
        max-width: 20px;
        max-height: 20px;
      }
      >input {
        flex: 1;
        padding: 0 14/$font;
        font-size: 16/$font;
        height: 22/$font;
        outline: none;
        border: none;
      }
    }
  }
  .login-button {
    position: relative;
    z-index: 10;
    margin: 40/$font 0 32/$font 0;
    width: 360/$font;
    max-width: 360px;
    font-weight: 600;
    color: #FFFFFF;
    border-radius: 2/$font;
  }
  .text-btn {
    position: absolute;
    right: 48/$font;
    bottom: 48/$font;
    width: auto;
    height: auto;
    font-size: 14/$font;
    font-weight: 400;
    color: #2262B6;
    text-align: right;
    line-height: 20/$font;
    cursor: pointer;
  }
  .wx-qr-code {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    height: auto;
    font-size: 14/$font;
    font-weight: 400;
    color: #333333;
    line-height: 20/$font;
    >img {
      margin-top: 24/$font;
      width: 160/$font;
      height: 160/$font;
      max-width: 160px;
      max-height: 160px;
    }
  }
}
.bottom-tip {
  position: absolute;
  bottom: 24/$font;
  width: 100%;
  height: 17/$font;
  font-size: 12/$font;
  font-weight: 300;
  color: #FFFFFF;
  line-height: 17/$font;
  text-align: center;
  user-select: text;
  > a {
    font-size: 12/$font;
    font-weight: 300;
    color: #FFFFFF;
    text-decoration: none;
  }
}
@media screen and ( min-width: 1440px ) {
  .login-form {
    top: 223px;
    .tab {
      margin: 44px 0 66px 0;
      width: 100%;
      font-size: 20px;
      line-height: 28px;
      >div {
        &:after {
          bottom: -12px;
          height: 4px;
        }
      }
    }
    .login-input {
      >div {
        padding: 0 22px;
        border-radius: 2px;
        border: 1px solid #B5B5B5;
        >input {
          padding: 0 14px;
          font-size: 16px;
          height: 22px;
        }
      }
    }
    .login-button {
      margin: 40px 0 32px 0;
      border-radius: 2px;
    }
    .text-btn {
      right: 48px;
      bottom: 48px;
      width: auto;
      height: auto;
      font-size: 14px;
      line-height: 20px;
    }
    .wx-qr-code {
      font-size: 14px;
      line-height: 20px;
      >img {
        margin-top: 24px;
      }
    }
  }
}
</style>
