import axios from 'axios'
import { Message } from 'element-ui'

// 创建axios实例
const wtUploadRequest = axios.create({
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  baseURL: window.wtConst.uploadUrl, // api的base_url
  timeout: 300000 // 请求超时时间
})

// respone拦截器
wtUploadRequest.interceptors.response.use(
  response => {
    //  code为非0是抛错 可结合自己业务进行修改
    // const res = response.data
    if (response.data !== '') {
      this.$message({ type: 'error', message: '上传失败' })
      return Promise.reject(new Error('error'))
    } else {
      return response.data
    }
  },
  error => {
    console.log('err' + error)// for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default wtUploadRequest
