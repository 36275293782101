import request from '@/utils/request'
import Vue from 'vue'
const app = {
  state: {
    dictCacheMap: {},
    sidebar: {
      opened: true
    },
    dictionary: { // 系统字典
    },
    dictionaryCodeStatus: {} // 系统字典状态
  },
  mutations: {
    TOGGLE_SIDEBAR: state => {
      state.sidebar.opened = !state.sidebar.opened
    },
    UPDATE_DICT_DATA: (state, data) => {
      state.dictCacheMap[data.dictKey] = data.list
    },
    UPDATE_DICTIONARY: (state, data) => {
      for (let key in data) {
        Vue.set(state.dictionary, key, data[key])
      }
    },
    // 改变字典请求状态
    UPDATE_DICTIONARY_CODE_STATUS: (state, data) => {
      state.dictionaryCodeStatus[data.dataType] = data.flag
    }
  },
  actions: {
    /**
     * 字典查询
     * @param commit
     * @param array
     */
    getDictionaryList ({commit, state}, {dataType}) {
      const currentCodeStatus = state.dictionaryCodeStatus[dataType]
      if (currentCodeStatus === true) {
        return
      }
      commit('UPDATE_DICTIONARY_CODE_STATUS', {
        dataType, flag: true
      })
      return new Promise((resolve, reject) => {
        if (state.dictionary[dataType]) {
          return resolve()
        }
        request({
          url: 'modules/systemData/listByType',
          method: 'post',
          data: {
            dataType: [dataType]
          }
        }).then(res => {
          if (res.code === '0') {
            commit('UPDATE_DICTIONARY', res.data)
            commit('UPDATE_DICTIONARY_CODE_STATUS', {
              dataType, flag: false
            })
            resolve()
          }
        }).catch((...rest) => {
          commit('UPDATE_DICTIONARY_CODE_STATUS', {
            dataType, flag: false
          })
        })
      })
    },
    ToggleSideBar: ({ commit }) => {
      commit('TOGGLE_SIDEBAR')
    },
    queryDictList({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        if (state.dictCacheMap[data.dictKey] && state.dictCacheMap[data.dictKey].length > 0) {
          resolve(state.dictCacheMap[data.dictKey])
          return
        }
        request({
          url: '/modules/dict/ajaxList',
          method: 'post',
          data: { 'dictKey': data.dictKey }
        }).then(resp => {
          commit('UPDATE_DICT_DATA', {
            dictKey: data.dictKey,
            list: resp.data
          })
          resolve(resp.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default app
