import service from '@/utils/request'
const template = {
  state: {
    dragTemplate: {
      createAccountId: null,
      createTime: null,
      cycleType: null,
      diseaseId: null,
      diseaseLevel: null,
      diseaseName: null,
      diseaseTemplateInspectionResultList: [],
      evaluationFrequency: null,
      evaluationFrequencyType: null,
      executionCycle: null,
      followUpFrequency: null,
      frequencyType: null,
      id: null,
      isPublish: null,
      lifeGuidance: null,
      name: null,
      schemeBasis: null,
      targetContent: null,
      templateNo: null,
      updateTime: null
    },
    medicalDivideTemplate: {
      createAccountId: 0,
      createAccountName: '',
      createTime: '',
      id: 0,
      isPublish: 0,
      medicalDivideRuleResultList: [],
      platformAmount: '',
      platformNo: '',
      productType: 0,
      title: '',
      updateTime: ''
    }
  },
  mutations: {
    SET_DRAGTEMPLATE: (state, dragTemplateDetail) => {
      state.dragTemplate.createAccountId = dragTemplateDetail.createAccountId
      state.dragTemplate.createTime = dragTemplateDetail.createTime
      state.dragTemplate.cycleType = dragTemplateDetail.cycleType
      state.dragTemplate.diseaseId = dragTemplateDetail.diseaseId
      state.dragTemplate.diseaseLevel = dragTemplateDetail.diseaseLevel
      state.dragTemplate.diseaseName = dragTemplateDetail.diseaseName
      state.dragTemplate.diseaseTemplateInspectionResultList = dragTemplateDetail.diseaseTemplateInspectionResultList
      state.dragTemplate.evaluationFrequency = dragTemplateDetail.evaluationFrequency
      state.dragTemplate.evaluationFrequencyType = dragTemplateDetail.evaluationFrequencyType
      state.dragTemplate.executionCycle = dragTemplateDetail.executionCycle
      state.dragTemplate.followUpFrequency = dragTemplateDetail.followUpFrequency
      state.dragTemplate.frequencyType = dragTemplateDetail.frequencyType
      state.dragTemplate.id = dragTemplateDetail.id
      state.dragTemplate.isPublish = dragTemplateDetail.isPublish
      state.dragTemplate.lifeGuidance = dragTemplateDetail.lifeGuidance
      state.dragTemplate.name = dragTemplateDetail.name
      state.dragTemplate.schemeBasis = dragTemplateDetail.schemeBasis
      state.dragTemplate.targetContent = dragTemplateDetail.targetContent
      state.dragTemplate.templateNo = dragTemplateDetail.templateNo
      state.dragTemplate.updateTime = dragTemplateDetail.updateTime
      // console.log(dragTemplateDetail);
      // console.log('上边是mutions打印的数据');
    },
    SET_MEDICALDIVIDETEMPLATE: (state, medicalDivideTemplateDetail) => {
      state.medicalDivideTemplate.createAccountId = medicalDivideTemplateDetail.createAccountId
      state.medicalDivideTemplate.createAccountName = medicalDivideTemplateDetail.createAccountName
      state.medicalDivideTemplate.id = medicalDivideTemplateDetail.id
      state.medicalDivideTemplate.createTime = medicalDivideTemplateDetail.createTime
      state.medicalDivideTemplate.isPublish = medicalDivideTemplateDetail.isPublish
      state.medicalDivideTemplate.medicalDivideRuleResultList = medicalDivideTemplateDetail.medicalDivideRuleResultList
      state.medicalDivideTemplate.platformAmount = medicalDivideTemplateDetail.platformAmount
      state.medicalDivideTemplate.platformNo = medicalDivideTemplateDetail.platformNo
      state.medicalDivideTemplate.productType = medicalDivideTemplateDetail.productType
      state.medicalDivideTemplate.title = medicalDivideTemplateDetail.title
      state.medicalDivideTemplate.updateTime = medicalDivideTemplateDetail.updateTime
    },
    RESET_MEDICALDIVIDETEMPLATE: (state, reset) => {
      state.medicalDivideTemplate.createAccountId = 0
      state.medicalDivideTemplate.createAccountName = ''
      state.medicalDivideTemplate.createTime = ''
      state.medicalDivideTemplate.id = 0
      state.medicalDivideTemplate.isPublish = 0
      state.medicalDivideTemplate.medicalDivideRuleResultList = []
      state.medicalDivideTemplate.platformAmount = ''
      state.medicalDivideTemplate.platformNo = ''
      state.medicalDivideTemplate.productType = 0
      state.medicalDivideTemplate.title = ''
      state.medicalDivideTemplate.updateTime = ''
    }

  },
  actions: {
    setDragTemplate({ commit }, dragTemplateId) {
      service.post('modules/diseaseTemplate/detail', {
        id: dragTemplateId
      }).then(res => {
        commit('SET_DRAGTEMPLATE', res.data)
      })
    },
    setMedicalDivideTemplate({ commit }, medicalDivideTemplateId) {
      return new Promise((resolve, reject) => {
        service.post('modules/medicalDivideTemplate/detail', {
          id: medicalDivideTemplateId
        }).then(res => {
          commit('SET_MEDICALDIVIDETEMPLATE', res.data)
          resolve()
        })
      })
    },
    reSetMedicalDivideTemplate({ commit }) {
      commit('RESET_MEDICALDIVIDETEMPLATE', null)
    }
  }
}
export default template
