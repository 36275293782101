<template>
  <div class="box">
    <div class="header-box">
      <div class="header">
        <img :src="detailForm.wechatPhotoUrl || defaultHeader"/>
        <div>修改头像</div>
        <wt-oss-upload
          fileList.sync="[]"
          public
          file-path="user/"
          list-type="picture"
          :limit="1"
          @uploadSuccess="uploadSuccess"
          style="opacity: 0">
        </wt-oss-upload>
      </div>
      <div class="nick-name">{{ detailForm.name }}</div>
    </div>
    <div class="form-title">基本信息</div>
    <el-form :model="detailForm" :rules="rules" ref="verifyForm" label-width="70px" class="form-box">
      <el-form-item label="昵称:" prop="name">
        <el-input v-model="detailForm.name" size="small" placeholder="请输入昵称"></el-input>
      </el-form-item>
      <el-form-item label="电话:" prop="phone">
        <el-input v-model="detailForm.phone" size="small" placeholder="请输入电话"></el-input>
      </el-form-item>
      <el-form-item label="邮箱:" prop="email">
        <el-input v-model="detailForm.email" size="small" placeholder="请输入邮箱"></el-input>
      </el-form-item>
      <el-form-item label="公司:" prop="company">
        <el-input v-model="detailForm.companyName" size="small" placeholder="请输入公司"></el-input>
      </el-form-item>
      <el-form-item label="职务:" prop="duties">
        <el-input v-model="detailForm.job" size="small" placeholder="请输入职务"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="medium" @click="save('verifyForm')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'personalData',
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo || {}
    })
  },
  data() {
    return {
      detailForm: {},
      // updateForm: {
      //   companyName: '', // 公司
      //   email: '', // 邮箱
      //   id: '', // 主键
      //   job: '', // 职务
      //   memberEndTime: '', // 会员结束时间
      //   memberLevel: '', // 会员等级 1初级会员 2 高级会员 3 vip会员
      //   name: '', // 用户昵称
      //   phone: '', // 联系电话
      //   realName: '', // 真实姓名
      //   sex: '', // 性别（0:未知 1:男 2:女）
      //   wechatPhoto: '' // 账号头像
      // },
      rules: {
        phone: [{ required: true, message: '请输入电话', trigger: 'blur' }]
      },
      defaultHeader: require('@/assets/image/login/default-header.png')
    }
  },
  mounted() {
    console.log('个人资料装载')
  },
  activated() {
    console.log('个人资料活动')
    this.requestSelfInfo()
  },
  deactivated() {
    console.log('个人资料非活动')
  },
  destroyed() {
    console.log('个人资料卸载')
  },
  methods: {
    uploadSuccess(e) {
      this.detailForm.wechatPhoto = e[0].name
      this.detailForm.wechatPhotoUrl = e[0].url
    },
    updateSelfInfo() {
      this.$wtRequest({
        url: `/api/user/updateInfo`,
        method: 'post',
        data: this.detailForm
      }).then(() => {
        this.$store.dispatch('setStoreUserInfo', this.detailForm.id)
        this.requestSelfInfo()
      }).catch(() => {})
    },
    requestSelfInfo() {
      this.$wtRequest({
        url: `/api/user/selfInfo`,
        method: 'post'
      }).then((res) => {
        this.detailForm = res.data
      }).catch(() => {})
    },
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('是否确认保存？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.updateSelfInfo()
          }).catch(() => {})
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
.box {
  padding: 40/$font 24/$font 60/$font 24/$font;
}
.header-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 44/$font;
  width: 100%;
  height: auto;
  .header {
    position: relative;
    overflow: hidden;
    width: 120/$font;
    height: 120/$font;
    border-radius: 50%;
    >img {
      width: 120/$font;
      height: 120/$font;
    }
    >div {
      position: absolute;
      top: 0;
      left: 0;
      width: 120/$font;
      height: 120/$font;
      font-size: 14/$font;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 120/$font;
      text-align: center;
      background: rgba(0,0,0,0.4);
      cursor: pointer;
    }
  }
  .nick-name {
    margin-top: 16/$font;
    width: auto;
    height: auto;
    font-size: 18/$font;
    font-weight: 500;
    color: #333333;
    line-height: 25/$font;
  }
}
.form-title {
  width: auto;
  height: 22/$font;
  font-size: 16/$font;
  font-weight: 500;
  color: #333333;
  line-height: 22/$font;
}
.form-box {
  margin-top: 22/$font;
  width: 400/$font;
}
</style>
