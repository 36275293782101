function adapter() {
  const dip = document.documentElement.clientWidth
  // const rootFontSize = Math.min(Math.max(dip, 960), 1440) / 10
  const rootFontSize = Math.max(dip, 960) / 10
  document.documentElement.style.fontSize = rootFontSize + 'px'
}
adapter()
window.onresize = function() {
  adapter()
}
