<template>
  <div class="banner-box">
    <div id="bannerBox" class="banner-items"
         :style="{transform: `translateX(calc(${translateX}% + ${mouseMoveNumber}px))`, transition: mouseDownPosition ? 'all 0s ease-in-out' : 'all 0.5s ease-in-out'}"
         @mousemove="mouseMoveEvent"
         @mousedown="mouseDownEvent"
         @mouseup="mouseUpEvent"
         @mouseleave="mouseLeaveEvent">
      <div v-for="(item, index) in list" :key="index" @mousedown="bannerItemMouseDown" @click="clickBanner($event, item)">
        <img :src="item.imgUrl"/>
      </div>
    </div>
    <div class="banner-indicate">
      <div
        v-for="(item, index) in list"
        @click="indicateClickEvent(index)"
        :key="index"
        :class="{'indicate': true, 'indicate-active': index === active}">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'wt-home-banner',
  props: {
    list: { type: Array, default: () => { return [] } }
  },
  data() {
    return {
      active: 0,
      translateX: 0,
      mouseMoveNumber: 0,
      timer: null,
      mouseDownPosition: null,
      itemMouseDate: {}
    }
  },
  watch: {
    // active(newVal) {
    //   this.translateX = -newVal * 100
    // }
  },
  mounted() {
    this.initTimer()
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    bannerItemMouseDown(e) {
      this.itemMouseDate = e
    },
    clickBanner(e, item) {
      const itemMouseDate = this.itemMouseDate
      if (e.timeStamp - itemMouseDate.timeStamp < 1000 && Math.abs(e.clientX - itemMouseDate.clientX) < 40 && Math.abs(e.clientY - itemMouseDate.clientY) < 40) {
        this.$emit('click', item)
      }
    },
    mouseMoveEvent(e) {
      if (this.mouseDownPosition) {
        const move = e.x - this.mouseDownPosition.x
        if (this.active === 0 && move > 0) return
        if (this.active === this.list.length - 1 && move < 0) return
        this.mouseMoveNumber = e.x - this.mouseDownPosition.x
      }
    },
    mouseDownEvent(e) {
      clearInterval(this.timer)
      this.mouseDownPosition = e
    },
    mouseUpEvent(e) {
      if (this.mouseDownPosition.x - e.x > 100) {
        this.reset()
        if (this.active === this.list.length - 1) return
        this.indicateClickEvent(this.active + 1)
      } else if (this.mouseDownPosition.x - e.x < -100) {
        this.reset()
        if (this.active === 0) return
        this.indicateClickEvent(this.active - 1)
      } else {
        this.reset()
      }
      this.mouseDownPosition = null
      this.mouseMoveNumber = 0
      this.indicateClickEvent(this.active)
    },
    mouseLeaveEvent() {
      this.reset()
    },
    reset() {
      this.mouseDownPosition = null
      this.mouseMoveNumber = 0
      this.indicateClickEvent(this.active)
    },
    indicateClickEvent(index) {
      clearInterval(this.timer)
      this.active = index
      this.translateX = -this.active * 100
      this.initTimer()
    },
    initTimer() {
      this.timer = setInterval(() => {
        if (this.active === this.list.length - 1) {
          this.active = 0
        } else {
          this.active += 1
        }
        this.translateX = -this.active * 100
      }, 5000)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
.banner-box {
  position: relative;
  overflow: hidden;
  width: 1440/$font;
  height: 500/$font;
  background-color: #9E9E9E;
  .banner-items {
    display: flex;
    align-items: center;
    transition: all 0.5s ease-in-out;
    >div {
      width: 1440/$font;
      height: 500/$font;
      >img {
        width: 1440/$font;
        height: 500/$font;
        -webkit-user-drag: none;
      }
    }
  }
  .banner-indicate {
    position: absolute;
    bottom: 24/$font;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 100;
    .indicate {
      margin-right: 8/$font;
      width: 24/$font;
      height: 4/$font;
      background: #FFFFFF;
      border-radius: 2/$font;
      opacity: 0.4;
      cursor: pointer;
      transition: all 0.15s;
    }
    .indicate-active {
      width: 36/$font;
      background: #FF6C00;
      opacity: 1;
    }
    :last-child {
      margin-right: 0;
    }
  }
}
</style>
