<template>
  <div class="main-box">
    <div class="header">
      <img src="@/assets/image/logo.png"/>
      <div class="report-title">
        {{ detailForm.name }}
        <span>{{detailForm.reportTime}}&emsp;&emsp;{{detailForm.labelName}}&emsp;&emsp;{{detailForm.pageNumber}}页</span>
      </div>
      <el-button v-if="!isCanRead" @click="navigateToPurchase" size="medium" style="margin-left: auto;">购买</el-button>
    </div>
    <div class="block1"></div>
<!--    <iframe v-if="url" :src="url" style="width: 100%; height: 90%;"></iframe>-->
    <div v-if="detailForm" class="pdf-box">
      <div v-for="page in pageCount" :key="page">
        <pdf-viewer :src="pdfUrl" :page="page" />
        <div v-if="!isCanRead && page === pageCount" class="end-tip">
          <div>试看结束，请购买后观看</div>
          <div @click="navigateToPurchase">购买</div>
        </div>
      </div>
    </div>
    <div class="block2"></div>
    <div class="footer">
      <div class="footer-nav">
        <div @click="navigateTo('home')">首页</div>
        <div class="separate"></div>
        <div @click="navigateTo('article')">读文章</div>
        <div class="separate"></div>
        <div @click="navigateTo('dynamics')">看动态</div>
        <div class="separate"></div>
        <div @click="navigateTo('report')">找研报</div>
        <div class="separate"></div>
<!--        <div @click="navigateTo('course')">学课程</div>-->
<!--        <div class="separate"></div>-->
        <div @click="meeting">活动会议</div>
      </div>
      <div class="divider"></div>
      <div class="information">
        <img src="@/assets/image/logo.png" class="logo" />
        <div class="address">
          <span>地址：中国.北京.海淀区建材城中路27号金隅智造工场N5号楼</span>
          <span>联系电话：+86 10 82911506</span>
          <span>邮箱：ev100plus@chinaev100.org</span>
        </div>
        <div class="qr-code">
          <div>
            <img src="@/assets/image/wx_gzh.jpg" />官方公众号
          </div>
          <div class="">
            <img src="@/assets/image/wx_mp.jpg" />官方小程序
          </div>
        </div>
      </div>
      <div class="tip">Copyright ©2023中国电动汽车百人会 版权所有 <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备19027029号-2</a></div>
    </div>
  </div>
</template>

<script>
import pdfViewer from 'vue-pdf'
import { mapState } from 'vuex'
export default {
  name: 'index',
  components: {
    pdfViewer
  },
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo || {}
    }),
    isCanRead() {
      const isReadAuth = this.userAuth.isReadAuth
      const { pageNumber, freeNumber } = this.detailForm
      return isReadAuth === 1 || freeNumber >= pageNumber || Number(this.detailForm.price || 0) === 0
    },
    isCanDownload() {
      const isDownloadAuth = this.userAuth.isDownloadAuth
      const { pageNumber, freeNumber } = this.detailForm
      return isDownloadAuth === 1 || freeNumber >= pageNumber || Number(this.detailForm.price || 0) === 0
    }
  },
  data() {
    return {
      id: '',
      detailForm: {},
      userAuth: { isDownloadAuth: 0, isReadAuth: 0 },
      url: '',
      isTry: '',
      pdfUrl: '',
      pageCount: '',
      timer: null
    }
  },
  mounted() {
    console.log('得到的参数', this.$route.query.url)
    this.isTry = this.$route.query.t
    this.pdfUrl = this.$route.query.url
    this.id = this.$route.query.id
    this.url = 'https://oss.evplus.ev100plus.com/pdfjs-3.4.120-legacy-dist/web/viewer.html?file=' + this.$route.query.url
    this.requestDetail()
    this.getAuthority()
    // document.onkeydown = () => {
    //   //禁用F12
    //   if (window.event && window.event.keyCode == 123) {
    //     return false;
    //     //禁用ctrl+shift+i,
    //   } else if (window.event.ctrlKey && window.event.shiftKey && window.event.keyCode == 73) {
    //     return false;
    //     //屏蔽Shift+F10
    //   } else if (window.event.shiftKey && window.event.keyCode == 121) {
    //     return false;
    //   }
    // };
    // var threshold = 100;
    // this.timer1 = setInterval(function () {
    //   //当浏览器窗口宽度或高度与页面的宽度和高度的差值大于100的时候触发页面刷新
    //   if (window.outerWidth - window.innerWidth > threshold || window.outerHeight - window.innerHeight > threshold) {
    //     // 刷新页面；
    //     window.location.reload()
    //     alert('请关闭控制台重新输入地址进入网站');
    //   }
    // })
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    navigateToPurchase() {
      this.$wtRequest({
        url: `/api/order/waitPayOrder`,
        method: 'post',
        data: { businessId: this.id, businessType: 1 }
      }).then((res) => {
        console.log(res)
        if (!res.data) {
          if (this.detailForm.isDynamic === 1) {
            const routeUrl = this.$router.resolve(`/dynamics/purchase/${this.id}`)
            window.open(routeUrl.href, '_blank')
          } else {
            const routeUrl = this.$router.resolve(`/report/purchase/${this.id}`)
            window.open(routeUrl.href, '_blank')
          }
        } else {
          this.$confirm(`您有该研报待支付的订单`, '提示', {
            confirmButtonText: '去支付',
            cancelButtonText: '取消订单',
            type: 'info'
          }).then(() => {
            const routeUrl = this.$router.resolve(`/user/order/r/${res.data.id}`)
            window.open(routeUrl.href, '_blank')
          }).catch(() => {
            const routeUrl = this.$router.resolve(`/user/order/r/${res.data.id}`)
            window.open(routeUrl.href, '_blank')
          })
        }
      }).catch(() => {})
    },
    meeting() {
      window.open('https://www.chinaev100.com/meetting/list/1', 'block')
    },
    navigateTo(router) {
      this.$router.push(`/${router}`)
    },
    getAuthority() {
      // 用户权限
      this.$wtRequest({
        url: `/api/auth/authority`,
        method: 'post',
        data: { businessId: this.id, businessType: 1 }
      }).then((res) => {
        this.userAuth = res.data
      }).catch(() => {})
    },
    requestDetail() {
      this.$wtRequest({
        url: `/api/report/detail`,
        method: 'post',
        data: { id: this.id }
      }).then((res) => {
        this.detailForm = res.data
        let pageCount = 0
        if (this.isTry === '1') {
          pageCount = res.data.freeNumber
        } else {
          pageCount = res.data.pageNumber
        }
        this.pageCount = 2
        this.timer = setInterval(() => {
          this.pageCount = Math.min(this.pageCount + 2, pageCount)
          if (this.pageCount === pageCount) clearInterval(this.timer)
        }, 2000)
      }).catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
.main-box {
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  background-color: rgba(240, 240, 240, 1);
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1099;
  display: flex;
  align-items: center;
  padding: 11/$font 120/$font;
  width: 1440/$font;
  height: 60/$font;
  background: $primary-color;
  >img {
    width: 135/$font;
    height: 38/$font;
  }
  .report-title {
    margin-left: 80/$font;
    font-size: 14/$font;
    font-weight: 400;
    color: #FFFFFF;
    > span {
      display: block;
      font-size: 12/$font;
      margin-top: 4/$font;
    }
  }
}
.block1 {
  height: 60/$font;
}
.pdf-box {
  margin: 0 auto;
  width: 1200/$font;
  height: auto;
  > div {
    position: relative;
    margin-top: 24/$font;
    padding: 24/$font;
    background-color: #FFFFFF;
    .end-tip {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 243/$font;
      background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #FFFFFF 50%);
      font-size: 16/$font;
      font-weight: 400;
      color: #333333;
      line-height: 22/$font;
      :nth-child(2) {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 24/$font;
        width: 240/$font;
        height: 54/$font;
        font-size: 18/$font;
        font-weight: 500;
        color: #FFFFFF;
        background: linear-gradient(270deg, #FFA500 0%, #FF6C00 100%);
        border-radius: 27/$font;
      }
    }
  }
}
.block2 {
  height: 60/$font;
}
.footer {
  position: relative;
  z-index: 999;
  width: 1440/$font;
  height: 287/$font;
  background: $primary-color;
  .footer-nav {
    display: flex;
    align-items: center;
    padding-left: 120/$font;
    width: 1440/$font;
    height: 52/$font;
    >div {
      width: auto;
      height: 20/$font;
      font-size: 14/$font;
      font-weight: 300;
      color: #FFFFFF;
      line-height: 20/$font;
      cursor: pointer;
    }
    .separate {
      margin: 0 24/$font 0 24/$font;
      width: 1/$font;
      height: 11/$font;
      opacity: 0.6;
      border: 1/$font solid #FFFFFF;
      cursor: default;
    }
  }
  .divider {
    width: 1440/$font;
    height: 1/$font;
    opacity: 0.3;
    border: 1/$font solid #FFFFFF;
  }
  .information {
    display: flex;
    align-items: flex-start;
    padding: 40/$font 120/$font 0 120/$font;
    width: 100%;
    height: auto;
    .address {
      width: 757/$font;
      >span {
        display: block;
        margin-bottom: 16/$font;
        font-size: 14/$font;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20/$font;
        user-select: text;
      }
    }
    .logo {
      margin-right: 60/$font;
      width: 137/$font;
      height: 38/$font;
    }
    .qr-code {
      display: flex;
      align-items: center;
      >div {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 40/$font;
        font-size: 12/$font;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 17/$font;
        >img {
          margin-bottom: 8/$font;
          width: 83/$font;
          height: 83/$font;
        }
      }
    }
  }
  .tip {
    position: absolute;
    bottom: 24/$font;
    width: 100%;
    height: 17/$font;
    font-size: 12/$font;
    font-weight: 300;
    color: #FFFFFF;
    line-height: 17/$font;
    text-align: center;
    opacity: 0.6;
    user-select: text;
    > a {
      font-size: 12/$font;
      font-weight: 300;
      color: #FFFFFF;
      text-decoration: none;
    }
  }
}
</style>
