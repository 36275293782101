<template>
  <div class="box">
    <img v-if="item.imgUrl" :src="item.imgUrl"/>
    <img v-else/>
    <div class="right">
      <div class="title">{{ item.title }}</div>
      <div class="content">{{item.summary}}</div>
      <div class="information">
        <wt-label-item :text="item.labelName" bg-color="rgba(196, 196, 196, 0.2)" color="#999999"/>
        <div>{{ item.createTime }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'wt-article-item',
  props: {
    item: { type: Object, default: () => { return {} } }
  },
  data() {
    return {}
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
.box {
  display: flex;
  align-items: center;
  width: 100%;
  height: 144/$font;
  >img {
    margin-right: 24/$font;
    width: 216/$font;
    height: 144/$font;
  }
}
.right {
  flex: 1;
  height: 144/$font;
  .title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    width: auto;
    height: 50/$font;
    font-size: 18/$font;
    font-weight: 500;
    color: #333333;
    line-height: 25/$font;
  }
  .content {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    margin-top: 8/$font;
    width: auto;
    height: 60/$font;
    font-size: 14/$font;
    font-weight: 400;
    color: #999999;
    line-height: 20/$font;
  }
  .information {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5/$font;
    width: auto;
    height: auto;
    :nth-child(2) {
      font-size: 12/$font;
      font-weight: 400;
      color: #999999;
    }
  }
}
</style>
