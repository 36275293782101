<template>
  <div class="main-box">
    <div class="router-box">{{ detailForm.isDynamic === 1 ? '动态' : '研报' }} > <span>{{ detailForm.isDynamic === 1 ? '动态' : '研报' }}详情</span></div>
    <div class="content">
      <div class="content-page">
        <wt-report-item3 :item="detailForm"/>
        <div class="title-divider">研报简介</div>
        <div class="content">{{ detailForm.summary }}</div>
      </div>
      <div class="right">
        <div class="title">
          <img src="@/assets/image/report/report-detail-icon4.png"/>
          <div>{{ isCanRead ? '查看' : '试看' }}{{ isCanDownload ? '下载' : '购买' }}</div>
        </div>
        <div class="card">
          <div v-if="isCanRead" @click="viewerPdf">
            <div>
              <img src="@/assets/image/report/report-detail-icon3.png"/>查看
            </div>
            <div>对相应的报告进行查看</div>
          </div>
          <div v-else @click="viewerPdf('try')">
            <div>
              <img src="@/assets/image/report/report-detail-icon3.png"/>试看
            </div>
            <div>对相应的报告进行试看</div>
          </div>
          <div v-if="isCanDownload" @click="downloadPdf">
            <div>
              <img src="@/assets/image/report/report-detail-icon1.png"/>下载
            </div>
            <div>下载并查看相应的报告</div>
          </div>
          <div v-else @click="navigateToPurchase">
            <div>
              <img src="@/assets/image/report/report-detail-icon1.png"/>购买
            </div>
            <div>购买之后可查看完整报告</div>
          </div>
        </div>
      </div>
      <el-dialog
        :visible.sync="dialogVisible"
        width="40%">
        <div>
          邮箱：<el-input v-model="email" size="medium" style="width: 90%;"></el-input>
        </div>
        <span slot="footer">
          <el-button size="small" type="primary" @click="confirmDownloadPdf('input')">下载</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'detail',
  data() {
    return {
      id: '',
      detailForm: {},
      userAuth: {
        isDownloadAuth: 0,
        isReadAuth: 0
      },
      dialogVisible: false,
      email: ''
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo || {}
    }),
    isCanRead() {
      const isReadAuth = this.userAuth.isReadAuth
      const { pageNumber, freeNumber } = this.detailForm
      return isReadAuth === 1 || freeNumber >= pageNumber || Number(this.detailForm.price || 0) === 0
    },
    isCanDownload() {
      const isDownloadAuth = this.userAuth.isDownloadAuth
      const { pageNumber, freeNumber } = this.detailForm
      return isDownloadAuth === 1 || freeNumber >= pageNumber || Number(this.detailForm.price || 0) === 0
    }
  },
  activated() {
    console.log('得到的参数', this.$route.params.id)
    this.id = this.$route.params.id
    this.refresh()
  },
  methods: {
    refresh() {
      this.requestDetail()
      this.getAuthority()
    },
    getAuthority() {
      // 用户权限
      this.$wtRequest({
        url: `/api/auth/authority`,
        method: 'post',
        data: { businessId: this.id, businessType: 1 }
      }).then((res) => {
        this.userAuth = res.data
      }).catch(() => {})
    },
    requestDetail() {
      this.$wtRequest({
        url: `/api/report/detail`,
        method: 'post',
        data: { id: this.id }
      }).then((res) => {
        this.detailForm = res.data
      }).catch(() => {})
    },
    viewerPdf(isTry) {
      if (isTry === 'try') {
        const routeUrl = this.$router.resolve({ path: `/preview`, query: { url: this.detailForm.tryFileUrl, t: 1, id: this.id }})
        window.open(routeUrl.href, '_blank')
      } else {
        this.$wtRequest({
          url: `/api/report/pdfRead`,
          method: 'post',
          data: { id: this.id }
        }).then((res) => {
          if (res.code !== '0') {
            return this.$message.error(res.msg)
          }
          this.refresh()
          // const routeUrl = this.$router.resolve({ path: `/preview`, query: { url: encodeURIComponent(res.data), id: this.id }})
          const routeUrl = this.$router.resolve({ path: `/preview`, query: { url: res.data, id: this.id }})
          window.open(routeUrl.href, '_blank')
        }).catch(() => {})
      }
    },
    downloadPdf() {
      if (this.userInfo && this.userInfo.email) {
        this.confirmDownloadPdf(this.userInfo.email)
      } else {
        this.dialogVisible = true
      }
    },
    confirmDownloadPdf(email) {
      if (email === 'input') email = this.email
      if (email.length > 50) return this.$message.warning('邮箱字符长度应小于50')
      if (!this.$wtValidator.checkEmail(email)) return this.$message.warning('邮箱格式输入有误')
      this.dialogVisible = false
      this.$wtRequest({
        url: `/api/report/download`,
        method: 'post',
        data: { id: this.id, email }
      }).then((res) => {
        this.refresh()
        this.$confirm(`已发送至您的邮箱 ${email}`, '提示', {
          confirmButtonText: '我知道了',
          type: 'info'
        }).then(() => {}).catch(() => {})
      }).catch(() => {})
    },
    navigateToPurchase() {
      this.$wtRequest({
        url: `/api/order/waitPayOrder`,
        method: 'post',
        data: { businessId: this.id, businessType: 1 }
      }).then((res) => {
        console.log(res)
        if (!res.data) {
          if (this.detailForm.isDynamic === 1) {
            this.$router.push(`/dynamics/purchase/${this.id}`)
          } else {
            this.$router.push(`/report/purchase/${this.id}`)
          }
        } else {
          this.$confirm(`您有该研报待支付的订单`, '提示', {
            confirmButtonText: '去支付',
            cancelButtonText: '取消订单',
            type: 'info'
          }).then(() => {
            this.$router.push(`/user/order/r/${res.data.id}`)
          }).catch(() => {
            this.$router.push(`/user/order/r/${res.data.id}`)
          })
        }
      }).catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
.main-box {
  padding: 16/$font 120/$font 60/$font 120/$font;
  width: 1440/$font;
  height: auto;
  background: #F5F7F9;
}
.router-box {
  margin-bottom: 16/$font;
  width: 100%;
  height: auto;
  font-size: 14/$font;
  font-weight: 400;
  color: #666666;
  line-height: 20/$font;
  >span {
    color: $primary-color;
    font-size: 14/$font;
    font-weight: 400;
    line-height: 20/$font;
  }
}
.content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .content-page {
    padding: 24/$font 24/$font 40/$font 24/$font;
    width: 950/$font;
    height: auto;
    background: #FFFFFF;
    .title-divider {
      position: relative;
      margin: 40/$font 0 32/$font 0;
      padding-bottom: 20/$font;
      width: auto;
      height: 50/$font;
      font-size: 22/$font;
      font-weight: 500;
      color: #333333;
      line-height: 30/$font;
      border-bottom: 1/$font solid rgba(196, 196, 196, 0.5);
      &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 88/$font;
        height: 4/$font;
        background: #FF6C00;
      }
    }
    .content {
      width: 100%;
      height: auto;
      font-size: 16/$font;
      font-weight: 400;
      color: #333333;
      line-height: 26/$font;
    }
  }
  .right {
    .title {
      display: flex;
      align-items: center;
      margin-bottom: 16/$font;
      > img {
        margin-right: 12/$font;
        width: 30/$font;
      }
      > div {
        width: auto;
        height: 28/$font;
        font-size: 20/$font;
        font-weight: 500;
        color: #333333;
        line-height: 28/$font;
      }
    }
    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 24/$font;
      width: 218/$font;
      height: 236/$font;
      background: #FFFFFF;
      >div {
        padding: 16/$font 22/$font;
        width: 100%;
        background: #FFFFFF;
        border-radius: 3/$font;
        border: 1/$font solid #F0F1F2;
        cursor: pointer;
        >div {
          display: flex;
          align-items: center;
          font-size: 20/$font;
          font-weight: 500;
          color: #333333;
          line-height: 28/$font;
          >img {
            margin-right: 12/$font;
            width: 18/$font;
          }
        }
        :nth-child(2) {
          font-size: 12/$font;
          font-weight: 400;
          color: #999999;
          line-height: 17/$font;
        }
      }
    }
  }
}
</style>
