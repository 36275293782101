<template>
  <div class="wt-coupon-card-box" :style="`background-image: url(${isOverDate ? imgBgDis : imgBg})`">
    <div class="line1">{{ item.type === 1 ? item.favourablePriceDollar : item.discount }}<span>{{ item.type === 1 ? '元' : '折' }}</span></div>
    <div class="line2">有效期至{{ item.endDate }}</div>
    <div class="line3">
      <span>[{{ useClass[item.useClass] }}]</span>
      <span>[{{ type[item.type] }}]</span>
      <span>{{tip}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'wt-coupon-card',
  props: {
    item: { type: Object, default: () => { return {} } }
  },
  computed: {
    isOverDate() {
      return new Date() > new Date(this.item.endDate + ' 23:59:59')
    },
    tip() {
      if (this.item.type === 1) {
        return `每满${this.item.fullSubtractPriceDollar}减${this.item.favourablePriceDollar}元`
      } else {
        return `可享${this.item.discount}折`
      }
    }
  },
  data() {
    return {
      imgBg: require('@/assets/image/user/coupon-bg.png'),
      imgBgDis: require('@/assets/image/user/coupon-bg-disable.png'),
      type: {
        1: '满减券',
        2: '折扣券'
      },
      useClass: {
        1: '课程购买',
        2: '报告购买',
        3: '会员充值'
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
.wt-coupon-card-box {
  position: relative;
  width: 428/$font;
  height: 110/$font;
  background-size: 100% 100%;
  cursor: default;
}
.line1 {
  position: absolute;
  top: 9/$font;
  left: 14/$font;
  font-size: 26/$font;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 31/$font;
  >span {
    margin-left: 4/$font;
    font-size: 14/$font;
    font-weight: 400;
    line-height: 20/$font;
  }
}
.line2 {
  position: absolute;
  top: 46/$font;
  left: 14/$font;
  font-size: 12/$font;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 17/$font;
}
.line3 {
  position: absolute;
  top: 80/$font;
  left: 14/$font;
  font-size: 14/$font;
  font-weight: 400;
  color: #333333;
  line-height: 20/$font;
  :nth-child(2) {
    margin-left: 8/$font;
  }
  :nth-child(3) {
    margin-left: 16/$font;
    color: #666666;
  }
}
</style>
