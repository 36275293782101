<template>
  <div>
    <div class="box1">
      <div>
        <div class="card" :style="`background-image: url(${bgImg1})`">
          <div>高级会员</div>
          <div>大部分人员都在用</div>
        </div>
        <div class="member-card-select">
          <div style="width: 100%; display: flex; align-items: center; justify-content: space-evenly;">
            <member-card-type @click="active1 = 1" card-title="月卡" :price="cardPrice(2, 1)" :active="active1 === 1"/>
            <member-card-type @click="active1 = 2" card-title="季卡" :price="cardPrice(2, 2)" :active="active1 === 2"/>
            <member-card-type @click="active1 = 3" card-title="年卡" :price="cardPrice(2, 3)" :active="active1 === 3"/>
          </div>
          <div v-if="userInfo.memberLevel !== 3" class="button" @click="buyMember(2)" :style="`background-image: url(${bgImg3})`">立即开通</div>
          <div v-else class="button" style="width: 0;"></div>
        </div>
      </div>
      <div>
        <div class="card" :style="`background-image: url(${bgImg2})`">
          <div style="color: rgba(255, 206, 158, 1);">尊享会员</div>
          <div style="color: rgba(255, 206, 158, 1);">尊享更多优惠权益</div>
        </div>
        <div class="member-card-select">
          <div style="width: 100%; display: flex; align-items: center; justify-content: space-evenly;">
            <member-card-type @click="active2 = 1" card-title="月卡" :price="cardPrice(3, 1)" :active="active2 === 1"/>
            <member-card-type @click="active2 = 2" card-title="季卡" :price="cardPrice(3, 2)" :active="active2 === 2"/>
            <member-card-type @click="active2 = 3" card-title="年卡" :price="cardPrice(3, 3)" :active="active2 === 3"/>
          </div>
          <div v-if="userInfo.memberLevel !== 3" class="button" @click="buyMember(3)" :style="`background-image: url(${bgImg4}); color: #FFFFFF;`">立即开通</div>
          <div v-else class="button" style="width: 0;"></div>
        </div>
      </div>
    </div>
    <div class="divide"></div>
    <div class="title">会员权益</div>
    <div class="divider"></div>
    <div class="box2">
      <member-equity-table :table-data="tableData"/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import memberCardType from '../compon/memberCardType'
import memberEquityTable from '../compon/memberEquityTable'
export default {
  name: 'memberEquity',
  components: { memberCardType, memberEquityTable },
  computed: {
    cardPrice() {
      return (level, scope) => {
        return (this.priceData.find(item => item.level === level && item.scope === scope) || { priceDollar: 0 }).priceDollar
      }
    },
    ...mapState({
      userInfo: state => state.auth.userInfo || {}
    })
  },
  data() {
    return {
      bgImg1: require('@/assets/image/user/member-up-bg1.png'),
      bgImg2: require('@/assets/image/user/member-up-bg2.png'),
      bgImg3: require('@/assets/image/user/member-up-bg3.png'),
      bgImg4: require('@/assets/image/user/member-up-bg4.png'),
      active1: 1,
      active2: 1,
      form: {
        formData: {},
        pageData: {
          currentPage: 1,
          pageSize: 20,
          sortName: 'updateTime',
          sortOrder: 'desc'
        }
      },
      priceData: [],
      tableData: []
    }
  },
  mounted() {
    this.requestMemberLevel()
    this.requestMemberFunction()
  },
  methods: {
    /**
     * @param type 2:高级会员 3:尊享会员
     */
    buyMember(type) {
      const id = (this.priceData.find(item => item.level === type && item.scope === (type === 2 ? this.active1 : this.active2))).id
      this.$router.push(`user/order/m/${id}`)
    },
    requestMemberLevel() {
      this.$wtRequest({
        url: `/api/memberLevel/list`,
        method: 'post',
        data: this.form
      }).then((res) => {
        this.priceData = res.data.list
      }).catch(() => {})
    },
    requestMemberFunction() {
      this.$wtRequest({
        url: `/api/memberFunction/list`,
        method: 'post',
        data: this.form
      }).then((res) => {
        this.tableData = res.data.list
      }).catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
.box1 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 40/$font 24/$font 40/$font 24/$font;
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 430/$font;
    height: 92/$font;
    background-size: 100% 100%;
    :nth-child(1) {
      width: auto;
      height: auto;
      font-size: 22/$font;
      font-weight: 500;
      color: rgba(159, 83, 0, 1);
      line-height: 30/$font;
    }
    :nth-child(2) {
      margin-top: 6/$font;
      width: auto;
      height: auto;
      font-size: 14/$font;
      font-weight: 400;
      color: rgba(159, 83, 0, 1);
      line-height: 20/$font;
    }
  }
  .member-card-select {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 268/$font;
    background: #FFFFFF;
    border-radius: 0 0 10/$font 10/$font;
    border: 1/$font solid #F0F1F2;
    border-top: 0;
    .button {
      width: 160/$font;
      height: 48/$font;
      font-size: 16/$font;
      font-weight: 500;
      color: #9F5300;
      text-align: center;
      line-height: 48/$font;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
}
.divide {
  width: 100%;
  height: 24/$font;
  background: #F5F7F9;
}
.title {
  padding: 17/$font 24/$font;
  width: auto;
  height: auto;
  font-size: 20/$font;
  font-weight: 500;
  color: #333333;
  line-height: 28/$font;
}
.divider {
  width: 100%;
  height: 1/$font;
  opacity: 0.5;
  border: 1/$font solid #C4C4C4;
}
.box2 {
  width: 100%;
  padding: 32/$font 24/$font 60/$font 24/$font;
}
</style>
