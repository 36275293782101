<template>
  <div class="box">
    <img v-if="item.imgUrl" :src="item.imgUrl">
    <img v-else>
    <div>
      <div class="title">{{ item.name }}</div>
      <div class="content">机构：{{item.organizationName}}</div>
      <div class="content">主题：{{item.themeName}}</div>
      <div class="content">作者：{{item.author}}</div>
      <div class="content">页数：{{item.pageNumber}}</div>
      <div class="content">研报时间：{{ dateFormat(item.reportTime || '') }}</div>
      <div class="label">{{ item.labelName }}</div>
      <div v-if="item.priceDollar !== '0'" class="price"><span>￥</span>{{ item.priceDollar }}</div>
      <div v-else class="price">免费</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'wt-report-item3',
  props: {
    item: { type: Object, default: () => { return {} } }
  },
  computed: {
    dateFormat() {
      return (date) => {
        if (date === '') return
        return `${date.substr(0, 4)}年${date.substr(5, 2)}月${date.substr(8, 2)}日`
      }
    }
  },
  data() {
    return {}
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
.box {
  display: flex;
  padding: 24/$font;
  width: 100%;
  height: 323/$font;
  background: #F5F7F9;
  >img {
    margin-right: 16/$font;
    width: 192/$font;
    height: 275/$font;
    border: 1/$font solid #2262B6;
  }
}
.title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  margin-bottom: 24/$font;
  width: auto;
  height: 37/$font;
  font-size: 26/$font;
  font-weight: 600;
  color: #333333;
  line-height: 37/$font;
}
.content {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  margin-bottom: 4/$font;
  width: auto;
  height: 20/$font;
  font-size: 14/$font;
  font-weight: 400;
  color: #666666;
  line-height: 20/$font;
}
.label {
  margin: 18/$font 0 18/$font 0;
  padding: 6/$font 16/$font;
  width: fit-content;
  height: auto;
  font-size: 12/$font;
  font-weight: 500;
  color: #2262B6;
  line-height: 17/$font;
  border-radius: 100/$font;
  border: 1/$font solid rgba(34, 98, 182, 0.7);
}
.price {
  width: auto;
  height: 36/$font;
  font-size: 28/$font;
  font-weight: 500;
  color: #FF6C00;
  line-height: 36/$font;
  >span {
    font-size: 14/$font;
  }
}
</style>
