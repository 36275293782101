<template>
  <div>
    <div class="search-box">
      <img src="@/assets/image/article/article-search-bg.png"/>
      <div>
        <input v-model="form.formData.name" placeholder="请输入关键字进行搜索" @change="searchEvent"/>
        <div @click="searchEvent">
          <img src="@/assets/icon_png/search-icon.png"/>
        </div>
      </div>
    </div>
    <div class="content-box">
      <div class="left">
        <wt-side-menu-single :list="labelList" @click="clickMenu"/>
      </div>
      <div class="right wt-p-t-24 wt-p-r-24 wt-p-b-60 wt-p-l-24">
        <div class="cursor-items-box">
          <div v-for="item in list" :key="item.id" class="wt-m-r-32 wt-m-b-32" @click="navigateDetail(item.id)">
            <wt-course-item :item="item"/>
          </div>
        </div>
        <div class="pagination">
          <wt-pagination :total="listTotal" :page-size="9" :current-page="form.pageData.currentPage" @currentPageChange="currentPageChange"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index',
  data() {
    return {
      labelList: [],
      form: {
        formData: {
          name: '',
          labelId: ''
        },
        pageData: {
          currentPage: 1,
          pageSize: 9
        }
      },
      list: [],
      listTotal: 0
    }
  },
  mounted() {
    this.initDictionary()
  },
  activated() {
    this.requestList()
  },
  methods: {
    navigateDetail(id) {
      const routeUrl = this.$router.resolve(`/course/detail/${id}`)
      window.open(routeUrl.href, '_blank')
    },
    clickMenu(e) {
      this.form.formData.labelId = e.id
      this.searchEvent()
    },
    searchEvent() {
      this.form.pageData.currentPage = 1
      this.requestList()
    },
    currentPageChange(e) {
      this.form.pageData.currentPage = e
      this.requestList()
    },
    requestList() {
      this.$wtRequest({
        url: `/api/course/list`,
        method: 'post',
        data: this.form
      }).then((res) => {
        this.list = res.data.list
        this.listTotal = res.data.total
      }).catch(() => {})
    },
    initDictionary() {
      // 标签字典 1:研报  2:课程  3:热搜词  4:文章  5:动态
      this.$wtRequest({
        url: `/api/dictionary/listByType`,
        method: 'post',
        data: { id: 2 }
      }).then((res) => {
        const _labelList = [{ title: '全部', id: '' }]
        res.data.forEach(label => _labelList.push({ title: label.name, id: label.id }))
        this.labelList = _labelList
      }).catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/adapter.scss';
img {
  -webkit-user-drag: none;
}
.search-box {
  position: relative;
  width: 1440/$font;
  height: 200/$font;
  >img {
    width: 1440/$font;
    height: 200/$font;
  }
  >div {
    position: absolute;
    top: 75/$font;
    left: 335/$font;
    display: flex;
    align-items: center;
    >input {
      padding: 0 16/$font;
      width: 690/$font;
      height: 50/$font;
      outline: none;
    }
    >div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80/$font;
      height: 50/$font;
      background: $primary-color;
      border-radius: 0 2/$font 2/$font 0;
      >img {
        width: 30/$font;
        height: 30/$font;
      }
    }
  }
}
.content-box {
  display: flex;
  align-items: self-start;
  justify-content: space-between;
  padding: 24/$font 120/$font 60/$font 120/$font;
  width: 1440/$font;
  height: auto;
  background: #F5F7F9;
  .left {
    width: 218/$font;
    height: auto;
    background: #FFFFFF;
  }
  .right {
    width: 958/$font;
    height: auto;
    background: #FFFFFF;
    .cursor-items-box {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      >div:nth-child(3n+3) {
        margin-right: 0 !important;
      }
    }
  }
}
.pagination {
  display: flex;
  justify-content: flex-end;
}
</style>
