const getters = {
  sidebar: state => state.app.sidebar,
  moduleList: state => state.auth.moduleList,
  dynamicRoutes: state => state.auth.dynamicRoutes,
  permissionCodes: state => state.auth.permissionCodes,
  token: state => state.auth.token,
  avatar: state => state.auth.avatar,
  name: state => state.auth.name,
  roles: state => state.auth.roles,
  roleCodeList: state => state.auth.roleCodeList,
  userUuid: state => state.auth.userUuid,
  userId: state => state.auth.userId,
  userDuty: state => state.auth.userDuty,
  ctiIsLogin: state => state.ctiCloud.ctiIsLogin,
  language: state => 'zh',
  dragTemplate: state => state.template.dragTemplate,
  medicalDivideTemplateDetail: state => state.template.medicalDivideTemplate,
  dictionary: state => state.app.dictionary, // 系统字典，后面的人改这个文件请写注释
  totalTreat: state => state.auth.totalTreat, // 诊疗过程咨询未读
  totalTeam: state => state.auth.totalTeam, // 团队客服咨询未读
  productCategory: state => state.dictionary.productCategory, // 1 产品类别
  drugType: state => state.dictionary.drugType, // 3 药品类型
  drugDoseUnit: state => state.dictionary.drugDoseUnit, // 4 药品剂量单位
  drugQuantityUnit: state => state.dictionary.drugQuantityUnit, // 5 药品数量单位
  drugPackagingUnit: state => state.dictionary.drugPackagingUnit, // 6 药品包装单位
  drugAdministration: state => state.dictionary.drugAdministration, // 7 药品服用方式
  DrugTakingFrequency: state => state.dictionary.DrugTakingFrequency, // 8 药品服用频率
  chineseWesternPatentMedicineDosageForm: state => state.dictionary.chineseWesternPatentMedicineDosageForm, // 9 中西成药剂型
  traditionalChineseMedicineDosageForm: state => state.dictionary.traditionalChineseMedicineDosageForm, // 10 中药剂型

  /** im start */
  hidden: state => {
    // eslint-disable-next-line no-unused-vars
    const temp = state.current
    if (typeof document.hasFocus !== 'function') {
      return document.hidden
    }
    return !document.hasFocus()
  }

  /** im end */
}
export default getters
