import wtUtil from '@/utils/wtUtil'

const wtValidator = {
  ValidatorNotBlank: (rule, value, callback) => {
    if (wtUtil.isBlank(value)) {
      callback(new Error(''))
    } else {
      callback()
    }
  },
  ValidatorWtUpload: (rule, value, callback) => {
    if (!value || value.length <= 0) {
      const text = rule.name ? `请上传${rule.name}` : '请上传文件'
      return callback(new Error(text))
    }
    if (rule && value.length > rule.limit) {
      return callback(new Error(`最多上传${rule.limit}个文件`))
    }
    if (rule && value.length < rule.minlimit) {
      return callback(new Error(`最少上传${rule.minlimit}个文件`))
    }
    return callback()
  },
  ValidatorWtEditor: (rule, value, callback) => {
    if (wtUtil.isBlank(value)) {
      callback(new Error(''))
    } else {
      callback()
    }
  },
  ValidatorWtPhone: (rule, value, callback) => {
    if (value != null && value !== '') {
      if (!(/^1[3456789]\d{9}$/.test(value))) {
        callback(new Error('您输入的手机号不正确!'))
      } else {
        callback()
      }
    } else {
      callback()
    }
  },
  ValidatorWtPhoneTel: (rule, value, callback) => {
    if (value != null && value !== '') {
      // if (!(/^(((\(?\（?\+?\d{1,4}-?\)?\）?)?(\(?\（?\d{1,4}-?\)?\）?)\d{6,8})|((\(?\（?\+?\d{1,4}-?\)?\）?)?(\(?\（?\d{1,4}-?\)?\）?)?([1][3,4,5,7,8][0-9]\d{8})))$/.test(value))) {
      if (!(/^[\0-9\+?\-?\(?\)?\（?\）?]*$/.test(value))) {
        callback(new Error('请输入正确格式的座机/手机号!'))
      } else {
        callback()
      }
    } else {
      callback()
    }
  },
  ValidatorWtPositiveNumber: (rule, value, callback) => {
    if (value != null && value !== '') {
      if (!(/^(?:[1-9]\d*)$/.test(value))) {
        callback(new Error('请输入正整数!'))
      } else {
        callback()
      }
    } else {
      callback()
    }
  },
  ValidatorWtPositiveNumberZero: (rule, value, callback) => {
    if (value != null && value !== '') {
      if (!(/^(?:[0-9]\d*)$/.test(value))) {
        callback(new Error('请输入正整数或零!'))
      } else {
        callback()
      }
    } else {
      callback()
    }
  },
  // 金额小数点2位
  ValidatorWtPrice: (rule, value, callback) => {
    if (value && value.indexOf('.') !== -1 && value.split('.').length > 2) {
      callback(new Error('请输入正确格式的金额')) // 防止输入多个小数点
    } else if (value && value.indexOf('.') !== -1 && value.split('.')[1].length > 2) {
      callback(new Error('请输入最多2位小数的数值')) // 小数点后两位
    } else if (Number(value) < 0) {
      callback(new Error('金额不能为负数')) // 负数
    } else {
      callback()
    }
  },
  // 金额小数点4位
  ValidatorWtPriceFour: (rule, value, callback) => {
    if (value && value.indexOf('.') !== -1 && value.split('.').length > 2) {
      callback(new Error('请输入正确格式的金额')) // 防止输入多个小数点
    } else if (value && value.indexOf('.') !== -1 && value.split('.')[1].length > 4) {
      callback(new Error('请输入最多4位小数的数值')) // 小数点后4位
    } else if (Number(value) < 0) {
      callback(new Error('金额不能为负数')) // 负数
    } else {
      callback()
    }
  },
  ValidatorWtEdtId: (rule, value, callback) => {
    if (value != null && value !== '') {
      if (!(/^\d{8}$/.test(value))) {
        callback(new Error('您输入的教育ID不正确!'))
      } else {
        callback()
      }
    } else {
      callback()
    }
  },
  ValidatorWtTeaId: (rule, value, callback) => {
    if (value != null && value !== '') {
      if (!(/^(0|[1-9]\d{0,9})$/.test(value))) {
        callback(new Error('您输入的教职工号不正确!'))
      } else {
        callback()
      }
    } else {
      callback()
    }
  },
  ValidatorWtCertificateNo: (rule, value, callback) => {
    if (value != null && value !== '') {
      if (!(/(^\d{15}$)|(^\d{17}([0-9]|(X|x))$)/.test(value))) {
        callback(new Error('您输入的身份证号不正确!'))
      } else {
        callback()
      }
    } else {
      callback()
    }
  },
  ValidatorWtNumber: (rule, value, callback) => {
    if (value != null && value !== '') {
      if (!(/(^[0-9]*$)/.test(value))) {
        callback(new Error('您输入的值不是纯数字!'))
      } else {
        callback()
      }
    } else {
      callback()
    }
  },
  ValidatorPassword: (rule, value, callback) => {
    if (value != null && value !== '') {
      const reg = new RegExp('^(?=.*[a-zA-Z])(?=.*\\d)(?=.*[\\W_])[a-zA-Z\\d\\W_]{8,}$', '')
      if (!reg.test(value)) {
        callback(new Error('密码长度8位以上，由数字、字母(字母区分大小写）、特殊字符组成（特殊字符包括!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~）'))
      } else {
        callback()
      }
    } else {
      callback()
    }
  },
  // 判断剂量等正则 不能输入负数或其他字符
  isPositiveNumber: (num) => {
    return /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/.test(num)
  },
  // 判断正整数正则
  isPositiveInteger: (num) => {
    return /(^[1-9]\d*$)/.test(num)
  },
  // 判断价格正则
  isPositivePrice: (num) => {
    return /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(num)
  },
  // 判断邮箱是否填写正确
  checkEmail: (str) => {
    return /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/.test(str)
  },
  // 判断手机号是否填写正确
  checkMobile: (str) => {
    return /^1[3|4|5|6|7|8|9]\d{9}$/.test(str)
  }
}

export default wtValidator
