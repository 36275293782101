export default {
  checkNum: function(testNum) {
    var numReg = /^[0-9]+$/
    var numRe = new RegExp(numReg)
    if (!numRe.test(testNum)) {
      return false
    } else {
      return true
    }
  }
}
